import { html } from "htm/preact";
import Router, { route } from "preact-router";
import { useEffect, useState } from "preact/hooks";
import { useInput } from ".";
import { base } from "./api";

export async function login(email: string, password: string): Promise<{
    admintoken: string
}> {

    const body = {
        email,
        password,
    };

    const res = await (await fetch(base + '/admin/login', {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors'
    })).text();

    try {
        return JSON.parse(res);
    } catch (e) {
        throw new Error(res);
    }
}


export async function updatePassword(email: string, password: string, cus?: string): Promise<{}> {

    const res = await (await fetch(base + '/admin/accounts', {
        body: JSON.stringify({ email, password, cus }),
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('admintoken'),
            'Content-Type': 'application/json',
        },
    })).text();

    try {
        return JSON.parse(res);
    } catch (e) {
        throw new Error(res);
    }
}


export function Passwords() {


    const [accounts, setAccounts] = useState<any[]>([]);
    const [cus, setCus] = useInput('');
    const [email, setEmail] = useInput('');
    const [password, setPassword] = useInput('');
    const [confirmPassword, setConfirmPassword] = useInput('');


    useEffect(() => {
        const token = localStorage.getItem('admintoken');
        fetch(base + '/admin/accounts', {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        }).then(res => res.json())
            .then(res => { setAccounts(res) });
    }, []);

    const onUpdateClick = () => {
        updatePassword(email, password, cus || undefined)
            .then(res => {
                alert('Password updated');
            });

    };


    return html`<section>
    <h1>Update Password</h1>
    <input oninput=${setCus} value=${cus} type="text" placeholder="CUS" />
    <input oninput=${setEmail} value=${email} type="text" placeholder="Email" />
    <input oninput=${setPassword} value=${password} type="password" placeholder="Password" />
    <input oninput=${setConfirmPassword} value=${confirmPassword} type="password" placeholder="Confirm Password" />
    <button onclick=${onUpdateClick}>Update</button>

    <div>
        <table>
            <tr>
                <th>CUS</th>
                <th>Email</th>
                <th>Password</th>
            </tr>
            ${accounts.map(it => html`<tr>
                <td>${it.cus}</td>
                <td>${it.email}</td>
                <td>${it.password}</td>
            </tr>`)}
        </table>
    </div>
</section>`;
}

export function Logout({ setToken }: { setToken: (token: string | null) => void }) {
    useEffect(() => {
        localStorage.removeItem('admintoken');
        setToken(null);
        route('/admin');
    }, []);
    return html``;
}

export function Admin() {

    const [token, setToken] = useState('');


    useEffect(() => {
        const admintoken = localStorage.getItem('admintoken');
        if (admintoken) {
            setToken(admintoken);
        }
    }, []);

    if (token) {

        return html`
        <a href="/admin/logout">Logout</a>
        <a href="/admin/passwords">Passwords</a>
        <main class="page-main">
            <${Router}>
                <${Passwords} default />
                <${Logout} setToken=${setToken} path="/admin/logout" />
            </${Router}>
        </main>
`;


    } else {


        const [email, setEmail] = useInput('');
        const [password, setPassword] = useInput('');

        const onLoginClick = () => {
            login(email, password)
                .then(res => {
                    localStorage.setItem('admintoken', res.admintoken);
                    setToken(res.admintoken);
                });
        };

        return html`<article class="admin-login">

    <section class="admin-login__form">
        <h1>Admin Login</h1>
        <input oninput=${setEmail} value=${email} type="text" placeholder="Email" />
        <input oninput=${setPassword} type="password" placeholder="Password" value=${password} />
        <button onclick=${onLoginClick}>Login</button>
    </section>
</article>`;
    }
}