
import Select from "react-select";
import { ctx } from '.';
import { AddressOverview } from './model';
import { useRef } from "preact/hooks";


// this is where the option is rendered
const formatOptionLabel = (address: AddressOverview) => (
  <div style={{ color: '#000' }}>
    <div>{address.addr1}</div>
    <div style={{ marginLeft: "10px" }}>
      {address.zip} - {address.city} - {address.country}
    </div>
  </div>
);

const filterOptions = (
  candidate: { data: AddressOverview },
  input: string
) => {
  if (input) {
    return candidate.data.addrtext.toLowerCase().includes(input.toLowerCase());
  }
  return true;
};

export default function AddressList({ value, onchange }: any) {

  const { state } = ctx();
  const selectInputRef = useRef<any>();

  // alert(JSON.stringify(state.addresses.find(it => it.id == value)));
  return (
    <Select
      ref={selectInputRef}
      onChange={(e: AddressOverview) => onchange(e?.id)}
      value={state.addresses.find(it => it?.id == value)}
      formatOptionLabel={formatOptionLabel}
      options={state.addresses}
      getOptionValue={(option: AddressOverview) => option.id}
      filterOption={filterOptions}
      onMenuOpen={(e: any) => selectInputRef.current?.clearValue()}
      isClearable={true}
    />);

}

