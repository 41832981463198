
function _getPackage(wrapperElement) {
    if (typeof wrapperElement.contentWindow.marvin != 'undefined') {
        return wrapperElement.contentWindow.marvin;
    }
    return null;
}

function _createPackage(wrapperElement, resolve, reject) {

    var marvinPackage = _getPackage(wrapperElement);
    if (marvinPackage) {
        marvinPackage.onReady(function () {
            resolve(marvinPackage);
        });
    } else {
        // use listener
        wrapperElement.addEventListener('load', function handleSketchLoad(e) {
            var marvin = _getPackage(wrapperElement);
            if (marvin) {
                marvin.onReady(function () {
                    resolve(marvin);
                });
            } else {
                reject('Unable to find marvin package');
            }
        });
    }
}

function _createEditor(wrapperElement, resolve, reject) {

    var marvinPackage = _getPackage(wrapperElement);
    if (marvinPackage) {
        marvinPackage.onReady(function () {
            if (typeof marvinPackage.sketcherInstance != 'undefined') {
                resolve(_getPackage(wrapperElement).sketcherInstance);
                return;
            } else {
                reject(
                    'Unable to find sketcherInstance in element'
                );
                return;
            }
        });
    } else {
        // use listener
        wrapperElement.addEventListener('load', function handleSketchLoad(e) {
            var marvin = _getPackage(wrapperElement);
            if (marvin) {
                marvin.onReady(function () {
                    if (typeof marvin.sketcherInstance != 'undefined') {
                        resolve(marvin.sketcherInstance);
                    } else {
                        reject(
                            'Unable to find sketcherInstance in iframe');
                    }
                });
            } else {
                reject(
                    'Unable to find marvin package, cannot retrieve sketcher instance'
                );
            }
        });
    }
}


export const MarvinJSUtil = {
    getEditor: function getEditor(element) {
        function createEditor(resolve, reject) {
            _createEditor(element, resolve, reject);
        }

        return new Promise(createEditor);
    },
    getPackage: function getPackage(element) {
        function createPackage(resolve, reject) {
            _createPackage(element, resolve, reject);
        }

        return new Promise(createPackage);
    },
};
