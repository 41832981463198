import {html} from 'htm/preact';
import {ctx} from '../';
import {useEffect} from 'preact/hooks';
import * as api from '../api';


export default function MyAccount() {

    const { state, dispatch } = ctx();
    const user = state.user;

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            return;
        }
        api.getAddresses()
            .then(addresses => {
                dispatch({ type: 'SET_ADDRESSES', addresses });
            });
    }, []);

    return html`<section class="column main">
    <div class="page-title-wrapper">
        <h1 class="page-title">
            <span class="base" data-ui-id="page-title-wrapper">My Account</span>
        </h1>
    </div>
    <input name="form_key" type="hidden" value="rZ83imfPSXICTyJZ" />
    <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;">
    </div>

    <div class="block block-dashboard-info">
        <div class="block-title">
            <h2>Account Information</h2>
        </div>
        <div class="block-content">
            <div class="box box-information">
                <h3 class="box-title">
                    <span>Contact Information</span>
                </h3>
                <div class="box-content">
                    <p>
                        ${user?.firstname} ${user?.lastname}<br/>
                        ${user?.email}<br/>
                        ${user?.companyname}<br/>
                        ${user?.cus}<br/>
                    </p>
                </div>
                <!--<div class="box-actions">
                    <a class="action edit primary"
                        href="/account/edit">
                        <span>Edit</span>
                    </a>
                    <a href="/account/edit"
                        class="action change-password primary">
                        Change Password </a>
                </div>-->
            </div>
        </div>
    </div>
    <div class="block block-dashboard-addresses">
        <div class="block-title">
            <h2>Address Book</h2>
        </div>
        <div class="block-content block-addresses">
            <div class="box box-billing-address">
                <h3 class="box-title">
                    <span>Default Billing Address</span>
                </h3>
                <div class="box-content">
                    <address>
                        ${state.user?.default_billing
            ? state.addresses.find(it => it.id == state.user?.default_billing)?.addrtext
            : `You have not set a default billing address.`
        }
                    </address>
                </div>
                <div class="box-actions">
                    <a class="action edit primary"
                        href="/account/addresses"
                        data-ui-id="default-billing-edit-link">
                        <span>Edit Address</span></a>
                </div>
            </div>
            <div class="box box-shipping-address">
                <h3 class="box-title">
                    <span>Default Shipping Address</span>
                </h3>
                <div class="box-content">
                    <address>
                        ${state.user?.default_shipping
            ? state.addresses.find(it => it.id == state.user?.default_shipping)?.addrtext
            : `You have not set a default shipping address.`
        }
                    </address>
                </div>
                <div class="box-actions">
                    <a class="action edit primary"
                        href="/account/addresses"
                        data-ui-id="default-shipping-edit-link">
                        <span>
                            Edit Address </span>
                    </a>
                </div>
            </div>
        </div>
        <div class="block-content address-actions">
            <a class="action edit primary" href="/account/addresses">
                <span>Manage Addresses</span>
            </a>
        </div>
    </div>
</section>`;
}