import { html } from "htm/preact";
import { ctx } from "..";

export default function AccountInfo() {

    const { state } = ctx();
    const user = state.user;

    return html`<div class="column main">
    <div class="page-title-wrapper">
        <h1 class="page-title">
            <span class="base" data-ui-id="page-title-wrapper">Edit Account Information</span>
        </h1>
    </div>
    <input name="form_key" type="hidden" value="rZ83imfPSXICTyJZ" />
    <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;">
    </div>
    <form class="form form-edit-account" action="/account/editPost/" method="post"
        id="form-validate" enctype="multipart/form-data" data-hasrequired="&#x2A;&#x20;Required&#x20;Fields"
        autocomplete="off">
        <fieldset class="fieldset info">
            <input name="form_key" type="hidden" value="rZ83imfPSXICTyJZ" />
            <legend class="legend"><span>Account Information</span></legend><br/>

            <div class="field field-name-firstname required">
                <label class="label" for="firstname"><span>First Name</span></label>
                <div class="control">
                    <input type="text" id="firstname" name="firstname" value="${user?.firstname}" title="First&#x20;Name"
                        class="input-text required-entry" data-validate="{required:true}"/>
                </div>
            </div>
            <div class="field field-name-lastname required">
                <label class="label" for="lastname"><span>Last Name</span></label>
                <div class="control">
                    <input type="text" id="lastname" name="lastname" value="${user?.lastname}" title="Last&#x20;Name"
                        class="input-text required-entry" data-validate="{required:true}"/>
                </div>
            </div>

            <!--
            <div class="field choice">
                <input type="checkbox" name="change_email" id="change-email" data-role="change-email" value="1"
                    title="Change&#x20;Email" class="checkbox" />
                <label class="label" for="change-email"><span>Change Email</span></label>
            </div>
            <div class="field choice">
                <input type="checkbox" name="change_password" id="change-password" data-role="change-password" value="1"
                    title="Change&#x20;Password" class="checkbox" />
                <label class="label" for="change-password"><span>Change Password</span></label>
            </div>
            -->
        </fieldset>

        <fieldset class="fieldset password" data-container="change-email-password">
            <legend class="legend"><span data-title="change-email-password">Change Email and Password</span></legend>
            <br/>
            <div class="field email required" data-container="change-email">
                <label class="label" for="email"><span>Email</span></label>
                <div class="control">
                    <input type="email" name="email" id="email" autocomplete="email" data-input="change-email"
                        value="testerucb1&#x40;punchout.live" title="Email" class="input-text"
                        data-validate="{required:true, 'validate-email':true}" />
                </div>
            </div>
            <div class="field password current required">
                <label class="label" for="current-password"><span>Current Password</span></label>
                <div class="control">
                    <input type="password" class="input-text" name="current_password" id="current-password"
                        data-input="current-password" autocomplete="off" />
                </div>
            </div>
            <div class="field new password required" data-container="new-password">
                <label class="label" for="password"><span>New Password</span></label>
                <div class="control">
                    <input type="password" class="input-text" name="password" id="password" data-password-min-length="8"
                        data-password-min-character-sets="3" data-input="new-password"
                        data-validate="{required:true, 'validate-customer-password':true}" autocomplete="off" />
                    <div id="password-strength-meter-container" data-role="password-strength-meter" aria-live="polite">
                        <div id="password-strength-meter" class="password-strength-meter">
                            Password Strength:
                            <span id="password-strength-meter-label" data-role="password-strength-meter-label">
                                No Password </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field confirm password required" data-container="confirm-password">
                <label class="label" for="password-confirmation"><span>Confirm New Password</span></label>
                <div class="control">
                    <input type="password" class="input-text" name="password_confirmation" id="password-confirmation"
                        data-input="confirm-password" autocomplete="off" />
                </div>
            </div>
        </fieldset>
        <div class="actions-toolbar">
            <div class="primary">
                <button type="submit" class="action save primary" title="Save"><span>Save</span></button>
            </div>
            <div class="secondary">
                <a class="action back" href="/"><span>Go back</span></a>
            </div>
        </div>
    </form>
</div>`;
}