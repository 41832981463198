import { html } from "htm/preact";
import { ctx } from "..";

const subsidiariesMap: { [key: string]: string } = { "6": "IE", "10": "NL", "8": "GB", "11": "NL", "1": "GB", "13": "GB", "12": "GB", "2": "GB", "9": "GB", "7": "NL", "3": "GB" }

const countriesMap: { [key: string]: string } = {
    GB: "United Kingdom",
    IE: "Ireland",
    NL: "Netherlands",

};

export default function Footer() {

    const { state } = ctx();



    return html`<footer class="page-footer">
    <div class="footer">
        <div class="footer-top">
            <div class="footer-logo">
                <p>Powered by:
                    <img src="/assets/images/fluorochem_logo.svg" alt="Fluorochem" class="fluorochem-logo"/>
                </p>
            </div>
            <div class="footer-row footer-top-content">
                <div class="footer-column">
                    <ul class="footer-switcher footer-links">
                        <li><span class="active">${countriesMap[subsidiariesMap[state.user?.subsidiary || 1]]}</span></li>
                    </ul>
                </div>
                <div class="footer-column">
                <ul class="footer-links">
                    <li class="nav item"><a href="/terms-of-service-eu/">Terms of Service EU</a></li>
                    <li class="nav item"><a href="/privacy-policy/">Privacy Policy</a></li>
                    <li class="nav item"><a href="/terms-of-sale/">Terms of Sale</a></li>
                    <li class="nav item"><a href="/modern-slavery-statement">Modern Slavery Statement</a></li>
                </ul>
            </div>
            </div>
        </div>
    </div>
</footer>`;
}