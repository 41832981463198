import { html } from "htm/preact";
import { useEffect } from "preact/hooks";

export function TermsOfServiceEUPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return html`
    
    <div class="body-overlay"></div>
        
        <!-- Document Wrapper
        ============================================= -->
        <div id="wrapper" class="clearfix">    
    
            <!-- Content
            ============================================= -->
            <section id="content cms-content">
    
                <div class="content-wrap nopadding">
    
                    <div id="section-products" class="page-section notoppadding nobottompadding">
    
                        <div class="section newupdatedcontent nomargin" style="background-color: white;">
                            <div class="container clearfix">
                                <div class="divcenter">
                                    
                                    <h1 class="nobottommargin t300 ls1">Terms of Service EU</h1>
                                    <p class="nucsubheader"><strong>PLEASE READ CAREFULLY BEFORE USING WWW.FLUOROCHEM.EU or WWW.DOUGDISCOVERY.COM (the “Website”)</strong></p>
    
                                    <p><strong>The Terms of Service is a legal agreement between you (“you”, “your”) and Fluorochem EU Limited
                                    (trading as “Fluorochem”, “we”, “our”), a limited company whose operational place of business is 1st
                                    Floor, Penrose 2, Penrose Dock, Cork, T23 YY09, Companies Registration Office number 683004.</strong></p>

                                    <p><strong>These Terms of Service outline the conditions under which you use the Website and purchase Products
                                    from us, whether by way of the Website and/or via the email ordering process detailed below. By
                                    your use of the Website, by uploading data and/or downloading data, as well as any continued and
                                    subsequent use of the Website, you consent to be bound by these Terms of Service. If you do not
                                    agree to all of these Terms of Service then do not use the Website or any of the services on the
                                    Website. By your continued use of the Website you confirm that you have read, accept, and that you
                                    understand these Terms of Service. BY TICKING THE BOX WHEN YOU PURCHASE ANYTHING ON THE
                                    WEBSITE YOU CONFIRM THAT YOU HAVE READ, YOU ACCEPT, AND YOU HAVE UNDERSTOOD THESE
                                    TERMS OF SERVICE, THAT YOU ALSO CONSENT TO USE ELECTRONIC SIGNATURES AND ACKNOWLEDGE
                                    YOUR TICKING OF THE BOX TO BE AN ELECTRONIC SIGNATURE SUFFICIENT TO BIND YOU TO THESE
                                    TERMS OF SERVICE. Further, by submitting an order to us via the Website and or via the email process,
                                    you confirm that any such order shall be governed by these Terms of Service and any terms which you
                                    purport to provide to us are hereby rejected and superseded by these Terms of Service.</strong></p>

                                    <p><strong>Changes to Terms of Service:</strong> We may update, change, or replace any part of these Terms of Service
                                    by posting updates and/or changes to the Website. Your continued use of or access to the Website
                                    following the posting of any changes constitutes your acceptance of those changes</p>

                                    <h3>1. PRODUCTS AND PRICING</h3>
                                    <p><strong>1.1 Product Depictions/Product Descriptions: </strong>
                                    We have made every effort to display the
                                    specifications, descriptions, volume and dimensions of items on the Website and our
                                    promotional material, as accurately as possible. We cannot guarantee that your monitor’s
                                    display of any products will accurately reflect the item delivered. The products will comply
                                    with the specification supplied as well as any descriptions thereof. In accepting to purchase
                                    via the Website or via email, you acknowledge that you have not relied upon any statement,
                                    promise or other representations about the products by us. Descriptions of the items set out
                                    in our sales documentation are intended as a guide only. Products supplied and purchased
                                    from Fluorochem are for research use only.
                                    </p>
                                    <p><strong>1.2 Subject to Change: </strong>
                                    Product description and product pricing is subject to change at any time
                                    without notice.
                                    </p>
                                    <p><strong>1.3 Pricing, Currency, and Foreign Transaction Fees: </strong>
                                    All prices quoted on the Website are in Euro.
                                    GBP or USD depending on your login jurisdiction. The Website processes all transactions in the
                                    currency applicable to your login jurisdiction via our third party payment provider or shall
                                    recalibrate to an appropriate alternative currency as required depending on your jurisdiction
                                    (at our discretion), to whom you will be redirected for payment and in accordance with section
                                    10.1 you will be bound by their terms and conditions. Depending on your location and on your
                                    card provider card issuers may charge an extra fee as a foreign transaction fee – this charge is
                                    implemented by your card issuer and not by Fluorochem.
                                    </p>
                                    <p><strong>1.4 Pricing Errors: </strong>
                                    In the unlikely event that an incorrect price is shown on the Website or
                                    promotional material, Fluorochem reserves the right to refrain from fulfilling the order at that
                                    price. You will be notified by email informing you of the correct price and given the option to
                                    proceed with the corrected or updated order. Fluorochem, at its sole discretion, reserves the
                                    right to cancel an order in advance of shipment/collection, without cause, you will be notified
                                    of any such incident and offered an opportunity to reorder or your funds will be returned.
                                    </p>
                                    <p><strong>1.5 Price Fluctuations: </strong>
                                    If the cost of the products to Fluorochem increases due to any factor
                                    beyond our control including, but not limited to, material costs, labour costs, alteration of
                                    exchange rates or duties, or changes to delivery rates, we can increase the price prior to
                                    delivery, but any such change will be notified to you in advance of our processing the order
                                    and an option to confirm, re-order or cancel will be offered.
                                    </p>
                                    <p><strong>1.6 Discounts: </strong>
                                    From time to time, discounts are offered to customers. All discounts are offered at
                                    the sole discretion of Fluorochem or on the quote provided, and confirmed at checkout or by
                                    email.
                                    </p>
                                    <p><strong>1.7 Additional Costs: </strong>
                                    All prices are exclusive of packaging, tax, duties, transportation, delivery,
                                    insurance and other related costs which will be expressed to you at check out, unless
                                    otherwise agreed, specified or identified in the check out process or order confirmation.
                                    </p>

                                    <h3>2. CANCELLATION/TERMINATION</h3>
                                    <p><strong>2.1 Cancellation by Fluorochem: </strong>Fluorochem reserves the right to cancel any order placed on the
                                    Website or via email, prior to shipment. If your order is cancelled by us, you will receive a full
                                    refund of any amount paid by you in respect of that order or be provided an opportunity to
                                    reorder.</p>
                                    <p><strong>2.2 Cancellation by Customer - Pre-shipping: </strong>You may cancel an order up to 24 hours before the
                                    shipment date notified to you in your order. Your order may be made ready for shipment in a
                                    shorter period of time that 24 hours, depending on our availability. If we receive notice of your
                                    cancellation in advance of dispatch, it may be too late to cancel delivery. To cancel your order
                                    if your item has already been dispatched, contact us on the Website. Any refund will exclude
                                    the original delivery charge and the return-to-sender fee imposed by the delivery service.</p>
                                    <p><strong>2.3 Termination for cause: </strong>Fluorochem can terminate the sale of products to you under these
                                    Terms of Service where:</p>
                                    <p>2.3.1 you commit a material breach of any other term of these Terms of Service and (if such breach
                                        is remediable) fail to remedy that breach within a period of 14 days after being notified in
                                        writing to do so;</p>
                                    <p>2.3.2 you repeatedly breach any of the terms herein in such a manner as to reasonably justify the
                                    opinion that your conduct is inconsistent with you having the intention or ability to give effect
                                    to the terms of this agreement;</p>
                                    <p>2.3.3 you commence negotiations of a material or significant nature with all or any class of your
                                    creditors with a view to rescheduling any of your debts, or make a proposal for or enter into
                                    any compromise or arrangement with any of your creditors other than for the sole purpose of
                                    a scheme for a solvent amalgamation with one or more other companies or a solvent
                                    reconstruction;</p>
                                    <p>2.3.4 a petition is filed, a notice is given, a resolution is passed, or an order is made, for or on
                                    connection with the winding up, other than for the sole purpose of a scheme for a solvent
                                    amalgamation of that other party with one or more other companies or the solvent
                                    reconstruction of that other party;</p>
                                    <p>2.3.5 you cease, or threaten to cease, to carry on all or substantially the whole of your business; or</p>
                                    <p>2.3.6 a petition for bankruptcy is filed (or is so threatened).</p>

                                    <h3>3. ORDERING AND SHIPPING</h3>
                                    <p><strong>3.1 Email Orders: </strong>you may email your order to us at: sales@fluorochem.eu In order for the email
                                    order to be accepted it is required to be submitted on your company or institution’s letter-
                                    head and once submitted, and approved, an email confirmation shall issue detailing the order,
                                    together with an order number or relevant purchase order number (as required)</p>
                                    <p><strong>3.2 Ecommerce/Website Order Placed: </strong>We facilitate ecommerce orders via the website utilising
                                    the cart process. Orders may be amended until the payment process is completed. Once you
                                    submit the order, the order goes live on our system and is processed by our customer success
                                    team. A confirmation email is sent to the email address provided to us confirming your order
                                    once it is placed on the Website.</p>
                                    <p><strong>3.3 Delivery Location: </strong>Delivery will be to the address stated in the order process, or by agreement
                                    to such other location. By agreement, at the sole discretion of Fluorochem, the products may
                                    be collected from our premises, subject to compliance with all necessary legislation to the
                                    movement of hazardous chemicals and the local jurisdictional laws.</p>
                                    <p><strong>3.4 Order Shipped: </strong>When your item ships you will receive an email notification from us or the
                                    shipping agent to say the order has been shipped and provide you with a tracking number.</p>
                                    <p><strong>3.5 Regions: </strong>We ship from the UK, Ireland or the Netherlands, to the EU, Scandinavia, Eastern
                                    Europe, and the USA and APAC (depending on the order).</p>
                                    <p>3.5.1 Orders shipping outside of the EU : Fluorochem will not be responsible should local customs
                                    authorities wish to confiscate any item or delay any shipment. All delivery lead times are
                                    provided pending customs inspection and Fluorochem will not be responsible for any resulting
                                    delay.</p>
                                    <p><strong>3.6 Method: </strong>Shipping and delivery is by way FPO/Custom Carrier, but this may be altered at our
                                    discretion from time to time.</p>
                                    <p><strong>3.7 Shipping Rates: </strong>Shipping rates will be standard rates for the jurisdiction you have selected for
                                    shipment and will be calculated during checkout prior to payment or notified to your via the
                                    confirmation process. Delivery costs are charged, unless previously agreed otherwise, at the
                                    lowest available rate for the transport options appropriate to the overall hazard classification
                                    of the shipment. Costs can also vary based on the number of products ordered or if products
                                    are ordered and shipped in part. Shipping rates are subject to change.</p>
                                    <p><strong>3.8 Terms Specific to Delivery:</strong></p>
                                    <p>3.8.1 Subject to the specific terms of any special delivery service, delivery can take place at any time
                                    of the day and must be accepted at any time between 08:00 to 17:00.</p>
                                    <p>3.8.2 If you do not take delivery of the Products we may, at our discretion and without prejudice to
                                    any other rights:</p>
                                    <p>a) store or arrange for the storage of the Products and will charge you for all associated
                                    costs and expenses including, but not limited to, transportation, storage and insurance;
                                    and / or</p>
                                    <p>b) make arrangements for the redelivery of the Products and will charge you for the costs
                                    of such redelivery; and/or</p>
                                    <p>c) after 10 business days, resell or otherwise dispose of part or all of the Products and
                                    charge you for any shortfall below the price of the Products.</p>
                                    <p>3.8.3 If redelivery is not possible as set out above, you must collect the Products from our premises
                                    and will be notified of this. We can charge you for all associated costs including, but not limited
                                    to, storage and insurance.</p>
                                    <p>3.8.4 Any dates quoted for delivery are approximate only, and the time of delivery is not of the
                                    essence. We will not be liable for any delay in delivery of the Products that is caused by a
                                    circumstance beyond our control or your failure to provide us with adequate delivery
                                    instructions or any other instructions that are relevant to the supply of the Products.</p>
                                    <p>3.8.5 We can deliver the Products by instalments, which will be invoiced and paid for separately.
                                    Each instalment is a separate contract. Any delay in delivery or defect in an instalment will not
                                    entitle you to cancel any other instalment.</p>
                                    <p>3.8.6 Shipping and delivery will not be made to a residential address.
                                    </p>

                                    <h3>4. RETURNS</h3>
                                    <p><strong>4.1 Obligation to Inspect: </strong>You must inspect the Products on delivery or collection and ensure that
                                    any inspection takes place in accordance with all relevant health and safety recommendations
                                    or regulations.</p>
                                    <p><strong>4.2 Damage: </strong>If you identify any damages or shortages, you must inform us in writing within 7 days
                                    of delivery, providing details. Other than by agreement, we will only accept returned Products
                                    if we are satisfied that those Products are defective and if required, have carried out an
                                    inspection. Subject to your compliance with this section 4 and/or our agreement, you may
                                    return the Products and we will, as appropriate, replace, or refund the Products or part of
                                    them. We will provide you with the necessary instructions for safe return via email. Please
                                    contact us for further information.</p>
                                    <p><strong>4.3 Exclusions: </strong>We will be under no liability or further obligation in relation to the Products if:</p>
                                    <p>4.3.1 you fail to provide notice as set above; and/or</p>
                                    <p>4.3.2 you or your staff fail to have sufficient expertise and safety equipment in place in respect of
                                    the safe handling of chemicals;</p>
                                    <p>4.3.3 you make any further use of such Products after giving notice under the clause above relating
                                    to damages and shortages; and/or</p>
                                    <p>4.3.4 the defect arises because you did not follow our oral or written instructions about the safe
                                    storage (where provided) of the Products; and/or</p>
                                    <p>4.3.5 the defect arises from future use, combination in or with another product, or misuse or
                                    alteration of the Products, negligence, wilful damage or any other act by you, your employees
                                    or agents or any third parties.</p>
                                    <p><strong>4.4 Research Only: </strong>For the avoidance of doubt, all of our Products are supplied for the purposes
                                    of research use only. It is your or that of your organisation or institution to ensure or
                                    determine the use, if any, of any such Products. Fluorochem does not advise in relation to the
                                    use of our Products and you expressly hold Fluorochem harmless from any use made or
                                    combination with another product by you or your organisation of the Products.</p>
                                    <p><strong>4.5 Risk and Cost: </strong>You bear the risk and cost of returning the Products.</p>
                                    <p><strong>4.6 Deemed Acceptance: </strong>Acceptance of the Products will be deemed to be upon inspection of
                                    them by you and in any event within 14 days after delivery.</p>
                                    <p><strong>4.7 Items eligible for refund or replacement:</strong></p>
                                    <p>4.7.1 Item(s) damaged in transit: Any claims for breakages can be made either by telephone +44
                                    (0) 1457860111 or through the Website as soon as possible after delivery. Fluorochem will
                                    take care and attention to packing breakable items but in the event that there is damage
                                    during transit, Fluorochem will either replace or refund the broken item.</p>
                                    <p>4.7.2 Defective/spoiled item(s): You have the right to return a faulty time within a reasonable
                                    period of time. If you have received a defective/spoiled item please contact us through the
                                    Website or our customer service team.</p>
                                    <p>4.7.3 Incorrect item(s): If you receive an incorrect item please contact us as soon as possible
                                    through the Website or our customer service team.</p>
                                    <p><strong>4.8 Refund/Replace: </strong>Damaged, defective, or incorrect items will be refunded to you or a
                                    replacement will be sent to you. Please contact Fluorochem directly to arrange the return and
                                    collection of the relevant Product. Products are required to be packaged in a compliant
                                    manner for shipping regulations. Please contact Fluorochem for more details.</p>

                                    <h3>5. RISK AND TITLE</h3>
                                    <p><strong>5.1 Risk: </strong>The risk in the Products will pass to you on completion of delivery, whether deemed or
                                    otherwise.</p>
                                    <p><strong>5.2 Title: </strong>Title to the Products will not pass to you until we have received payment in full (in
                                        cleared funds) for: (a) the Products and/or (b) any other products or services that we have
                                        supplied to you in respect of which payment has become due.</p>
                                    <p><strong>5.3 Fiduciary Duty: </strong>Until title to the Products has passed to you, you must (a) hold the Products
                                    on a fiduciary basis as our bailee; and/or (b) store the Products separately and not remove,
                                    deface or obscure any identifying mark or packaging on or relating to the Products; and/or (c)
                                    keep the Products in satisfactory condition and keep them insured against all risks for their
                                    full price from the date of delivery.</p>
                                    <p><strong>5.4 Access: </strong>As long as the Products have not been resold, or irreversibly incorporated into another
                                    product, and without limiting any other right or remedy we may have, we can at any time ask
                                    you to deliver up the Products and, if you fail to do so promptly, enter any of your premises
                                    or of any third party where the Products are stored in order to recover them.</p>

                                    <h3>6. AGE AND RESPONSIBILITY</h3>
                                    <p><strong>6.1 Age: </strong>Any individual making use of the products and or ordering the Products must be at least
                                    18 years of age. This is a commerce website and not for use by consumers.</p>
                                    <p><strong>6.2 Responsibility: </strong> this is a commercial website for the sale of chemical products for research use.
                                    We sell to industry and institutions only. Consumers are expressly prohibited from using this
                                    Website and from ordering from us. Any inadvertent sale to a consumer and the damages
                                    arising therefrom are hereby disclaimed.</p>

                                    <h3>7. COVID STATEMENT</h3>
                                    <p><strong>7.1 COVID Related Delay: </strong>We endeavour to ship our products to you in line with our shipping
                                    estimates as provided to you on placing your order. However we have at times experienced
                                    unavoidable shipping delays due to the COVID-19 pandemic. We appreciate your patience at
                                    this difficult time and please be assured that we are working hard to provide accurate shipping
                                    information to our customers and to ship out our products as quickly as possible.</p>
                                  
                                    <h3>8. INTELLECTUAL PROPERTY</h3>
                                    <p><strong>8.1 Copyright and Trademark: </strong>Fluorochem owns and operates the Website. All material on the
                                    Website or our printed materials, is protected by copyright and is the property of Fluorochem.
                                    This material is provided solely for commercial research use. Fluorochem is trademarked in
                                    Ireland and use of the mark is protected under applicable Irish and international law.</p>
                                  
                                    <h3>9. PRIVACY POLICY</h3>
                                    <p><strong>9.1 Privacy Policy: </strong>Please see our Privacy Policy for details on how we collect and retain your
                                    personal data.</p>

                                    <h3>10. THIRD PARTY SITES</h3>
                                    <p><strong>10.1 Links: </strong>The Website if it includes links to third party web sites in its Content is not responsible
                                    for and does not endorse any features, content, advertising, products or other materials on or
                                    available from Third Party Sites or applications. If you decide to access Third Party Sites, you
                                    do so at your own risk and agree that your use of any Third Party Sites is on an “as-is” basis
                                    without any warranty, and your use of any Third Party Site is subject to the terms and
                                    conditions contained therein.</p>

                                    <h3>11. WARRANTY & DISCLAIMER</h3>
                                    <p><strong>11.1 General: </strong>All information and content on the Website and the Products supplied pursuant to it, is and/or
                                    are provided “as is” or “as available”. Fluorochem does not warrant, and expressly disclaims,
                                    that the information contained in and the content provided on the Website is up-to-date and
                                    complete and expressly disclaims any warranty as to suitability of any of the Products for any
                                    further use by you. For the avoidance of doubt, all of our Products are supplied for the
                                    purposes of research use only. It is your or that of your organisation or institution to ensure
                                    or determine the use, if any, of any such Products. Fluorochem does not advise in relation to
                                    the use of our Products and you expressly hold Fluorochem harmless from any issue made by
                                    you or your organisation of the Products.</p>
                                    <p><strong>11.2 Breach of Warranty: </strong> For the avoidance of doubt, Fluorochem shall have no liability to remedy
                                    a breach of warranty where such breach arises as a result of any of the following
                                    circumstances:</p>
                                    <p>11.2.1 any use of the products by you or incorporation by you in any chemical fashion including use
                                    in pharmaceuticals and/or for human or animal consumption;</p>
                                    <p>11.2.2 any temporary or permanent reproduction by any means and in any form, in whole or in part,
                                    of the products and/or the Website;</p>
                                    <p>11.2.3 any reverse assembly, reverse compilation, reverse engineering or adaptation of the whole or
                                    part of the products and/or the Website;</p>
                                    <p>11.2.4 any alteration, modification, adjustment, translation, adaptation or enhancement made by
                                    you to the products supplied and/or the Website or any combination, connection, operation
                                    or use of the products with or for pharmaceutical use whether for future use in humans,
                                    animals or otherwise;</p>
                                    <p>11.2.5 any dissemination, sale, hire, lease offer or exposure for sale or distribution of the Website or
                                    the content therein;</p>
                                    <p>11.2.6 any breach by you of your obligations under this Agreement or of Fluorochem’s Intellectual
                                    Property Rights; or</p>
                                    <p>11.2.7 any act, omission, negligence, fraud or default of or by you.</p>

                                    <h3>12. LIMITATION OF LIABILITY</h3>
                                    <p><strong>12.1 Precedence: </strong>This Section prevails over all other provisions of these Terms of Service and sets
                                    out the entire liability of Fluorochem in respect of:</p>
                                    <p>12.1.1 the performance, non-performance, purported performance or delay in performance by
                                    Fluorochem of its obligations under these Terms of Service; and</p>
                                    <p>12.1.2 otherwise in relation to this Agreement or the entering into or performance of these Terms of
                                    Service including breach of statutory duty, and in tort, misrepresentation or otherwise which
                                    will be limited to this Section.</p>
                                    <p><strong>12.2</strong> If Fluorochem does not deliver the Products, our liability is limited, subject to the Section
                                    below, to the costs and expenses incurred by you in obtaining replacement goods of similar
                                    description and quality in the cheapest market available, less the price of the Products</p>
                                    <p><strong>12.3 Total Liability: </strong>Our total liability will not, in any circumstances, exceed the total amount of the
                                    Price payable by you.</p>
                                    <p><strong>12.4 Limits: </strong>We will not be liable (whether caused by our employees, agents or otherwise) in
                                    connection with the Products, for:</p>
                                    <p>12.4.1 any indirect, special or consequential loss, damage, costs, or expenses; and/or</p>
                                    <p>12.4.2 any loss of profits; loss of anticipated profits; loss of business; loss of data; loss of reputation
                                    or goodwill; business interruption; or, other third party claims; and/or</p>
                                    <p>12.4.3 any failure to perform any of our obligations if such delay or failure is due to any cause beyond
                                    our reasonable control; and/or</p>
                                    <p>12.4.4 any losses caused directly or indirectly by any failure or breach by you in relation to your
                                    obligations; and/or</p>
                                    <p>12.4.5 any loss relating to the choice of the Products and how they will meet your purpose or the use
                                    by you of the Products supplied.</p>
                                    <p><strong>12.5</strong> The exclusions of liability contained within this Section will not exclude or limit our liability for
                                    death or personal injury caused by our negligence; or for any matter for which it would be
                                    illegal for us to exclude or limit our liability; and for fraud or fraudulent misrepresentation.</p>
                                    <p><strong>12.6 No Implied Terms: </strong>The terms of these Terms of service are in lieu of all other conditions,
                                    warranties and other terms concerning the supply or purported supply of, or failure to supply
                                    or delay in supplying, any services (except for those arising under the Sale of Products Act
                                    1893 and/or the Sale of Products, Supply of Services Act 1980 (if any) which might but for this
                                    Section have effect between Fluorochem and you or would otherwise be implied or
                                    incorporated into these Terms of Service or any collateral contract, whether by statue,
                                    common law, or otherwise (including the implied conditions, warranties or other terms as to
                                    satisfactory quality, fitness for purpose or as to the use of reasonable skill and care), all of
                                    which are hereby excluded.</p>
                                    <p><strong>12.7 Non-Contractual Liability: </strong>Fluorochem does not accept, and excludes all liability for breach of
                                    any obligation or duty to take reasonable care or exercise reasonable skill other than any such
                                    obligation or duty arising under these Terms of Service.</p>

                                    <h3>13. PROHIBITED USES</h3>
                                    <p><strong>13.1 Prohibited: </strong>You are prohibited from using the site or its content or any of the Products, in any
                                    of the following ways:</p>
                                    <p>13.1.1 for any unlawful purpose;
                                   </p>
                                    <p> 13.1.2 to solicit others to perform or participate in any unlawful acts;
                                   </p>
                                    <p> 13.1.3 to violate any local or international regulations or laws;
                                    </p>
                                    <p>13.1.4 to infringe upon or violate our intellectual property rights or the intellectual property rights of
                                    others;
                                    </p>
                                    <p>13.1.5 to submit false or misleading information;
                                    </p>
                                    <p>13.1.6 to collect or track the personal information of others;
                                  </p>
                                    <p>  13.1.7 to spam, phish, pharm, pretext, spider, crawl, or scrape; or
                                    </p>
                                    <p>13.1.8 to interfere with or circumvent the security features of this or other Websites or the Internet.</p>
                                    <p><strong>13.2 Termination: </strong>We reserve the right to terminate your use of this Website or the purchase of
                                    our products for violating any of the prohibited uses.</p>

                                    <h3>14. NOTICES:</h3>
                                    <p><strong>14.1 General Communication: </strong>You agree that Fluorochem may communicate with you by email,
                                    mobile and telephone about orders you have placed on through the Website and future offers
                                    and promotions available on the Website. Please review your settings to control what kind of
                                    messages you receive from us. To unsubscribe from any communication we circulate to you,
                                    please click on the unsubscribe mechanism at the bottom of the communication. You
                                    acknowledge and agree that we shall have no liability associated with or arising from your
                                    failure to do so, to maintain accurate contact or other information, including, but not limited
                                    to, your failure to receive critical information about the Website.</p>
                                    
                                    <h3>15. AMENDMENTS TO THE TERMS OF SERVICE:</h3>
                                    <p><strong>15.1 Amendments: </strong>Fluorochem reserves the right, at our sole discretion, to amend, add or delete
                                    any of these Terms of Service. Fluorochem will post notifications of any such changes to this
                                    Licence on the Website, will provide a link to the revised version of these Terms of Service,
                                    and may provide such other notice Fluorochem may elect in its sole discretion. If any future
                                    changes to these Terms of Service are unacceptable to you or cause you to no longer be in
                                    compliance with these Terms of Service, you can cease using the Website which will, excepting
                                    any outstanding orders you have placed through the Website, terminate the agreement
                                    between you and Fluorochem in respect of these Terms of Service.</p>

                                    <h3>16. SEVERABILITY:</h3>
                                    <p><strong>16.1 Severance of Terms: </strong>If any of the Terms of Service are determined by any competent authority
                                    to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will
                                    to that extent be severed from the remaining terms, conditions and provisions which will
                                    continue to be valid to the fullest extent permitted by law.</p>

                                    <h3>17. ENTIRE AGREEMENT:</h3>
                                    <p><strong>17.1 </strong>These Terms of Service together with the Privacy Policy represents the entire agreement
                                    between you and Fluorochem as relates to your use of the Website.</p>

                                    <h3>18. LAW AND JURISDICTION:</h3>
                                    <p><strong>18.1 Governing Law: </strong>These Terms of Service are governed by Irish law. Any dispute arising from,
                                    or related to, any term of these Terms of Service shall be subject to the exclusive jurisdiction
                                    of the Irish courts.</p>

                                    <h3>19. LANGUAGE:</h3>
                                    <p><strong>19.1 Language: </strong>Any dispute arising from, or related to, any term of these Terms of Service shall be
                                    resolved or determined based on the English language version alone. These terms were
                                    originally written in English. In the event that these terms are translated into any other
                                    language, the translation shall be for review purposes only and have no legal effect.</p>
    
                                </div>
                            </div>
                        </div>
    
                    </div>
    
                </div>
    
            </section><!-- #content end -->
    
        </div><!-- #wrapper end -->
    
        <!-- Go To Top
        ============================================= -->
        <div id="gotoTop" class="icon-angle-up"></div>

        
    `;
}