import { useState } from "preact/hooks";
import { ctx, isValidEmail, useInput } from ".";
import * as api from "./api";
import { route } from "preact-router";
import { html } from "htm/preact";
import { useEffect } from "react";
declare var require: any;


export function ForgotPassword() {

    const { state, dispatch } = ctx();

    const [email, setEmail] = useInput('');

    const [loading, setLoading] = useState(false);

    const [step, setStep] = useState('Login' as 'Login' | 'EmailPasswordReset' | 'SelectCustomer');
    const [customers, setCustomers] = useState([] as any[]);

    const [sent, setSent] = useState(false);

    useEffect(() => {
        setSent(false);
    }, []);

    const onSubmit = (e: SubmitEvent) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();


        if (!isValidEmail(email)) {
            return alert('Invalid email');
        }


        setLoading(true);
        api.forgotPassword(email)
            .then((res) => {
                setSent(true);
            })
            .catch((err) => {
                alert(err.message);
            })
            .finally(() => {
                setLoading(false);
            });




    };


    return html`<main id="maincontent" class="page-main">
    <div id="contentarea"></div>
    <div class="page messages">
        <div data-placeholder="messages"></div>
        <div data-bind="scope: 'messages'">
            <!-- ko if: cookieMessages && cookieMessages.length > 0 -->
            <div role="alert" data-bind="foreach: { data: cookieMessages, as: 'message' }" class="messages">
                <div data-bind="attr: {
                class: 'message-' + message.type + ' ' + message.type + ' message',
                'data-ui-id': 'message-' + message.type
            }">
                    <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                </div>
            </div>
            <!-- /ko -->

            <!-- ko if: messages().messages && messages().messages.length > 0 -->
            <div role="alert" data-bind="foreach: { data: messages().messages, as: 'message' }" class="messages">
                <div data-bind="attr: {
                class: 'message-' + message.type + ' ' + message.type + ' message',
                'data-ui-id': 'message-' + message.type
            }">
                    <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                </div>
            </div>
            <!-- /ko -->
        </div>
    </div>
    <div class="columns">
        <div class="column main"><input name="form_key" type="hidden" value="i8hLt8ukPFs9dSGI" />
            <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;">
            </div>
            <div class="login-container">              
                <div class="login-container-right">
                    <div class="block block-customer-login">
                        <div class="block-title">
                        <img class="logo" src="${require('/assets/images/d.png')}"
        title="" alt="Doug Discovery" />                           
                        </div>
                        <div class="block-title">
                            <h2 id="block-customer-login-heading">
                                Registered Doug Discovery Users </h2>
                        </div>
                        <div class="block-content" aria-labelledby="block-customer-login-heading">
                            ${!sent ?
            html`<form onSubmit=${onSubmit} class="form form-login">
                                <fieldset class="fieldset login" data-hasrequired="* Required Fields">
                                    <div class="field note">If you have an account, reset your password with your email address.
                                    </div>
                                    <div class="field email required">
                                        <label class="label" for="email"><span>Email</span></label>
                                        <div class="control">
                                            <input onInput=${setEmail} name="login[username]" value=${email} autocomplete="off" id="email"  required
                                                type="email" class="input-text" title="Email"/>
                                        </div>
                                    </div>
                                    <div class="actions-toolbar">
                                        <div class="primary">
                                            <button type="submit" class="action login primary ${loading ? 'disabled' : ''}" name="send" id="send2">
                                                <span>Reset Password</span></button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>` : html`Please check your email, a password reset link has been sent to ${email}`}
                        </div>
                    </div>

                    <div class="block aw-ca-creat-wrapper">
                        <div class="aw-block-new-company">
                            <div class="block-title">
                                <h2 id="block-new-customer-heading">Sign In with Doug Discovery</h2>
                            </div>
                            <div class="block-content" aria-labelledby="block-new-customer-heading">
                                <div class="actions-toolbar">
                                    <div class="primary">
                                        <a href="/login" class="action create primary">
                                            <span>Sign In</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block aw-ca-creat-wrapper">
                        <div class="aw-block-new-company">
                            <div class="block-title">
                                <h2 id="block-new-customer-heading">Register with Doug Discovery</h2>
                            </div>
                            <div class="block-content" aria-labelledby="block-new-customer-heading">
                                <p>
                                    Please register here to manage your account and orders. </p>
                                <div class="actions-toolbar">
                                    <div class="primary">
                                        <a href="/register" class="action create primary">
                                            <span>Sign Up</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>`;
}





export function ResetPassword({ token }: { token: string }) {


    const [password, setPassword] = useInput('');
    const [confirmPassword, setConfirmPassword] = useInput('');

    const [loading, setLoading] = useState(false);


    const onSubmit = (e: SubmitEvent) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();

        if (!password || password.length < 6) {
            return alert('Please enter a password of at least 6 characters');
        }

        if (password !== confirmPassword) {
            return alert('Passwords do not match');
        }


        setLoading(true);
        api.resetPassword(token, password)
            .then((res) => {
                alert('Password reset successfully');
                route('/login');
            })
            .catch((err) => {
                alert(err.message);
            })
            .finally(() => {
                setLoading(false);
            });



    };


    return html`<main id="maincontent" class="page-main">
    <div id="contentarea"></div>
    <div class="page messages">
        <div data-placeholder="messages"></div>
        <div data-bind="scope: 'messages'">
            <!-- ko if: cookieMessages && cookieMessages.length > 0 -->
            <div role="alert" data-bind="foreach: { data: cookieMessages, as: 'message' }" class="messages">
                <div data-bind="attr: {
                class: 'message-' + message.type + ' ' + message.type + ' message',
                'data-ui-id': 'message-' + message.type
            }">
                    <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                </div>
            </div>
            <!-- /ko -->

            <!-- ko if: messages().messages && messages().messages.length > 0 -->
            <div role="alert" data-bind="foreach: { data: messages().messages, as: 'message' }" class="messages">
                <div data-bind="attr: {
                class: 'message-' + message.type + ' ' + message.type + ' message',
                'data-ui-id': 'message-' + message.type
            }">
                    <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                </div>
            </div>
            <!-- /ko -->
        </div>
    </div>
    <div class="columns">
        <div class="column main"><input name="form_key" type="hidden" value="i8hLt8ukPFs9dSGI" />
            <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;">
            </div>
            <div class="login-container">              
                <div class="login-container-right">
                    <div class="block block-customer-login">
                        <div class="block-title">
                        <img class="logo" src="${require('/assets/images/d.png')}"
        title="" alt="Doug Discovery" />                           
                        </div>
                        <div class="block-title">
                            <h2 id="block-customer-login-heading">
                                Registered Doug Discovery Users </h2>
                        </div>
                        <div class="block-content" aria-labelledby="block-customer-login-heading">
                            <form onSubmit=${onSubmit} class="form form-login">
                                <fieldset class="fieldset login" data-hasrequired="* Required Fields">
                                    <div class="field note">If you have an account, sign in with your email address.
                                    </div>
                                    <div class="field password required">
                                        <label class="label" for="email"><span>New Password</span></label>
                                        <div class="control">
                                            <input onInput=${setPassword} name="login[username]" value=${password} autocomplete="off" id="email"  required
                                                type="password" class="input-text" title="New Password"/>
                                        </div>
                                    </div>
                                    <div class="field password required">
                                    <label class="label" for="email"><span>Confirm New Password</span></label>
                                    <div class="control">
                                        <input onInput=${setConfirmPassword} name="login[username]" value=${confirmPassword} autocomplete="off" id="email"  required
                                            type="password" class="input-text" title="Confirm New Password"/>
                                    </div>
                                    </div>
                                    <div class="actions-toolbar">
                                        <div class="primary">
                                            <button type="submit" class="action login primary ${loading ? 'disabled' : ''}" name="send" id="send2">
                                                <span>Reset Password</span></button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>

                    <div class="block aw-ca-creat-wrapper">
                        <div class="aw-block-new-company">
                            <div class="block-title">
                                <h2 id="block-new-customer-heading">Login with Doug Discovery</h2>
                            </div>
                            <div class="block-content" aria-labelledby="block-new-customer-heading">
                                <p>
                                    Please login here to manage your account and orders. </p>
                                <div class="actions-toolbar">
                                    <div class="primary">
                                        <a href="/login" class="action create primary">
                                            <span>Sign In</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block aw-ca-creat-wrapper">
                        <div class="aw-block-new-company">
                            <div class="block-title">
                                <h2 id="block-new-customer-heading">Register with Doug Discovery</h2>
                            </div>
                            <div class="block-content" aria-labelledby="block-new-customer-heading">
                                <p>
                                    Please register here to manage your account and orders. </p>
                                <div class="actions-toolbar">
                                    <div class="primary">
                                        <a href="/register" class="action create primary">
                                            <span>Sign Up</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>`;
}