import { html } from "htm/preact";
import { useRef, useState } from "preact/hooks";
import { route } from "preact-router";
import * as api from '../api';

export default function Register() {

    const form = useRef<HTMLFormElement>(null);

    const [loading, setLoading] = useState(false);

    const onSubmitClick = (e: Event) => {

        if (loading) {
            return;
        }
        if (!form.current?.checkValidity()) {
            return alert('Please fill out all fields');
        }

        const formData = new FormData(form.current as any);
        const data: any = {};
        formData.forEach((v, k) => {
            data[k] = v;
        });
        console.log(data);
        setLoading(true);
        api.registerNewAccount(data)
            .then(res => {
                alert('Registration successful. The team will prepare your account and reach out to you.');
                route('/');
            })
            .catch(err => {
                alert('An error happened, please try again, if you still receive the same error please reach out to webuser@fluorochem.co.uk with details: '
                    + err.message);
            })
            .finally(() => setLoading(false));

    }


    return html`
    <main id="maincontent" class="page-main">
    <div id="contentarea"></div>
    <div class="page messages">
        <div data-placeholder="messages"></div>
        <div data-bind="scope: 'messages'">
            <!-- ko if: cookieMessages && cookieMessages.length > 0 -->
            <div role="alert" data-bind="foreach: { data: cookieMessages, as: 'message' }" class="messages">
                <div data-bind="attr: {
                class: 'message-' + message.type + ' ' + message.type + ' message',
                'data-ui-id': 'message-' + message.type
            }">
                    <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                </div>
            </div>
            <!-- /ko -->

            <!-- ko if: messages().messages && messages().messages.length > 0 -->
            <div role="alert" data-bind="foreach: { data: messages().messages, as: 'message' }" class="messages">
                <div data-bind="attr: {
                class: 'message-' + message.type + ' ' + message.type + ' message',
                'data-ui-id': 'message-' + message.type
            }">
                    <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                </div>
            </div>
            <!-- /ko -->
        </div>
    </div>
    <div class="columns">
        <div class="column main"><input name="form_key" type="hidden" value="i8hLt8ukPFs9dSGI" />
            <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;">
            </div>
            <div class="aw-ca-company-create">
                <form ref=${form} class="custom-account-registration aw-ca__form">
                    <div id="memberDetails" class="userDiv fieldset-wrapper">
                        <div class="fieldset-wrapper-title">
                            <h2>Contact Information</h2>
                        </div>
                        <fieldset class="fieldset">
                            <div class="field">
                                <label class="label">First Name*</label>
                                <input name="firstname" type="text" class="control" required/>
                            </div>

                            <div class="field">
                                <label class="label">Last Name*</label>
                                <input name="lastname" type="text" class="control" required/>
                            </div>

                            <div class="field">
                                <label class="label">Email*</label>
                                <input name="email" type="email" class="control" required/>
                            </div>
                            <div class="field">
                                <label class="label">Company / Institute*</label>
                                <input name="company" type="text" class="control"  required/>
                            </div>

                        </fieldset>

                        <div style="display: block;">
                            <button type="button" style="display: inline;" onclick=${onSubmitClick}> Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</main>`;
}