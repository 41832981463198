import { html } from "htm/preact";
import { useEffect } from "preact/hooks";

export function TermsOfSalePage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return html`
    
    <div class="body-overlay"></div>
        
    <!-- Document Wrapper
    ============================================= -->
    <div id="wrapper" class="clearfix">    

        <!-- Content
        ============================================= -->
        <section id="content cms-content">

            <div class="content-wrap nopadding">

                <div id="section-products" class="page-section notoppadding nobottompadding">

                    <div class="section newupdatedcontent nomargin" style="background-color: white;">
                        <div class="container clearfix">
                            <div class="divcenter">
                                
                                <h1 class="nobottommargin t300 ls1">Terms and Conditions of Sale</h1>

                                <h2>Application and entire agreement</h2>
                                <ol>
                                <li>These Terms and Conditions will apply to the purchase of the goods detailed in our quotation
                                <strong>(Goods)</strong> by the buyer (<strong>you</strong> or <strong>Customer</strong>) from Fluorochem Limited a company registered in England
                                and Wales under number 02049362 whose registered office is at Unit 14, Graphite Way,
                                Hadfield, Derbyshire, SK13 1QH (<strong>we</strong> or <strong>us</strong> or <strong>Supplier</strong>).</li>
                                <li>A quotation is defined as an individual quote presented or can be prices specified on our websites or
                                from any fixed pricing agreements.</li>
                                <li>These Terms and Conditions will be deemed to have been accepted by you when you accept them or
                                the quotation or from the date of any delivery of the Goods (whichever happens earlier) and will
                                constitute the entire agreement between us and you.</li>
                                <li>These Terms and Conditions and the quotation (together, the Contract) apply to the purchase and
                                sale of any Goods between us and you, to the exclusion of any other terms that you try to impose or
                                incorporate, or which are implied by trade, custom, practice or course of dealing.</li>

                                <h2>Interpretation</h2>
                                <li>A "business day" means any day other than a Saturday, Sunday or bank holiday in England and
                                Wales.</li>
                                <li>The headings in these Terms and Conditions are for convenience only and will not affect their
                                interpretation.</li>
                                <li>Words imparting the singular number include the plural and vice-versa.</li>

                                <h2>Goods</h2>
                                <li>The description of the Goods is set out in our sales documentation or on our website, unless
                                expressly changed in our quotation. In accepting the quotation, you acknowledge that it is your
                                responsibility to determine the suitability of the goods supplied, prior to use.</li>
                                <li>We can make any changes to the specification of the Goods which are required to conform to any
                                applicable safety or other statutory or regulatory requirements.</li>
                                <li>Goods are sold for research and development purposes only and are not to be used for medicinal or
                                veterinary purposes and any other use which would result in the general public being exposed.</li>

                                <h2>Price</h2>
                                <li>The price (Price) of the Goods is set out in our quotation, current at the date of your order or such
                                other price as we may agree in writing.</li>
                                <li>If the cost of the Goods to us increases due to any factor beyond our control including, but not
                                limited to, material costs, labour costs, alteration of exchange rates or duties, or changes to delivery
                                rates, we can increase the Price prior to delivery.</li>
                                <li>Any increase in the Price under the clause above will only take place after we have told you about it.</li>
                                <li>You may be entitled to discounts. Any and all discounts will be at our discretion.</li>
                                <li>The Price is exclusive of fees for transportation and delivery unless specified by an existing
                                agreement or identified in the quotation.</li>
                                <li>The Price is exclusive of any applicable VAT and other taxes or levies which are imposed or charged
                                by any competent authority</li>

                                <h2>Cancellation and alteration</h2>
                                <li>Details of the Goods as described in the clause above (Goods) and set out in our sales documentation
                                are subject to alteration without notice and are not a contractual offer to sell the Goods which is
                                capable of acceptance.</li>
                                <li>The quotation (including any non-standard price negotiated in accordance with the clause on Price
                                    (above) is valid for a period of 30 days only from the date shown in it unless expressly withdrawn by
                                    us at an earlier time.</li>
                                <li>Either of us can cancel the order for any reason prior to your acceptance (or rejection) of the
                                quotation.</li>

                                <h2>Payment</h2>
                                <li>We will invoice you at the point of despatch of the goods.</li>
                                <li>You must pay the Price within 30 days of the date of our invoice or otherwise according to any credit
                                terms agreed between us.</li>
                                <li>If you do not pay within the period set out above, we may suspend any further deliveries to you and
                                without limiting any of our other rights or remedies for statutory interest, charge you interest at the
                                rate of 3% per annum above the base rate of the Bank of England from time to time on the amount
                                outstanding until you pay in full.</li>
                                <li>Time for payment will be of the essence of the Contract between us and you.</li>
                                <li>All payments must be made in British Pounds, Euros, US Dollars as determined by the currency set on
                                the account.</li>
                                <li>Both parties must pay all amounts due under these Terms and Conditions in full without any
                                deduction or withholding except as required by law and neither party is entitled to assert any credit,
                                set-off or counterclaim against the other in order to justify withholding payment of any such amount
                                in whole or in part.</li>
                                <li>We will arrange for the delivery of the Goods to the address specified in the quotation, or your order
                                or to another location we agree in writing.</li>
                                <li>If we both agree, you can collect the Goods from our premises, subject to all applicable legislations
                                being adhered to.</li>
                                <li>Subject to the specific terms of any special delivery service, delivery can take place at any time of the
                                day and must be accepted at any time between 08:00 to 17:00.</li>
                                <li>If you do not take delivery of the Goods we may, at our discretion and without prejudice to any other
                                rights:
                                <ol type="a">
                                <li>store or arrange for the storage of the Goods and will charge you for all associated costs and
                                expenses including, but not limited to, transportation, storage and insurance; and / or</li>
                                <li>make arrangements for the redelivery of the Goods and will charge you for the costs of such
                                redelivery; and/or</li>
                                <li>after 10 business days, resell or otherwise dispose of part or all of the Goods and charge you
                                for any shortfall below the price of the Goods.</li>
                                </ol>
                                </li>                                
                                <li>If redelivery is not possible as set out above, you must collect the Goods from our premises and will
                                be notified of this. We can charge you for all associated costs including, but not limited to, storage
                                and insurance.</li>
                                <li>Any dates quoted for delivery are approximate only, and the time of delivery is not of the essence.
                                We will not be liable for any delay in delivery of the Goods that is caused by a circumstance beyond
                                our control or your failure to provide us with adequate delivery instructions or any other instructions
                                that are relevant to the supply of the Goods.</li>
                                <li>We can deliver the Goods by installments, which will be invoiced and paid for separately. Each
                                installment is a separate contract. Any delay in delivery or defect in an installment will not entitle
                                you to cancel any other installment.</li>
                                <li>Delivery costs are charged, unless previously agreed otherwise, at the lowest available rate for the
                                transport options appropriate to the overall hazard classification of the shipment.</li>

                                <h2>Inspection and acceptance of Goods</h2>
                                <li>You must inspect the Goods on delivery or collection.</li>
                                <li>If you identify any damages or shortages, you must inform us in writing within 7 days of delivery,
                                providing details.</li>
                                <li>Other than by agreement, we will only accept returned Goods if we are satisfied that those Goods
                                are defective and if required, have carried out an inspection.</li>
                                <li>Subject to your compliance with this clause and/or our agreement, you may return the Goods and
                                we will, as appropriate, replace, or refund the Goods or part of them.</li>
                                <li>We will be under no liability or further obligation in relation to the Goods if:
                                <ol type="a">
                                <li>if you fail to provide notice as set above; and/or</li>
                                <li>you make any further use of such Goods after giving notice under the clause above relating
                                to damages and shortages; and/or</li>
                                <li>the defect arises because you did not follow our oral or written instructions about the
                                storage, commissioning, installation, use and maintenance of the Goods; and/or</li>
                                <li>the defect arises from normal wear and tear of the Goods; and/or</li>
                                <li>the defect arises from misuse or alteration of the Goods, negligence, willful damage or any
                                other act by you, your employees or agents or any third parties.</li>
                                </ol>
                                </li>
                                <li>You bear the risk and cost of returning the Goods.</li>
                                <li>Acceptance of the Goods will be deemed to be upon inspection of them by you and in any event
                                within 14 days after delivery.</li>                               

                                <h2>Risk and title</h2>
                                <li>The risk in the Goods will pass to you on completion of delivery.</li>
                                <li>Title to the Goods will not pass to you until we have received payment in full (in cash or cleared
                                    funds) for: (a) the Goods and/or (b) any other goods or services that we have supplied to you in
                                    respect of which payment has become due.</li>
                                <li>Until title to the Goods has passed to you, you must (a) hold the Goods on a fiduciary basis as our
                                bailee; and/or (b) store the goods separately and not remove, deface or obscure any identifying
                                mark or packaging on or relating to the Goods; and/or (c) keep the Goods in satisfactory condition
                                and keep them insured against all risks for their full price from the date of delivery.</li>
                                <li>As long as the Goods have not been resold, or irreversibly incorporated into another product, and
                                without limiting any other right or remedy we may have, we can at any time ask you to deliver up
                                the Goods and, if you fail to do so promptly, enter any of your premises or of any third party where
                                the Goods are stored in order to recover them.</li>

                                <h2>Termination</h2>
                                <li>We can terminate the sale of Goods under the Contract where:
                                <ol type="a">
                                <li>you commit a material breach of your obligations under these Terms and Conditions;</li>
                                <li>you are or become or, in our reasonable opinion, are about to become the subject of a
                                bankruptcy order or take advantage of any other statutory provision for the relief of
                                insolvent debtors;</li>
                                <li>you enter into a voluntary arrangement under Part 1 of the Insolvency Act 1986, or any
                                other scheme or arrangement is made with your creditors; or</li>
                                <li>you convene any meeting of your creditors, enter into voluntary or compulsory liquidation,
                                have a receiver, manager, administrator or administrative receiver appointed in respect of
                                your assets or undertakings or any part thereof, any documents are filed with the court for
                                the appointment of an administrator, notice of intention to appoint an administrator is given by you or any of your directors or by a qualifying floating charge holder (as defined in para.
                                    14 of Schedule B1 of the Insolvency Act 1986), a resolution is passed or petition presented to
                                    any court for the winding up of your affairs or for the granting of an administration order, or
                                    any proceedings are commenced relating to your insolvency or possible insolvency.</li>
                                </ol>
                                </li>

                                <h2>Limitation of liability</h2>
                                <li>Our liability under the Contract, and in breach of statutory duty, and in tort, misrepresentation or
                                otherwise will be limited to this section.</li>
                                <li>Subject to the clauses above on <strong>Inspection and Acceptance</strong> and <strong>Risk and Title</strong>, all warranties,
                                conditions or other terms implied by statute or common law (save for those implied by Section 12 of
                                the Sale of Goods Act 1979) are excluded to the fullest extent permitted by law.</li>
                                <li>If we do not deliver the Goods, our liability is limited, subject to the clause below, to the costs and
                                expenses incurred by you in obtaining replacement goods of similar description and quality in the
                                cheapest market available, less the price of the Goods.</li>
                                <li>Our total liability will not, in any circumstances, exceed the total amount of the Price payable by you.</li>
                                <li>We will not be liable (whether caused by our employees, agents or otherwise) in connection with the
                                Goods, for:
                                <ol type="a">
                                <li>any indirect, special or consequential loss, damage, costs, or expenses; and/or</li>
                                <li>any loss of profits; loss of anticipated profits; loss of business; loss of data; loss of reputation
                                or goodwill; business interruption; or, other third-party claims; and/or</li>
                                <li>any failure to perform any of our obligations if such delay or failure is due to any cause
                                beyond our reasonable control; and/or</li>
                                <li>any losses caused directly or indirectly by any failure or breach by you in relation to your
                                obligations; and/or</li>
                                <li>any loss relating to the choice of the Goods and how they will meet your purpose or the use
                                by you of the Goods supplied.</li>
                                </ol>
                                </li>
                                <li>The exclusions of liability contained within this clause will not exclude or limit our liability for death
                                or personal injury caused by our negligence; or for any matter for which it would be illegal for us to
                                exclude or limit our liability; and for fraud or fraudulent misrepresentation.</li>

                                <h2>Communications</h2>
                                <li>All notices under these Terms and Conditions must be in writing and signed by, or on behalf of, the
                                party giving notice (or a duly authorised officer of that party).</li>
                                <li>Notices will be deemed to have been duly given:
                                <ol type="a">
                                <li>when delivered, if delivered by courier or other messenger (including registered mail) during
                                the normal business hours of the recipient;</li>
                                <li>when sent, if transmitted by fax or email and a successful transmission report or return
                                receipt is generated;</li>
                                <li>on the fifth business day following mailing, if mailed by national ordinary mail; or</li>
                                <li>on the tenth business day following mailing, if mailed by airmail.</li>
                                </ol>
                                </li>
                                <li>All notices under these Terms and Conditions must be addressed to the most recent address, email
                                address or fax number notified to the other party.</li>

                                <h2>Data protection</h2>
                                <li>When providing the Goods to the Buyer, the Seller may gain access to and/or acquire the ability to
                                transfer, store or process personal data of employees of the Buyer.</li>
                                <li>The parties agree that where such processing of personal data takes place, the Buyer shall be 'data
                                controller' and the Seller shall be the 'data processor' as defined in the General Data Protection
                                Regulation <strong>(GDPR)</strong> as may be amended, extended and/or re-enacted from time to time.</li>
                                <li>For the avoidance of doubt, 'Personal Data', 'Processing', 'Data Controller', 'Data Processor' and 'Data
                                Subject' shall have the same meaning as in the GDPR.</li>
                                <li>The Seller shall only Process Personal Data to the extent reasonably required to enable it to provide
                                the Goods as mentioned in these terms and conditions or as requested by and agreed with the
                                Buyer, shall not retain any Personal Data longer than necessary for the Processing and refrain from
                                Processing any Personal Data for its own or for any third party's purposes.</li>
                                <li>The Seller shall not disclose Personal Data to any third parties other than employees, directors,
                                agents, subcontractors or advisors on a strict "need-to-know" basis and only under the same (or
                                more extensive) conditions as set out in these terms and conditions or to the extent required by
                                applicable legislation and/or regulations.</li>
                                <li>The Seller shall implement and maintain technical and organisational security measures as are
                                required to protect Personal Data Processed by the Seller on behalf of the Buyer. Further
                                information about the Seller's approach to data protection are specified in its Data Protection Policy,
                                which can be found on our website. For any enquiries or complaints regarding data privacy, you
                                can e-mail: dataprotection@fluorochem.co.uk</li>

                                <h2>Circumstances beyond the control of either party</h2>
                                <li>Neither party shall be liable for any failure or delay in performing their obligations where such failure
                                or delay results from any cause that is beyond the reasonable control of that party. Such causes
                                include, but are not limited to: industrial action, civil unrest, fire, flood, storms, earthquakes, acts of
                                terrorism, acts of war, governmental action or any other event that is beyond the control of the
                                party in question.</li>
                                
                                <h2>No Waiver</h2>
                                <li>No waiver by us of any breach of these Terms and Conditions by you shall be considered as a waiver
                                of any subsequent breach of the same or any other provision.</li>

                                <h2>Severance</h2>
                                <li>If one or more of these Terms and Conditions is found to be unlawful, invalid or otherwise
                                unenforceable, that / those provisions shall be deemed severed from the remainder of these Terms
                                and Conditions (which will remain valid and enforceable).</li>

                                <h2>Law and jurisdiction</h2>
                                <li>This Agreement shall be governed by and interpreted according to the law of England and Wales and
                                all disputes arising under the Agreement (including non-contractual disputes or claims) shall be
                                subject to the exclusive jurisdiction of the English and Welsh courts.</li>

                                </ol>
                                
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </section><!-- #content end -->

    </div><!-- #wrapper end -->

    <!-- Go To Top
    ============================================= -->
    <div id="gotoTop" class="icon-angle-up"></div>

    
`;
}