export const demo = {
    products:
        [
            {
                id: 1,
                name: 'VicTsing Wireless Mouse,',
                sku: '3-year-unlimited-cloud-storage-service-activation-card-other-1',
                description:
                    'After thousands of samples of palm data, we designed this ergonomic mouse. The laptop mouse has a streamlined arc and thumb rest to help reduce the stress caused by prolonged use of the laptop mouse.',
                brand: 'VicTsing',
                price: 10.99,
                image: '/assets/images/eCommerce/27.png',
                hasFreeShipping: true,
                rating: 2,
                category: 'Peripherals',
            },
            {
                id: 2,
                name: 'Bose Frames Tenor - Rectangular Polarized, Bluetooth Audio Sunglasses',
                sku: '360fly-panoramic-360-hd-video-camera-black-2',
                description:
                    'Redesigned for luxury — Thoughtfully refined and strikingly elegant, the latest Bose sunglasses blend enhanced features and designs for an elevated way to listen',
                brand: 'Bose',
                price: 249.0,
                image: '/assets/images/eCommerce/26.png',
                hasFreeShipping: false,
                rating: 5,
                category: 'Audio',
            },
            {
                id: 3,
                name: 'Willful Smart Watch for Men Women 2020,',
                sku: '3-dr-backpack-for-solo-black-3',
                description:
                    'Are you looking for a smart watch, which can not only easily keep tracking of your steps, calories, heart rate and sleep quality, but also keep you informed of incoming calls.',
                brand: 'Willful',
                price: 29.99,
                image: '/assets/images/eCommerce/25.png',
                hasFreeShipping: false,
                rating: 5,
                category: 'Wear',
            },
            {
                id: 4,
                name: 'Ronyes Unisex College Bag Bookbags for Women',
                sku: '3-dr-propellers-for-3-dr-solo-drones-2-pack-black-4',
                description:
                    'Made of high quality water-resistant material; padded and adjustable shoulder straps; external USB with built-in charging cable offers a convenient charging',
                brand: 'Ronyes',
                price: 23.99,
                image: '/assets/images/eCommerce/24.png',
                hasFreeShipping: false,
                rating: 2,
                category: 'Wear',
            },
            {
                id: 5,
                name: 'Toshiba Canvio Advance 2TB Portable External Hard Drive',
                sku: '3-dr-solo-gimbal-black-5',
                description: 'Up to 3TB of storage capacity to store your growing files and content',
                brand: 'Toshiba',
                price: 69.99,
                image: '/assets/images/eCommerce/23.png',
                hasFreeShipping: false,
                rating: 2,
                category: 'Peripherals',
            },
            {
                id: 6,
                name: 'Tile Pro - High Performance Bluetooth Tracker',
                sku: '3-dr-solo-smart-rechargeable-battery-black-6',
                description:
                    'FIND KEYS, BAGS & MORE -- Pro is our high-performance finder ideal for keys, backpacks, luggage or any other items you want to keep track of. The easy-to-use finder and free app work with iOS and Android.',
                brand: 'Tile',
                price: 29.99,
                image: '/assets/images/eCommerce/22.png',
                hasFreeShipping: false,
                rating: 4,
                category: 'Peripherals',
            },
            {
                id: 7,
                name: 'Bugani M90 Portable Bluetooth Speaker',
                sku: '3-m-eureka-rr-pet-micro-allergen-vacuum-bag-for-select-eureka-vacuums-3-pack-7',
                description:
                    'Bluetooth Speakers-The M90 Bluetooth speaker uses the latest Bluetooth 5.0 technology and the latest Bluetooth ATS chip, Connecting over Bluetooth in seconds to iPhone, iPad, Smart-phones, Tablets, Windows, and other Bluetooth devices.',
                brand: 'Bugani',
                price: 56.0,
                image: '/assets/images/eCommerce/21.png',
                hasFreeShipping: false,
                rating: 3,
                category: 'Audio',
            },
            {
                id: 8,
                name: 'PlayStation 4 Console',
                sku: '3-m-filtrete-bissell-replacement-belt-8',
                description:
                    'All the greatest, games, TV, music and more. Connect with your friends to broadcast and celebrate your epic moments at the press of the Share button to Twitch, YouTube, Facebook and Twitter.',
                brand: 'Sony',
                price: 339.95,
                image: '/assets/images/eCommerce/20.png',
                hasFreeShipping: false,
                rating: 1,
                category: 'Gaming',
            },
            {
                id: 9,
                name: 'Giotto 32oz Leakproof BPA Free Drinking Water',
                sku: '3-m-filtrete-devil-tattoo-vacuum-bag-3-pack-9',
                description:
                    'With unique inspirational quote and time markers on it,this water bottle is great for measuring your daily intake of water,reminding you stay hydrated and drink enough water throughout the day.A must have for any fitness goals including weight loss,appetite control and overall health.',
                brand: '3M',
                price: 16.99,
                image: '/assets/images/eCommerce/19.png',
                hasFreeShipping: false,
                rating: 5,
                category: 'Wear',
            },
            {
                id: 10,
                name: 'Oculus Quest All-in-one VR',
                sku: '3-m-filtrete-eureka-r-replacement-belt-for-select-eureka-vacuums-10',
                description:
                    'All-in-one VR: No PC. No wires. No limits. Oculus quest is an all-in-one gaming system built for virtual reality. Now you can play almost anywhere with just a VR headset and controllers. Oculus touch controllers: arm yourself with the award-winning Oculus touch controllers. Your slashes, throws and grab appear in VR with intuitive, realistic Precision, transporting your hands and gestures right into the game',
                brand: 'Oculus',
                price: 645.0,
                image: '/assets/images/eCommerce/18.png',
                hasFreeShipping: false,
                rating: 1,
                category: 'Gaming',
            },
            {
                id: 11,
                name: 'Handbags for Women Large Designer bag',
                sku: '3-m-filtrete-eureka-u-replacement-belt-11',
                description:
                    'Classic Hobo Purse: Top zipper closure, with 2 side zipper pockets design and elegant tassels decoration, fashionable and practical handbags for women, perfect for shopping, dating, travel and business',
                brand: 'Hobo',
                price: 39.99,
                image: '/assets/images/eCommerce/17.png',
                hasFreeShipping: false,
                rating: 3,
                category: 'Wear',
            },
            {
                id: 12,
                name: 'Adidas Mens Tech Response Shoes',
                sku: '3-m-filtrete-hoover-a-micro-allergen-vacuum-bag-12',
                description:
                    'Comfort + performance.  Designed with materials that are durable, lightweight and extremely comfortable. Core performance delivers the perfect mix of fit, style and all-around performance.',
                brand: 'Adidas',
                price: 54.59,
                image: '/assets/images/eCommerce/16.png',
                hasFreeShipping: false,
                rating: 5,
                category: 'Wear',
            },
            {
                id: 13,
                name: 'Laptop Bag',
                sku: '3-m-filtrete-hoover-w2-micro-allergen-vacuum-bag-3-pack-13',
                description:
                    'TSA FRIENDLY- A separate DIGI SMART compartment can hold 15.6 inch Laptop as well as 15 inch, 14 inch Macbook, 12.9 inch iPad, and tech accessories like charger for quick TSA checkpoint when traveling',
                brand: 'TAS',
                price: 29.99,
                image: '/assets/images/eCommerce/15.png',
                hasFreeShipping: false,
                rating: 5,
                category: 'Wear',
            },
            {
                id: 14,
                name: 'Wireless Charger 5W Max',
                sku: '3-m-filtrete-hoover-windtunnel-replacement-belt-14',
                description:
                    'Charge with case: transmits charging power directly through protective cases. Rubber/plastic/TPU cases under 5 mm thickness . Do not use any magnetic and metal attachments or cards, or it will prevent charging.',
                brand: '3M',
                price: 10.83,
                image: '/assets/images/eCommerce/14.png',
                hasFreeShipping: false,
                rating: 3,
                category: 'Peripherals',
            },
            {
                id: 15,
                name: 'Vankyo leisure 3 mini projector',
                sku: '3-m-filtrete-vacuum-belt-for-select-hoover-t-series-upright-vacuums-15',
                description:
                    'SUPERIOR VIEWING EXPERIENCE: Supporting 1920x1080 resolution, VANKYO Leisure 3 projector is powered by MStar Advanced Color Engine, which is ideal for home entertainment. 2020 upgraded LED lighting provides a superior viewing experience for you.',
                brand: 'Vankyo Store',
                price: 99.99,
                image: '/assets/images/eCommerce/13.png',
                hasFreeShipping: false,
                rating: 2,
                category: 'Display',
            },
            {
                id: 16,
                name: 'New Apple iPad Pro',
                sku: '3-m-hoover-y-z-pet-micro-allergen-vacuum-bag-for-select-hoover-vacuums-3-pack-16',
                description:
                    'Up to 10 hours of surﬁng the web on Wi‑Fi, watching video, or listening to music. Up to 9 hours of surﬁng the web using cellular data network, Compatible with Smart Keyboard Folio and Bluetooth keyboards',
                brand: 'Apple',
                price: 799.99,
                image: '/assets/images/eCommerce/12.png',
                hasFreeShipping: false,
                rating: 3,
                category: 'Laptops',
            },
            {
                id: 17,
                name: 'Nike Air Max',
                sku: '72-9301-speaker-wire-harness-adapter-for-most-plymouth-dodge-and-mitsubishi-vehicles-multi-17',
                description:
                    'With a bold application of colorblocking inspired by modern art styles, the Nike Air Max 270 React sneaker is constructed with layers of lightweight material to achieve its artful look and comfortable feel.',
                brand: 'Nike',
                price: 98.95,
                image: '/assets/images/eCommerce/11.png',
                hasFreeShipping: false,
                rating: 1,
                category: 'Wear',
            },
            {
                id: 18,
                name: 'Logitech K380 Wireless Keyboard',
                sku: 'acer-11-6-chromebook-intel-celeron-2-gb-memory-16-gb-e-mmc-flash-memory-moonstone-white-18',
                description:
                    'Logitech K380 Bluetooth Wireless Keyboard gives you the comfort and convenience of desktop typing on your smartphone, and tablet. It is a wireless keyboard that connects to all Bluetooth wireless devices that support external keyboards. Take this compact, lightweight, Bluetooth keyboard anywhere in your home. Type wherever you like, on any compatible computer, phone or tablet.',
                brand: 'Logitech',
                price: 81.99,
                image: '/assets/images/eCommerce/10.png',
                hasFreeShipping: false,
                rating: 4,
                category: 'Peripherals',
            },
            {
                id: 19,
                name: 'OnePlus 7 Pro ',
                sku: 'one-plus-7-pro-19',
                brand: 'Philips',
                price: 14.99,
                image: '/assets/images/eCommerce/9.png',
                hasFreeShipping: false,
                rating: 4,
                description: `The OnePlus 7 Pro features a brand new design, with a glass back and front and curved sides. The phone feels
        very premium but’s it’s also very heavy. The Nebula Blue variant looks slick but it’s quite slippery, which
        makes single-handed use a real challenge. It has a massive 6.67-inch ‘Fluid AMOLED’ display with a QHD+
        resolution, 90Hz refresh rate and support for HDR 10+ content. The display produces vivid colours, deep blacks
        and has good viewing angles.`,
                category: 'Phones',
            },
            {
                id: 20,
                name: 'Sony 4K Ultra HD LED TV ',
                sku: 'sony-4-k-ultra-hd-led-tv-20',
                brand: 'Apple',
                price: 7999.99,
                image: '/assets/images/eCommerce/8.png',
                hasFreeShipping: false,
                rating: 5,
                description: `Sony 4K Ultra HD LED TV has 4K HDR Support. The TV provides clear visuals and provides distinct sound quality
        and an immersive experience. This TV has Yes HDMI ports & Yes USB ports. Connectivity options included are HDMI.
        You can connect various gadgets such as your laptop using the HDMI port. The TV comes with a 1 Year warranty.`,
                category: 'Display',
            },
            {
                id: 21,
                name: 'Google - Google Home - White/Slate fabric',
                sku: 'google-google-home-white-slate-fabric-21',
                brand: 'Google',
                price: 129.29,
                image: '/assets/images/eCommerce/7.png',
                hasFreeShipping: true,
                rating: 4,
                description: `Simplify your everyday life with the Google Home, a voice-activated speaker powered by the Google Assistant. Use
        voice commands to enjoy music, get answers from Google and manage everyday tasks. Google Home is compatible with
        Android and iOS operating systems, and can control compatible smart devices such as Chromecast or Nest.`,
                category: 'Audio',
            },
            {
                id: 22,
                name: 'Switch Pro Controller',
                sku: 'switch-pro-controller-22',
                brand: 'Sharp',
                price: 429.99,
                image: '/assets/images/eCommerce/6.png',
                hasFreeShipping: false,
                rating: 3,
                description: `The Nintendo Switch Pro Controller is one of the priciest "baseline" controllers in the current console
        generation, but it's also sturdy, feels good to play with, has an excellent direction pad, and features
        impressive motion sensors and vibration systems. On top of all of that, it uses Bluetooth, so you don't need an
        adapter to use it with your PC.`,
                category: 'Gaming',
            },
            {
                id: 23,
                name: 'Apple - MacBook Air® (Latest Model) - 13.3" Display - Silver',
                sku: 'apple-mac-book-air-latest-model-13-3-display-silver-23',
                brand: 'Apple',
                price: 999.99,
                image: '/assets/images/eCommerce/5.png',
                hasFreeShipping: false,
                rating: 4,
                description: `MacBook Air is a thin, lightweight laptop from Apple. MacBook Air features up to 8GB of memory, a
        fifth-generation Intel Core processor, Thunderbolt 2, great built-in apps, and all-day battery life.1 Its thin,
        light, and durable enough to take everywhere you go-and powerful enough to do everything once you get there,
        better.`,
                category: 'Laptops',
            },
            {
                id: 24,
                name: 'OneOdio A71 Wired Headphones',
                sku: 'one-odio-a71-wired-headphones-24',
                brand: 'OneOdio',
                price: 49.99,
                image: '/assets/images/eCommerce/4.png',
                hasFreeShipping: true,
                rating: 3,
                description: `Omnidirectional detachable boom mic upgrades the headphones into a professional headset for gaming, business,
        podcasting and taking calls on the go. Better pick up your voice. Control most electric devices through voice
        activation, or schedule a ride with Uber and order a pizza. OneOdio A71 Wired Headphones voice-controlled device
        turns any home into a smart device on a smartphone or tablet.`,
                category: 'Audio',
            },
            {
                id: 25,
                name: 'Apple iMac 27-inch',
                sku: 'apple-i-mac-27-inch-25',
                brand: 'Apple',
                price: 999.99,
                image: '/assets/images/eCommerce/3.png',
                hasFreeShipping: true,
                rating: 4,
                description: `The all-in-one for all. If you can dream it, you can do it on iMac. It’s beautifully & incredibly intuitive and
        packed with tools that let you take any idea to the next level. And the new 27-inch model elevates the
        experience in way, with faster processors and graphics, expanded memory and storage, enhanced audio and video
        capabilities, and an even more stunning Retina 5K display. It’s the desktop that does it all — better and faster
        than ever.`,
                category: 'Display',
            },
            {
                id: 26,
                name: 'Apple iPhone 11 (64GB, Black)',
                sku: 'apple-i-phone-11-64-gb-black-26',
                brand: 'Apple',
                price: 669.99,
                image: '/assets/images/eCommerce/2.png',
                hasFreeShipping: true,
                rating: 5,
                description: `The Apple iPhone 11 is a great smartphone, which was loaded with a lot of quality features. It comes with a
        waterproof and dustproof body which is the key attraction of the device. The excellent set of cameras offer
        excellent images as well as capable of recording crisp videos. However, expandable storage and a fingerprint
        scanner would have made it a perfect option to go for around this price range.`,
                category: 'Phones',
            },
            {
                id: 27,
                name: 'Apple Watch Series 5',
                sku: 'apple-watch-series-5-27',
                brand: 'Apple',
                price: 339.99,
                image: '/assets/images/eCommerce/1.png',
                hasFreeShipping: true,
                rating: 4,
                description: `On Retina display that never sleeps, so it’s easy to see the time and other important information, without
        raising or tapping the display. New location features, from a built-in compass to current elevation, help users
        better navigate their day, while international emergency calling1 allows customers to call emergency services
        directly from Apple Watch in over 150 countries, even without iPhone nearby. Apple Watch Series 5 is available
        in a wider range of materials, including aluminium, stainless steel, ceramic and an all-new titanium.`,
                category: 'Wear',
            }
        ],
}


let unsortedConceptBlocks = [
    {
        "concept_molecule_example": "\"001038\"",
        "svg_path": "14\\6\\1\\6\\svg\\UBPDKIDWEADHPP-UHFFFAOYSA-N_001038",
        "concept_name": "Monosubstituted (ortho) monoiodobenzenes",
        "concept_occurrences": "217",
        "concept_name_original": "Monosubstituted (ortho) monoiodobenzenes",
        "concept_code": "Concept_272"
    },
    {
        "concept_molecule_example": "\"001039\"",
        "svg_path": "12\\6\\1\\6\\svg\\BBOLNFYSRZVALD-UHFFFAOYSA-N_001039",
        "concept_name": "1_2-Diiodobenzenes",
        "concept_occurrences": "6",
        "concept_name_original": "\"1,2-Diiodobenzenes\"",
        "concept_code": "Concept_131"
    },
    {
        "concept_molecule_example": "\"001039\"",
        "svg_path": "12\\6\\1\\6\\svg\\BBOLNFYSRZVALD-UHFFFAOYSA-N_001039",
        "concept_name": "Diiodobenzenes",
        "concept_occurrences": "43",
        "concept_name_original": "Diiodobenzenes",
        "concept_code": "Concept_210"
    },
    {
        "concept_molecule_example": "\"001050\"",
        "svg_path": "18\\8\\1\\6\\svg\\DANMWBNOPFBJSZ-UHFFFAOYSA-N_001050",
        "concept_name": "Disubstituted (2_3)- monoiodobenzenes",
        "concept_occurrences": "82",
        "concept_name_original": "\"Disubstituted (2,3)- monoiodobenzenes\"",
        "concept_code": "Concept_215"
    },
    {
        "concept_molecule_example": "\"001103\"",
        "svg_path": "12\\6\\1\\6\\svg\\QBELEDRHMPMKHP-UHFFFAOYSA-N_001103",
        "concept_name": "Monosubstituted (ortho) monobromobenzenes",
        "concept_occurrences": "804",
        "concept_name_original": "Monosubstituted (ortho) monobromobenzenes",
        "concept_code": "Concept_269"
    },
    {
        "concept_molecule_example": "\"001103\"",
        "svg_path": "12\\6\\1\\6\\svg\\QBELEDRHMPMKHP-UHFFFAOYSA-N_001103",
        "concept_name": "Monosubstituted (ortho) monochlorobenzenes",
        "concept_occurrences": "1320",
        "concept_name_original": "Monosubstituted (ortho) monochlorobenzenes",
        "concept_code": "Concept_270"
    },
    {
        "concept_molecule_example": "\"001104\"",
        "svg_path": "12\\6\\1\\6\\svg\\JRGGUPZKKTVKOV-UHFFFAOYSA-N_001104",
        "concept_name": "Monosubstituted (meta) monobromobenzenes",
        "concept_occurrences": "1277",
        "concept_name_original": "Monosubstituted (meta) monobromobenzenes",
        "concept_code": "Concept_261"
    },
    {
        "concept_molecule_example": "\"001104\"",
        "svg_path": "12\\6\\1\\6\\svg\\JRGGUPZKKTVKOV-UHFFFAOYSA-N_001104",
        "concept_name": "Monosubstituted (meta) monochlorobenzenes",
        "concept_occurrences": "1446",
        "concept_name_original": "Monosubstituted (meta) monochlorobenzenes",
        "concept_code": "Concept_262"
    },
    {
        "concept_molecule_example": "\"001113\"",
        "svg_path": "12\\6\\1\\6\\svg\\UWOIDOQAQPUVOH-UHFFFAOYSA-N_001113",
        "concept_name": "Disubstituted (2_6)- monobromobenzenes",
        "concept_occurrences": "115",
        "concept_name_original": "\"Disubstituted (2,6)- monobromobenzenes\"",
        "concept_code": "Concept_224"
    },
    {
        "concept_molecule_example": "\"001114\"",
        "svg_path": "12\\6\\1\\6\\svg\\CFPZDVAZISWERM-UHFFFAOYSA-N_001114",
        "concept_name": "Disubstituted (3_4)- monobromobenzenes",
        "concept_occurrences": "1166",
        "concept_name_original": "\"Disubstituted (3,4)- monobromobenzenes\"",
        "concept_code": "Concept_228"
    },
    {
        "concept_molecule_example": "\"001121\"",
        "svg_path": "12\\6\\1\\6\\svg\\IPWBFGUBXWMIPR-UHFFFAOYSA-N_001121",
        "concept_name": "Monosubstituted (ortho) monofluorobenzenes",
        "concept_occurrences": "1240",
        "concept_name_original": "Monosubstituted (ortho) monofluorobenzenes",
        "concept_code": "Concept_271"
    },
    {
        "concept_molecule_example": "\"001125\"",
        "svg_path": "12\\6\\1\\6\\svg\\CTPUUDQIXKUAMO-UHFFFAOYSA-N_001125",
        "concept_name": "Monosubstituted (meta) monoiodobenzenes",
        "concept_occurrences": "178",
        "concept_name_original": "Monosubstituted (meta) monoiodobenzenes",
        "concept_code": "Concept_264"
    },
    {
        "concept_molecule_example": "\"001127\"",
        "svg_path": "17\\7\\1\\6\\svg\\UVRRJILIXQAAFK-UHFFFAOYSA-N_001127",
        "concept_name": "Disubstituted (2_5)- monobromobenzenes",
        "concept_occurrences": "633",
        "concept_name_original": "\"Disubstituted (2,5)- monobromobenzenes\"",
        "concept_code": "Concept_220"
    },
    {
        "concept_molecule_example": "\"001128\"",
        "svg_path": "17\\7\\1\\6\\svg\\MMEGELSFOYDPQW-UHFFFAOYSA-N_001128",
        "concept_name": "Disubstituted (2_4)- monobromobenzenes",
        "concept_occurrences": "678",
        "concept_name_original": "\"Disubstituted (2,4)- monobromobenzenes\"",
        "concept_code": "Concept_216"
    },
    {
        "concept_molecule_example": "\"001146\"",
        "svg_path": "12\\6\\1\\6\\svg\\VZHJIJZEOCBKRA-UHFFFAOYSA-N_001146",
        "concept_name": "Monosubstituted (meta) monofluorobenzenes",
        "concept_occurrences": "1342",
        "concept_name_original": "Monosubstituted (meta) monofluorobenzenes",
        "concept_code": "Concept_263"
    },
    {
        "concept_molecule_example": "\"001147\"",
        "svg_path": "12\\6\\1\\6\\svg\\RJCGZNCCVKIBHO-UHFFFAOYSA-N_001147",
        "concept_name": "Monosubstituted (para) monochlorobenzenes",
        "concept_occurrences": "2582",
        "concept_name_original": "Monosubstituted (para) monochlorobenzenes",
        "concept_code": "Concept_278"
    },
    {
        "concept_molecule_example": "\"001147\"",
        "svg_path": "12\\6\\1\\6\\svg\\RJCGZNCCVKIBHO-UHFFFAOYSA-N_001147",
        "concept_name": "Monosubstituted (para) monofluorobenzenes",
        "concept_occurrences": "2598",
        "concept_name_original": "Monosubstituted (para) monofluorobenzenes",
        "concept_code": "Concept_279"
    },
    {
        "concept_molecule_example": "\"001151\"",
        "svg_path": "12\\6\\1\\6\\svg\\GWQSENYKCGJTRI-UHFFFAOYSA-N_001151",
        "concept_name": "Monosubstituted (para) monoiodobenzenes",
        "concept_occurrences": "290",
        "concept_name_original": "Monosubstituted (para) monoiodobenzenes",
        "concept_code": "Concept_280"
    },
    {
        "concept_molecule_example": "\"001152\"",
        "svg_path": "15\\7\\1\\6\\svg\\PJYASWQMTNNSSL-UHFFFAOYSA-N_001152",
        "concept_name": "Disubstituted (2_5)- monochlorobenzenes",
        "concept_occurrences": "1665",
        "concept_name_original": "\"Disubstituted (2,5)- monochlorobenzenes\"",
        "concept_code": "Concept_221"
    },
    {
        "concept_molecule_example": "\"001152\"",
        "svg_path": "15\\7\\1\\6\\svg\\PJYASWQMTNNSSL-UHFFFAOYSA-N_001152",
        "concept_name": "Disubstituted (3_4)- monoiodobenzenes",
        "concept_occurrences": "162",
        "concept_name_original": "\"Disubstituted (3,4)- monoiodobenzenes\"",
        "concept_code": "Concept_231"
    },
    {
        "concept_molecule_example": "\"001158\"",
        "svg_path": "12\\6\\1\\6\\svg\\WQONPSCCEXUXTQ-UHFFFAOYSA-N_001158",
        "concept_name": "1_2-Dibromobenzenes",
        "concept_occurrences": "89",
        "concept_name_original": "\"1,2-Dibromobenzenes\"",
        "concept_code": "Concept_127"
    },
    {
        "concept_molecule_example": "\"001158\"",
        "svg_path": "12\\6\\1\\6\\svg\\WQONPSCCEXUXTQ-UHFFFAOYSA-N_001158",
        "concept_name": "Dibromobenzenes",
        "concept_occurrences": "488",
        "concept_name_original": "Dibromobenzenes",
        "concept_code": "Concept_204"
    },
    {
        "concept_molecule_example": "\"001159\"",
        "svg_path": "12\\6\\1\\6\\svg\\JSRLURSZEMLAFO-UHFFFAOYSA-N_001159",
        "concept_name": "1_3-Dibromobenzenes",
        "concept_occurrences": "318",
        "concept_name_original": "\"1,3-Dibromobenzenes\"",
        "concept_code": "Concept_138"
    },
    {
        "concept_molecule_example": "\"001167\"",
        "svg_path": "12\\6\\1\\6\\svg\\ZQKJCBDCOGLKCQ-UHFFFAOYSA-N_001167",
        "concept_name": "Disubstituted (2_4)- monoiodobenzenes",
        "concept_occurrences": "170",
        "concept_name_original": "\"Disubstituted (2,4)- monoiodobenzenes\"",
        "concept_code": "Concept_219"
    },
    {
        "concept_molecule_example": "\"001168\"",
        "svg_path": "12\\6\\1\\6\\svg\\SBHVNORGKIPGCL-UHFFFAOYSA-N_001168",
        "concept_name": "Disubstituted (2_5)- monoiodobenzenes",
        "concept_occurrences": "157",
        "concept_name_original": "\"Disubstituted (2,5)- monoiodobenzenes\"",
        "concept_code": "Concept_223"
    },
    {
        "concept_molecule_example": "\"001169\"",
        "svg_path": "12\\6\\1\\6\\svg\\GOYDNIKZWGIXJT-UHFFFAOYSA-N_001169",
        "concept_name": "1_2-Difluorobenzenes",
        "concept_occurrences": "1239",
        "concept_name_original": "\"1,2-Difluorobenzenes\"",
        "concept_code": "Concept_129"
    },
    {
        "concept_molecule_example": "\"001169\"",
        "svg_path": "12\\6\\1\\6\\svg\\GOYDNIKZWGIXJT-UHFFFAOYSA-N_001169",
        "concept_name": "Difluorobenzenes",
        "concept_occurrences": "3743",
        "concept_name_original": "Difluorobenzenes",
        "concept_code": "Concept_208"
    },
    {
        "concept_molecule_example": "\"001170\"",
        "svg_path": "12\\6\\1\\6\\svg\\QUGUFLJIAFISSW-UHFFFAOYSA-N_001170",
        "concept_name": "1_4-Difluorobenzenes",
        "concept_occurrences": "429",
        "concept_name_original": "\"1,4-Difluorobenzenes\"",
        "concept_code": "Concept_146"
    },
    {
        "concept_molecule_example": "\"001194\"",
        "svg_path": "15\\7\\1\\6\\svg\\IGZGUYVVBABKOY-UHFFFAOYSA-N_001194",
        "concept_name": "Monosubstituted (ortho) trifluoromethylbenzenes",
        "concept_occurrences": "378",
        "concept_name_original": "Monosubstituted (ortho) trifluoromethylbenzenes",
        "concept_code": "Concept_275"
    },
    {
        "concept_molecule_example": "\"001195\"",
        "svg_path": "15\\7\\1\\6\\svg\\IGISPMBUGPHLBY-UHFFFAOYSA-N_001195",
        "concept_name": "Monosubstituted (meta) trifluoromethylbenzenes",
        "concept_occurrences": "740",
        "concept_name_original": "Monosubstituted (meta) trifluoromethylbenzenes",
        "concept_code": "Concept_267"
    },
    {
        "concept_molecule_example": "\"001196\"",
        "svg_path": "15\\7\\1\\6\\svg\\SKGRFPGOGCHDPC-UHFFFAOYSA-N_001196",
        "concept_name": "Monosubstituted (para) trifluoromethylbenzenes",
        "concept_occurrences": "680",
        "concept_name_original": "Monosubstituted (para) trifluoromethylbenzenes",
        "concept_code": "Concept_283"
    },
    {
        "concept_molecule_example": "\"001225\"",
        "svg_path": "12\\6\\1\\6\\svg\\AITNMTXHTIIIBB-UHFFFAOYSA-N_001225",
        "concept_name": "Monosubstituted (para) monobromobenzenes",
        "concept_occurrences": "1980",
        "concept_name_original": "Monosubstituted (para) monobromobenzenes",
        "concept_code": "Concept_277"
    },
    {
        "concept_molecule_example": "\"001232\"",
        "svg_path": "12\\6\\1\\6\\svg\\UEMGWPRHOOEKTA-UHFFFAOYSA-N_001232",
        "concept_name": "1_3-Difluorobenzenes",
        "concept_occurrences": "2083",
        "concept_name_original": "\"1,3-Difluorobenzenes\"",
        "concept_code": "Concept_140"
    },
    {
        "concept_molecule_example": "\"001290\"",
        "svg_path": "13\\6\\1\\6\\svg\\HFHFGHLXUCOHLN-UHFFFAOYSA-N_001290",
        "concept_name": "Phenol",
        "concept_occurrences": "4272",
        "concept_name_original": "Phenol",
        "concept_code": "Concept_382"
    },
    {
        "concept_molecule_example": "\"001356\"",
        "svg_path": "12\\6\\1\\6\\svg\\ZQBFAOFFOQMSGJ-UHFFFAOYSA-N_001356",
        "concept_name": "Hexafluorobenzenes",
        "concept_occurrences": "1",
        "concept_name_original": "Hexafluorobenzenes",
        "concept_code": "Concept_239"
    },
    {
        "concept_molecule_example": "\"001416\"",
        "svg_path": "12\\6\\1\\6\\svg\\JHLKSIOJYMGSMB-UHFFFAOYSA-N_001416",
        "concept_name": "Disubstituted (3_5)- monobromobenzenes",
        "concept_occurrences": "291",
        "concept_name_original": "\"Disubstituted (3,5)- monobromobenzenes\"",
        "concept_code": "Concept_232"
    },
    {
        "concept_molecule_example": "\"001427\"",
        "svg_path": "13\\6\\1\\6\\svg\\IGYXYGDEYHNFFT-UHFFFAOYSA-N_001427",
        "concept_name": "Disubstituted (3_4)- monofluorobenzenes",
        "concept_occurrences": "1648",
        "concept_name_original": "\"Disubstituted (3,4)- monofluorobenzenes\"",
        "concept_code": "Concept_230"
    },
    {
        "concept_molecule_example": "\"001450\"",
        "svg_path": "14\\4\\1\\0\\svg\\JBYHSSAVUBIJMK-UHFFFAOYSA-N_001450",
        "concept_name": "6-Membered heterocycle",
        "concept_occurrences": "59381",
        "concept_name_original": "6-Membered heterocycle",
        "concept_code": "Concept_172"
    },
    {
        "concept_molecule_example": "\"001484\"",
        "svg_path": "12\\6\\1\\6\\svg\\RFKBODCWHNDUTJ-UHFFFAOYSA-N_001484",
        "concept_name": "Disubstituted (3_5)- monochlorobenzenes",
        "concept_occurrences": "404",
        "concept_name_original": "\"Disubstituted (3,5)- monochlorobenzenes\"",
        "concept_code": "Concept_233"
    },
    {
        "concept_molecule_example": "\"001525\"",
        "svg_path": "16\\7\\1\\6\\svg\\YQQKTCBMKQQOSM-UHFFFAOYSA-N_001525",
        "concept_name": "Trifluoromethylthio benzenes",
        "concept_occurrences": "84",
        "concept_name_original": "Trifluoromethylthio benzenes",
        "concept_code": "Concept_341"
    },
    {
        "concept_molecule_example": "\"001550\"",
        "svg_path": "6\\2\\0\\0\\svg\\GHXZPUGJZVBLGC-UHFFFAOYSA-N_001550",
        "concept_name": "Iodo",
        "concept_occurrences": "3580",
        "concept_name_original": "Iodo",
        "concept_code": "Concept_79"
    },
    {
        "concept_molecule_example": "\"001585\"",
        "svg_path": "12\\6\\1\\6\\svg\\JXUKFFRPLNTYIV-UHFFFAOYSA-N_001585",
        "concept_name": "1_3_5-Trifluorobenzenes",
        "concept_occurrences": "64",
        "concept_name_original": "\"1,3,5-Trifluorobenzenes\"",
        "concept_code": "Concept_136"
    },
    {
        "concept_molecule_example": "\"001585\"",
        "svg_path": "12\\6\\1\\6\\svg\\JXUKFFRPLNTYIV-UHFFFAOYSA-N_001585",
        "concept_name": "Trifluorobenzenes",
        "concept_occurrences": "690",
        "concept_name_original": "Trifluorobenzenes",
        "concept_code": "Concept_339"
    },
    {
        "concept_molecule_example": "\"001598\"",
        "svg_path": "15\\7\\1\\6\\svg\\NIOGDCDTKPQEAT-UHFFFAOYSA-N_001598",
        "concept_name": "Disubstituted (2_4)- monochlorobenzenes",
        "concept_occurrences": "1281",
        "concept_name_original": "\"Disubstituted (2,4)- monochlorobenzenes\"",
        "concept_code": "Concept_217"
    },
    {
        "concept_molecule_example": "\"001599\"",
        "svg_path": "15\\7\\1\\6\\svg\\FNPVYRJTBXHIPB-UHFFFAOYSA-N_001599",
        "concept_name": "Disubstituted (2_3)- monochlorobenzenes",
        "concept_occurrences": "757",
        "concept_name_original": "\"Disubstituted (2,3)- monochlorobenzenes\"",
        "concept_code": "Concept_213"
    },
    {
        "concept_molecule_example": "\"001599\"",
        "svg_path": "15\\7\\1\\6\\svg\\FNPVYRJTBXHIPB-UHFFFAOYSA-N_001599",
        "concept_name": "Disubstituted (2_3)- monofluorobenzenes",
        "concept_occurrences": "856",
        "concept_name_original": "\"Disubstituted (2,3)- monofluorobenzenes\"",
        "concept_code": "Concept_214"
    },
    {
        "concept_molecule_example": "\"001620\"",
        "svg_path": "15\\7\\1\\6\\svg\\VXKYOKPNAXNAFU-UHFFFAOYSA-N_001620",
        "concept_name": "Disubstituted (2_4)- monofluorobenzenes",
        "concept_occurrences": "1502",
        "concept_name_original": "\"Disubstituted (2,4)- monofluorobenzenes\"",
        "concept_code": "Concept_218"
    },
    {
        "concept_molecule_example": "\"001622\"",
        "svg_path": "12\\6\\1\\6\\svg\\DVTULTINXNWGJY-UHFFFAOYSA-N_001622",
        "concept_name": "Trisubstituted (2_4_5)- monobromobenzenes",
        "concept_occurrences": "529",
        "concept_name_original": "\"Trisubstituted (2,4,5)- monobromobenzenes\"",
        "concept_code": "Concept_353"
    },
    {
        "concept_molecule_example": "\"001672\"",
        "svg_path": "15\\7\\1\\6\\svg\\MKFCYQTVSDCXAQ-UHFFFAOYSA-N_001672",
        "concept_name": "Disubstituted (2_5)- monofluorobenzenes",
        "concept_occurrences": "2225",
        "concept_name_original": "\"Disubstituted (2,5)- monofluorobenzenes\"",
        "concept_code": "Concept_222"
    },
    {
        "concept_molecule_example": "\"001672\"",
        "svg_path": "15\\7\\1\\6\\svg\\MKFCYQTVSDCXAQ-UHFFFAOYSA-N_001672",
        "concept_name": "Disubstituted (3_4)- monochlorobenzenes",
        "concept_occurrences": "1336",
        "concept_name_original": "\"Disubstituted (3,4)- monochlorobenzenes\"",
        "concept_code": "Concept_229"
    },
    {
        "concept_molecule_example": "\"001689\"",
        "svg_path": "17\\7\\1\\6\\svg\\OXINUINVVAJJSE-UHFFFAOYSA-N_001689",
        "concept_name": "Trisubstituted (2_4_5)- monochlorobenzenes",
        "concept_occurrences": "452",
        "concept_name_original": "\"Trisubstituted (2,4,5)- monochlorobenzenes\"",
        "concept_code": "Concept_354"
    },
    {
        "concept_molecule_example": "\"001689\"",
        "svg_path": "17\\7\\1\\6\\svg\\OXINUINVVAJJSE-UHFFFAOYSA-N_001689",
        "concept_name": "Trisubstituted (2_4_5)- monofluorobenzenes",
        "concept_occurrences": "390",
        "concept_name_original": "\"Trisubstituted (2,4,5)- monofluorobenzenes\"",
        "concept_code": "Concept_355"
    },
    {
        "concept_molecule_example": "\"001699\"",
        "svg_path": "12\\6\\1\\6\\svg\\PYLWMHQQBFSUBP-UHFFFAOYSA-N_001699",
        "concept_name": "Monofluorobenzenes",
        "concept_occurrences": "13503",
        "concept_name_original": "Monofluorobenzenes",
        "concept_code": "Concept_259"
    },
    {
        "concept_molecule_example": "\"001699\"",
        "svg_path": "12\\6\\1\\6\\svg\\PYLWMHQQBFSUBP-UHFFFAOYSA-N_001699",
        "concept_name": "Phenyl",
        "concept_occurrences": "93089",
        "concept_name_original": "Phenyl",
        "concept_code": "Concept_2"
    },
    {
        "concept_molecule_example": "\"001702\"",
        "svg_path": "14\\6\\1\\6\\svg\\WUJKFVGKLTWVSQ-UHFFFAOYSA-N_001702",
        "concept_name": "Trisubstituted (2_3_5)- monobromobenzenes",
        "concept_occurrences": "408",
        "concept_name_original": "\"Trisubstituted (2,3,5)- monobromobenzenes\"",
        "concept_code": "Concept_349"
    },
    {
        "concept_molecule_example": "\"001752\"",
        "svg_path": "12\\6\\1\\6\\svg\\SFPQFQUXAJOWNF-UHFFFAOYSA-N_001752",
        "concept_name": "1_3-Diiodobenzenes",
        "concept_occurrences": "25",
        "concept_name_original": "\"1,3-Diiodobenzenes\"",
        "concept_code": "Concept_141"
    },
    {
        "concept_molecule_example": "\"001791\"",
        "svg_path": "12\\6\\1\\6\\svg\\AJKNNUJQFALRIK-UHFFFAOYSA-N_001791",
        "concept_name": "1_2_3-Trifluorobenzenes",
        "concept_occurrences": "424",
        "concept_name_original": "\"1,2,3-Trifluorobenzenes\"",
        "concept_code": "Concept_115"
    },
    {
        "concept_molecule_example": "\"001792\"",
        "svg_path": "12\\6\\1\\6\\svg\\PEBWOGPSYUIOBP-UHFFFAOYSA-N_001792",
        "concept_name": "1_2_4-Trifluorobenzenes",
        "concept_occurrences": "202",
        "concept_name_original": "\"1,2,4-Trifluorobenzenes\"",
        "concept_code": "Concept_125"
    },
    {
        "concept_molecule_example": "\"001797\"",
        "svg_path": "12\\6\\1\\6\\svg\\RKWWASUTWAFKHA-UHFFFAOYSA-N_001797",
        "concept_name": "Disubstituted (2_3)- monobromobenzenes",
        "concept_occurrences": "265",
        "concept_name_original": "\"Disubstituted (2,3)- monobromobenzenes\"",
        "concept_code": "Concept_212"
    },
    {
        "concept_molecule_example": "\"001801\"",
        "svg_path": "12\\6\\1\\6\\svg\\HKJCELUUIFFSIN-UHFFFAOYSA-N_001801",
        "concept_name": "Trisubstituted (3_4_5)- monobromobenzenes",
        "concept_occurrences": "289",
        "concept_name_original": "\"Trisubstituted (3,4,5)- monobromobenzenes\"",
        "concept_code": "Concept_357"
    },
    {
        "concept_molecule_example": "\"002516\"",
        "svg_path": "10\\3\\0\\0\\svg\\SGJVENMTWFTZOJ-UHFFFAOYSA-N_002516",
        "concept_name": "Isothiocyanate",
        "concept_occurrences": "544",
        "concept_name_original": "Isothiocyanate",
        "concept_code": "Concept_54"
    },
    {
        "concept_molecule_example": "\"002518\"",
        "svg_path": "7\\1\\0\\0\\svg\\DNPRVXJGNANVCZ-UHFFFAOYSA-N_002518",
        "concept_name": "Nitro",
        "concept_occurrences": "9269",
        "concept_name_original": "Nitro",
        "concept_code": "Concept_40"
    },
    {
        "concept_molecule_example": "\"002576\"",
        "svg_path": "18\\8\\1\\6\\svg\\RCWIWNUVHNAUQC-UHFFFAOYSA-N_002576",
        "concept_name": "Disubstituted (3_5)- monofluorobenzenes",
        "concept_occurrences": "669",
        "concept_name_original": "\"Disubstituted (3,5)- monofluorobenzenes\"",
        "concept_code": "Concept_234"
    },
    {
        "concept_molecule_example": "\"002725\"",
        "svg_path": "10\\4\\1\\6\\svg\\KYQCOXFCLRTKLS-UHFFFAOYSA-N_002725",
        "concept_name": "6-Membered heteroaromatic",
        "concept_occurrences": "35777",
        "concept_name_original": "6-Membered heteroaromatic",
        "concept_code": "Concept_171"
    },
    {
        "concept_molecule_example": "\"002725\"",
        "svg_path": "10\\4\\1\\6\\svg\\KYQCOXFCLRTKLS-UHFFFAOYSA-N_002725",
        "concept_name": "Pyrazine",
        "concept_occurrences": "1141",
        "concept_name_original": "Pyrazine",
        "concept_code": "Concept_301"
    },
    {
        "concept_molecule_example": "\"002766\"",
        "svg_path": "8\\3\\1\\5\\svg\\FZWLAAWBMGSTSO-UHFFFAOYSA-N_002766",
        "concept_name": "5-Membered heteroaromatic",
        "concept_occurrences": "43841",
        "concept_name_original": "5-Membered heteroaromatic",
        "concept_code": "Concept_168"
    },
    {
        "concept_molecule_example": "\"002766\"",
        "svg_path": "8\\3\\1\\5\\svg\\FZWLAAWBMGSTSO-UHFFFAOYSA-N_002766",
        "concept_name": "5-Membered heterocycle",
        "concept_occurrences": "60602",
        "concept_name_original": "5-Membered heterocycle",
        "concept_code": "Concept_169"
    },
    {
        "concept_molecule_example": "\"002766\"",
        "svg_path": "8\\3\\1\\5\\svg\\FZWLAAWBMGSTSO-UHFFFAOYSA-N_002766",
        "concept_name": "Heteroaromatic",
        "concept_occurrences": "71682",
        "concept_name_original": "Heteroaromatic",
        "concept_code": "Concept_21"
    },
    {
        "concept_molecule_example": "\"002766\"",
        "svg_path": "8\\3\\1\\5\\svg\\FZWLAAWBMGSTSO-UHFFFAOYSA-N_002766",
        "concept_name": "Thiazole",
        "concept_occurrences": "2931",
        "concept_name_original": "Thiazole",
        "concept_code": "Concept_328"
    },
    {
        "concept_molecule_example": "\"002836\"",
        "svg_path": "12\\6\\1\\6\\svg\\WACNXHCZHTVBJM-UHFFFAOYSA-N_002836",
        "concept_name": "Pentafluorobenzenes",
        "concept_occurrences": "141",
        "concept_name_original": "Pentafluorobenzenes",
        "concept_code": "Concept_293"
    },
    {
        "concept_molecule_example": "\"002953\"",
        "svg_path": "18\\8\\1\\6\\svg\\ZLMKEENUYIUKKC-UHFFFAOYSA-N_002953",
        "concept_name": "Disubstituted (3_5)- monoiodobenzenes",
        "concept_occurrences": "53",
        "concept_name_original": "\"Disubstituted (3,5)- monoiodobenzenes\"",
        "concept_code": "Concept_235"
    },
    {
        "concept_molecule_example": "\"002976\"",
        "svg_path": "18\\8\\1\\6\\svg\\QTUGGVBKWIYQSS-UHFFFAOYSA-N_002976",
        "concept_name": "Disubstituted (2_6)- monoiodobenzenes",
        "concept_occurrences": "49",
        "concept_name_original": "\"Disubstituted (2,6)- monoiodobenzenes\"",
        "concept_code": "Concept_227"
    },
    {
        "concept_molecule_example": "\"003040\"",
        "svg_path": "16\\7\\1\\6\\svg\\GQHWSLKNULCZGI-UHFFFAOYSA-N_003040",
        "concept_name": "Trifluoromethoxy benzenes",
        "concept_occurrences": "1019",
        "concept_name_original": "Trifluoromethoxy benzenes",
        "concept_code": "Concept_340"
    },
    {
        "concept_molecule_example": "\"003242\"",
        "svg_path": "18\\8\\1\\6\\svg\\JTAUTNBVFDTYTI-UHFFFAOYSA-N_003242",
        "concept_name": "Disubstituted (2_6)- monofluorobenzenes",
        "concept_occurrences": "262",
        "concept_name_original": "\"Disubstituted (2,6)- monofluorobenzenes\"",
        "concept_code": "Concept_226"
    },
    {
        "concept_molecule_example": "\"003310\"",
        "svg_path": "12\\6\\1\\6\\svg\\SOZFIIXUNAKEJP-UHFFFAOYSA-N_003310",
        "concept_name": "1_2_3_4-Tetrafluorobenzenes",
        "concept_occurrences": "43",
        "concept_name_original": "\"1,2,3,4-Tetrafluorobenzenes\"",
        "concept_code": "Concept_104"
    },
    {
        "concept_molecule_example": "\"003310\"",
        "svg_path": "12\\6\\1\\6\\svg\\SOZFIIXUNAKEJP-UHFFFAOYSA-N_003310",
        "concept_name": "Tetrafluorobenzenes",
        "concept_occurrences": "122",
        "concept_name_original": "Tetrafluorobenzenes",
        "concept_code": "Concept_323"
    },
    {
        "concept_molecule_example": "\"003311\"",
        "svg_path": "12\\6\\1\\6\\svg\\UHHYOKRQTQBKSB-UHFFFAOYSA-N_003311",
        "concept_name": "1_2_3_5-Tetrafluorobenzenes",
        "concept_occurrences": "10",
        "concept_name_original": "\"1,2,3,5-Tetrafluorobenzenes\"",
        "concept_code": "Concept_108"
    },
    {
        "concept_molecule_example": "\"003312\"",
        "svg_path": "12\\6\\1\\6\\svg\\SDXUIOOHCIQXRP-UHFFFAOYSA-N_003312",
        "concept_name": "1_2_4_5-Tetrafluorobenzenes",
        "concept_occurrences": "70",
        "concept_name_original": "\"1,2,4,5-Tetrafluorobenzenes\"",
        "concept_code": "Concept_119"
    },
    {
        "concept_molecule_example": "\"003369\"",
        "svg_path": "12\\4\\1\\0\\svg\\STJYMUBZVMSMBP-UHFFFAOYSA-N_003369",
        "concept_name": "Cyclobutane",
        "concept_occurrences": "1344",
        "concept_name_original": "Cyclobutane",
        "concept_code": "Concept_200"
    },
    {
        "concept_molecule_example": "\"003454\"",
        "svg_path": "16\\4\\0\\0\\svg\\FUSFWUFSEJXMRQ-UHFFFAOYSA-N_003454",
        "concept_name": "Acetal",
        "concept_occurrences": "1424",
        "concept_name_original": "Acetal",
        "concept_code": "Concept_17"
    },
    {
        "concept_molecule_example": "\"003461\"",
        "svg_path": "12\\6\\1\\6\\svg\\SNHMUERNLJLMHN-UHFFFAOYSA-N_003461",
        "concept_name": "Monoiodobenzenes",
        "concept_occurrences": "1655",
        "concept_name_original": "Monoiodobenzenes",
        "concept_code": "Concept_260"
    },
    {
        "concept_molecule_example": "\"003497\"",
        "svg_path": "15\\8\\2\\9\\svg\\FCEHBMOGCRZNNI-UHFFFAOYSA-N_003497",
        "concept_name": "Benzothiophene",
        "concept_occurrences": "418",
        "concept_name_original": "Benzothiophene",
        "concept_code": "Concept_186"
    },
    {
        "concept_molecule_example": "\"003505\"",
        "svg_path": "10\\1\\0\\0\\svg\\HNQIVZYLYMDVSB-UHFFFAOYSA-N_003505",
        "concept_name": "Sulfonamide",
        "concept_occurrences": "2953",
        "concept_name_original": "Sulfonamide",
        "concept_code": "Concept_87"
    },
    {
        "concept_molecule_example": "\"003864\"",
        "svg_path": "12\\6\\1\\6\\svg\\MUUAQFJJUGVBGB-UHFFFAOYSA-N_003864",
        "concept_name": "Trisubstituted (2_3_4)- monobromobenzenes",
        "concept_occurrences": "207",
        "concept_name_original": "\"Trisubstituted (2,3,4)- monobromobenzenes\"",
        "concept_code": "Concept_345"
    },
    {
        "concept_molecule_example": "\"004179\"",
        "svg_path": "12\\6\\1\\6\\svg\\CAYJMDVKWMVOLG-UHFFFAOYSA-N_004179",
        "concept_name": "Trisubstituted (2_3_5)- monofluorobenzenes",
        "concept_occurrences": "198",
        "concept_name_original": "\"Trisubstituted (2,3,5)- monofluorobenzenes\"",
        "concept_code": "Concept_351"
    },
    {
        "concept_molecule_example": "\"004387\"",
        "svg_path": "12\\6\\1\\6\\svg\\PZKDJJMHRYNBOR-UHFFFAOYSA-N_004387",
        "concept_name": "Trisubstituted (3_4_5)- monofluorobenzenes",
        "concept_occurrences": "103",
        "concept_name_original": "\"Trisubstituted (3,4,5)- monofluorobenzenes\"",
        "concept_code": "Concept_359"
    },
    {
        "concept_molecule_example": "\"005006\"",
        "svg_path": "16\\5\\1\\0\\svg\\DHXVGJBLRPWPCS-UHFFFAOYSA-N_005006",
        "concept_name": "Tetrahydro-2H-pyran",
        "concept_occurrences": "1304",
        "concept_name_original": "Tetrahydro-2H-pyran",
        "concept_code": "Concept_324"
    },
    {
        "concept_molecule_example": "\"005025\"",
        "svg_path": "20\\6\\1\\0\\svg\\UZOFELREXGAFOI-UHFFFAOYSA-N_005025",
        "concept_name": "Piperidine",
        "concept_occurrences": "11898",
        "concept_name_original": "Piperidine",
        "concept_code": "Concept_299"
    },
    {
        "concept_molecule_example": "\"005088\"",
        "svg_path": "12\\5\\1\\5\\svg\\KJRRQXYWFQKJIP-UHFFFAOYSA-N_005088",
        "concept_name": "Furan",
        "concept_occurrences": "2236",
        "concept_name_original": "Furan",
        "concept_code": "Concept_236"
    },
    {
        "concept_molecule_example": "\"005327\"",
        "svg_path": "14\\4\\1\\0\\svg\\XDPCNPCKDGQBAN-UHFFFAOYSA-N_005327",
        "concept_name": "Tetrahydrofuran",
        "concept_occurrences": "921",
        "concept_name_original": "Tetrahydrofuran",
        "concept_code": "Concept_325"
    },
    {
        "concept_molecule_example": "\"005432\"",
        "svg_path": "9\\2\\0\\0\\svg\\JAMFGQBENKSWOF-UHFFFAOYSA-N_005432",
        "concept_name": "Ether",
        "concept_occurrences": "42665",
        "concept_name_original": "Ether",
        "concept_code": "Concept_14"
    },
    {
        "concept_molecule_example": "\"005432\"",
        "svg_path": "9\\2\\0\\0\\svg\\JAMFGQBENKSWOF-UHFFFAOYSA-N_005432",
        "concept_name": "Methoxy",
        "concept_occurrences": "17363",
        "concept_name_original": "Methoxy",
        "concept_code": "Concept_11"
    },
    {
        "concept_molecule_example": "\"005547\"",
        "svg_path": "21\\12\\3\\13\\svg\\IYYZUPMFVPLQIF-UHFFFAOYSA-N_005547",
        "concept_name": "Dibenzothiophene",
        "concept_occurrences": "16",
        "concept_name_original": "Dibenzothiophene",
        "concept_code": "Concept_203"
    },
    {
        "concept_molecule_example": "\"005575\"",
        "svg_path": "13\\6\\1\\6\\svg\\QEYONPKSDTUPAX-UHFFFAOYSA-N_005575",
        "concept_name": "Trisubstituted (2_3_5)- monochlorobenzenes",
        "concept_occurrences": "233",
        "concept_name_original": "\"Trisubstituted (2,3,5)- monochlorobenzenes\"",
        "concept_code": "Concept_350"
    },
    {
        "concept_molecule_example": "\"005605\"",
        "svg_path": "13\\6\\1\\6\\svg\\CCLYWHXHYLQWQK-UHFFFAOYSA-N_005605",
        "concept_name": "Trisubstituted (2_3_4)- monochlorobenzenes",
        "concept_occurrences": "164",
        "concept_name_original": "\"Trisubstituted (2,3,4)- monochlorobenzenes\"",
        "concept_code": "Concept_346"
    },
    {
        "concept_molecule_example": "\"005658\"",
        "svg_path": "16\\7\\1\\6\\svg\\RPTGCWUNGWVQET-UHFFFAOYSA-N_005658",
        "concept_name": "Trisubstituted (2_3_4)- monofluorobenzenes",
        "concept_occurrences": "151",
        "concept_name_original": "\"Trisubstituted (2,3,4)- monofluorobenzenes\"",
        "concept_code": "Concept_347"
    },
    {
        "concept_molecule_example": "\"005915\"",
        "svg_path": "13\\4\\0\\0\\svg\\CUABTFPYLQSPAE-UHFFFAOYSA-N_005915",
        "concept_name": "Thiocyanate",
        "concept_occurrences": "50",
        "concept_name_original": "Thiocyanate",
        "concept_code": "Concept_53"
    },
    {
        "concept_molecule_example": "\"006194\"",
        "svg_path": "15\\7\\1\\6\\svg\\XOJYLEJZALFLLW-UHFFFAOYSA-N_006194",
        "concept_name": "Monosubstituted (para) trichloromethylbenzenes",
        "concept_occurrences": "3",
        "concept_name_original": "Monosubstituted (para) trichloromethylbenzenes",
        "concept_code": "Concept_282"
    },
    {
        "concept_molecule_example": "\"008046\"",
        "svg_path": "16\\5\\1\\0\\svg\\QLPXKYBQRVRKPC-UHFFFAOYSA-N_008046",
        "concept_name": "2-Pyrroline",
        "concept_occurrences": "10",
        "concept_name_original": "2-Pyrroline",
        "concept_code": "Concept_159"
    },
    {
        "concept_molecule_example": "\"008671\"",
        "svg_path": "12\\6\\1\\6\\svg\\XLDRDGJJGGYJCO-UHFFFAOYSA-N_008671",
        "concept_name": "Trisubstituted (3_4_5)- monochlorobenzenes",
        "concept_occurrences": "132",
        "concept_name_original": "\"Trisubstituted (3,4,5)- monochlorobenzenes\"",
        "concept_code": "Concept_358"
    },
    {
        "concept_molecule_example": "\"009078\"",
        "svg_path": "9\\4\\0\\0\\svg\\XRGPFNGLRSIPSA-UHFFFAOYSA-N_009078",
        "concept_name": "Ketone",
        "concept_occurrences": "19151",
        "concept_name_original": "Ketone",
        "concept_code": "Concept_4"
    },
    {
        "concept_molecule_example": "\"010650\"",
        "svg_path": "16\\2\\0\\0\\svg\\SUHOOTKUPISOBE-UHFFFAOYSA-N_010650",
        "concept_name": "Phosphate",
        "concept_occurrences": "81",
        "concept_name_original": "Phosphate",
        "concept_code": "Concept_60"
    },
    {
        "concept_molecule_example": "\"010818\"",
        "svg_path": "22\\6\\1\\0\\svg\\UCFSYHMCKWNKAH-UHFFFAOYSA-N_010818",
        "concept_name": "Pinacol boronate",
        "concept_occurrences": "1729",
        "concept_name_original": "Pinacol boronate",
        "concept_code": "Concept_81"
    },
    {
        "concept_molecule_example": "\"011018\"",
        "svg_path": "82\\26\\0\\0\\svg\\CAUONNQGFXOEMY-UHFFFAOYSA-N_011018",
        "concept_name": "Distannane",
        "concept_occurrences": "1",
        "concept_name_original": "Distannane",
        "concept_code": "Concept_85"
    },
    {
        "concept_molecule_example": "\"011793\"",
        "svg_path": "10\\4\\1\\0\\svg\\PEEHTFAAVSWFBL-UHFFFAOYSA-N_011793",
        "concept_name": "Imide",
        "concept_occurrences": "1024",
        "concept_name_original": "Imide",
        "concept_code": "Concept_31"
    },
    {
        "concept_molecule_example": "\"011818\"",
        "svg_path": "19\\5\\1\\0\\svg\\JOMNTHCQHJPVAZ-YFKPBYRVSA-N_011818",
        "concept_name": "Piperazine",
        "concept_occurrences": "3837",
        "concept_name_original": "Piperazine",
        "concept_code": "Concept_298"
    },
    {
        "concept_molecule_example": "\"012705\"",
        "svg_path": "22\\6\\1\\0\\svg\\SSJXIUAHEKJCMH-PHDIDXHHSA-N_012705",
        "concept_name": "Diaminocyclohexane",
        "concept_occurrences": "276",
        "concept_name_original": "Diaminocyclohexane",
        "concept_code": "Concept_375"
    },
    {
        "concept_molecule_example": "\"013235\"",
        "svg_path": "15\\7\\1\\6\\svg\\CPKOXUVSOOKUDA-UHFFFAOYSA-N_013235",
        "concept_name": "Trisubstituted (2_4_5)- monoiodobenzenes",
        "concept_occurrences": "91",
        "concept_name_original": "\"Trisubstituted (2,4,5)- monoiodobenzenes\"",
        "concept_code": "Concept_356"
    },
    {
        "concept_molecule_example": "\"013251\"",
        "svg_path": "12\\6\\1\\6\\svg\\OTZQYBFTOANOJO-UHFFFAOYSA-N_013251",
        "concept_name": "Disubstituted (2_6)- monochlorobenzenes",
        "concept_occurrences": "113",
        "concept_name_original": "\"Disubstituted (2,6)- monochlorobenzenes\"",
        "concept_code": "Concept_225"
    },
    {
        "concept_molecule_example": "\"013417\"",
        "svg_path": "12\\4\\1\\6\\svg\\OYRRZWATULMEPF-UHFFFAOYSA-N_013417",
        "concept_name": "Pyrimidine",
        "concept_occurrences": "5127",
        "concept_name_original": "Pyrimidine",
        "concept_code": "Concept_309"
    },
    {
        "concept_molecule_example": "\"013932\"",
        "svg_path": "12\\4\\1\\0\\svg\\JVQIKJMSUIMUDI-UHFFFAOYSA-N_013932",
        "concept_name": "2_5-Dihydro-1H-pyrrole",
        "concept_occurrences": "391",
        "concept_name_original": "\"2,5-Dihydro-1H-pyrrole\"",
        "concept_code": "Concept_154"
    },
    {
        "concept_molecule_example": "\"018767\"",
        "svg_path": "10\\4\\1\\5\\svg\\SWEDAZLCYJDAGW-UHFFFAOYSA-N_018767",
        "concept_name": "Aromatic thiol",
        "concept_occurrences": "1847",
        "concept_name_original": "Aromatic thiol",
        "concept_code": "Concept_94"
    },
    {
        "concept_molecule_example": "\"019482\"",
        "svg_path": "11\\3\\0\\0\\svg\\GAWAYYRQGQZKCR-UWTATZPHSA-N_019482",
        "concept_name": "Chiral carboxylic acids",
        "concept_occurrences": "3841",
        "concept_name_original": "Chiral carboxylic acids",
        "concept_code": "Concept_196"
    },
    {
        "concept_molecule_example": "\"021382\"",
        "svg_path": "12\\6\\1\\6\\svg\\YDSUNVSIBZATIU-UHFFFAOYSA-N_021382",
        "concept_name": "Trisubstituted (2_3_4)- monoiodobenzenes",
        "concept_occurrences": "40",
        "concept_name_original": "\"Trisubstituted (2,3,4)- monoiodobenzenes\"",
        "concept_code": "Concept_348"
    },
    {
        "concept_molecule_example": "\"021385\"",
        "svg_path": "12\\6\\1\\6\\svg\\RLJXJUHMVYLIBW-UHFFFAOYSA-N_021385",
        "concept_name": "Trisubstituted (3_4_5)- monoiodobenzenes",
        "concept_occurrences": "33",
        "concept_name_original": "\"Trisubstituted (3,4,5)- monoiodobenzenes\"",
        "concept_code": "Concept_360"
    },
    {
        "concept_molecule_example": "\"021581\"",
        "svg_path": "19\\5\\1\\0\\svg\\FQUYSHZXSKYCSY-UHFFFAOYSA-N_021581",
        "concept_name": "1_4-Diazepane",
        "concept_occurrences": "400",
        "concept_name_original": "\"1,4-Diazepane\"",
        "concept_code": "Concept_143"
    },
    {
        "concept_molecule_example": "\"021581\"",
        "svg_path": "19\\5\\1\\0\\svg\\FQUYSHZXSKYCSY-UHFFFAOYSA-N_021581",
        "concept_name": "7-Membered heterocycle",
        "concept_occurrences": "1729",
        "concept_name_original": "7-Membered heterocycle",
        "concept_code": "Concept_177"
    },
    {
        "concept_molecule_example": "\"021690\"",
        "svg_path": "9\\3\\1\\5\\svg\\RAXXELZNTBOGNW-UHFFFAOYSA-N_021690",
        "concept_name": "Imidazole",
        "concept_occurrences": "1877",
        "concept_name_original": "Imidazole",
        "concept_code": "Concept_249"
    },
    {
        "concept_molecule_example": "\"021866\"",
        "svg_path": "15\\5\\1\\0\\svg\\BRTFVKHPEHKBQF-UHFFFAOYSA-N_021866",
        "concept_name": "Cyclopentane",
        "concept_occurrences": "1622",
        "concept_name_original": "Cyclopentane",
        "concept_code": "Concept_378"
    },
    {
        "concept_molecule_example": "\"022053\"",
        "svg_path": "20\\6\\0\\0\\svg\\UCJOAMOXKLJGST-UHFFFAOYSA-N_022053",
        "concept_name": "Secondary ketimine",
        "concept_occurrences": "28",
        "concept_name_original": "Secondary ketimine",
        "concept_code": "Concept_28"
    },
    {
        "concept_molecule_example": "\"022072\"",
        "svg_path": "29\\15\\3\\12\\svg\\IRXSLJNXXZKURP-UHFFFAOYSA-N_022072",
        "concept_name": "Fmoc",
        "concept_occurrences": "799",
        "concept_name_original": "Fmoc",
        "concept_code": "Concept_96"
    },
    {
        "concept_molecule_example": "\"022124\"",
        "svg_path": "16\\4\\1\\0\\svg\\YUIOPHXTILULQC-UHFFFAOYSA-N_022124",
        "concept_name": "1_4-Dithiane",
        "concept_occurrences": "2",
        "concept_name_original": "\"1,4-Dithiane\"",
        "concept_code": "Concept_149"
    },
    {
        "concept_molecule_example": "\"023906\"",
        "svg_path": "10\\3\\1\\0\\svg\\BRLQWZUYTZBJKN-VKHMYHEASA-N_023906",
        "concept_name": "Chiral",
        "concept_occurrences": "16963",
        "concept_name_original": "Chiral",
        "concept_code": "Concept_86"
    },
    {
        "concept_molecule_example": "\"023906\"",
        "svg_path": "10\\3\\1\\0\\svg\\BRLQWZUYTZBJKN-VKHMYHEASA-N_023906",
        "concept_name": "Oxirane",
        "concept_occurrences": "213",
        "concept_name_original": "Oxirane",
        "concept_code": "Concept_288"
    },
    {
        "concept_molecule_example": "\"024550\"",
        "svg_path": "26\\10\\4\\0\\svg\\RXUUYFUQAGICCD-HBSQYQGZSA-N_024550",
        "concept_name": "Noradamantanes",
        "concept_occurrences": "1",
        "concept_name_original": "Noradamantanes",
        "concept_code": "Concept_370"
    },
    {
        "concept_molecule_example": "\"032480\"",
        "svg_path": "19\\5\\0\\0\\svg\\HEWZVZIVELJPQZ-UHFFFAOYSA-N_032480",
        "concept_name": "Ketal",
        "concept_occurrences": "575",
        "concept_name_original": "Ketal",
        "concept_code": "Concept_18"
    },
    {
        "concept_molecule_example": "\"032720\"",
        "svg_path": "15\\7\\2\\9\\svg\\MVXVYAKCVDQRLW-UHFFFAOYSA-N_032720",
        "concept_name": "Pyrrolo[2_3-b]pyridine",
        "concept_occurrences": "406",
        "concept_name_original": "\"Pyrrolo[2,3-b]pyridine\"",
        "concept_code": "Concept_316"
    },
    {
        "concept_molecule_example": "\"032730\"",
        "svg_path": "9\\3\\1\\5\\svg\\WTKZEGDFNFYCGP-UHFFFAOYSA-N_032730",
        "concept_name": "Pyrazole",
        "concept_occurrences": "9949",
        "concept_name_original": "Pyrazole",
        "concept_code": "Concept_302"
    },
    {
        "concept_molecule_example": "\"032744\"",
        "svg_path": "9\\4\\1\\5\\svg\\YTPLMLYBLZKORZ-UHFFFAOYSA-N_032744",
        "concept_name": "Thiophene",
        "concept_occurrences": "3910",
        "concept_name_original": "Thiophene",
        "concept_code": "Concept_332"
    },
    {
        "concept_molecule_example": "\"033012\"",
        "svg_path": "14\\4\\0\\0\\svg\\MHAIQPNJLRLFLO-GSVOUGTGSA-N_033012",
        "concept_name": "Chiral esters",
        "concept_occurrences": "1539",
        "concept_name_original": "Chiral esters",
        "concept_code": "Concept_197"
    },
    {
        "concept_molecule_example": "\"033296\"",
        "svg_path": "11\\2\\1\\5\\svg\\KRGDHNPMWOBKPN-UHFFFAOYSA-N_033296",
        "concept_name": "5-Membered heterocyclic sulfonyl chloride",
        "concept_occurrences": "422",
        "concept_name_original": "5-Membered heterocyclic sulfonyl chloride",
        "concept_code": "Concept_170"
    },
    {
        "concept_molecule_example": "\"034290\"",
        "svg_path": "10\\4\\1\\5\\svg\\KAESVJOAVNADME-UHFFFAOYSA-N_034290",
        "concept_name": "Pyrrole",
        "concept_occurrences": "888",
        "concept_name_original": "Pyrrole",
        "concept_code": "Concept_310"
    },
    {
        "concept_molecule_example": "\"035819\"",
        "svg_path": "15\\7\\1\\6\\svg\\JDZLOJYSBBLXQD-UHFFFAOYSA-N_035819",
        "concept_name": "Difluoromethyl/difluoromethoxy benzenes",
        "concept_occurrences": "109",
        "concept_name_original": "Difluoromethyl/difluoromethoxy benzenes",
        "concept_code": "Concept_209"
    },
    {
        "concept_molecule_example": "\"036023\"",
        "svg_path": "14\\6\\1\\6\\svg\\IPXQDOGSRPHRAL-UHFFFAOYSA-N_036023",
        "concept_name": "Trisubstituted (2_3_5)- monoiodobenzenes",
        "concept_occurrences": "71",
        "concept_name_original": "\"Trisubstituted (2,3,5)- monoiodobenzenes\"",
        "concept_code": "Concept_352"
    },
    {
        "concept_molecule_example": "\"036066\"",
        "svg_path": "15\\4\\0\\0\\svg\\BTANRVKWQNVYAZ-BYPYZUCNSA-N_036066",
        "concept_name": "Chiral alcohols",
        "concept_occurrences": "2080",
        "concept_name_original": "Chiral alcohols",
        "concept_code": "Concept_189"
    },
    {
        "concept_molecule_example": "\"036826\"",
        "svg_path": "10\\3\\1\\0\\svg\\MNFORVFSTILPAW-UHFFFAOYSA-N_036826",
        "concept_name": "4-Membered heterocycle",
        "concept_occurrences": "1642",
        "concept_name_original": "4-Membered heterocycle",
        "concept_code": "Concept_165"
    },
    {
        "concept_molecule_example": "\"036826\"",
        "svg_path": "10\\3\\1\\0\\svg\\MNFORVFSTILPAW-UHFFFAOYSA-N_036826",
        "concept_name": "Atezidine",
        "concept_occurrences": "879",
        "concept_name_original": "Atezidine",
        "concept_code": "Concept_180"
    },
    {
        "concept_molecule_example": "\"036833\"",
        "svg_path": "15\\7\\2\\9\\svg\\XWIYUCRMWCHYJR-UHFFFAOYSA-N_036833",
        "concept_name": "1H-Pyrrolo[3_2-b]pyridine",
        "concept_occurrences": "81",
        "concept_name_original": "\"1H-Pyrrolo[3,2-b]pyridine\"",
        "concept_code": "Concept_152"
    },
    {
        "concept_molecule_example": "\"037636\"",
        "svg_path": "12\\6\\1\\6\\svg\\PHDKZIIWDGIUCG-UHFFFAOYSA-N_037636",
        "concept_name": "1_2_4-Trichlorobenzenes",
        "concept_occurrences": "46",
        "concept_name_original": "\"1,2,4-Trichlorobenzenes\"",
        "concept_code": "Concept_124"
    },
    {
        "concept_molecule_example": "\"040034\"",
        "svg_path": "15\\7\\2\\9\\svg\\XLKDJOPOOHHZAN-UHFFFAOYSA-N_040034",
        "concept_name": "Pyrrolo[2_3-c]pyridine",
        "concept_occurrences": "105",
        "concept_name_original": "\"Pyrrolo[2,3-c]pyridine\"",
        "concept_code": "Concept_317"
    },
    {
        "concept_molecule_example": "\"040035\"",
        "svg_path": "15\\7\\2\\9\\svg\\SRSKXJVMVSSSHB-UHFFFAOYSA-N_040035",
        "concept_name": "1H-Pyrrolo[3_2-c]pyridine",
        "concept_occurrences": "68",
        "concept_name_original": "\"1H-Pyrrolo[3,2-c]pyridine\"",
        "concept_code": "Concept_153"
    },
    {
        "concept_molecule_example": "\"042901\"",
        "svg_path": "12\\6\\1\\6\\svg\\XKEFYDZQGKAQCN-UHFFFAOYSA-N_042901",
        "concept_name": "1_3_5-Trichlorobenzenes",
        "concept_occurrences": "47",
        "concept_name_original": "\"1,3,5-Trichlorobenzenes\"",
        "concept_code": "Concept_135"
    },
    {
        "concept_molecule_example": "\"042901\"",
        "svg_path": "12\\6\\1\\6\\svg\\XKEFYDZQGKAQCN-UHFFFAOYSA-N_042901",
        "concept_name": "Trichlorobenzenes",
        "concept_occurrences": "122",
        "concept_name_original": "Trichlorobenzenes",
        "concept_code": "Concept_336"
    },
    {
        "concept_molecule_example": "\"043140\"",
        "svg_path": "12\\6\\1\\6\\svg\\GMVJKSNPLYBFSO-UHFFFAOYSA-N_043140",
        "concept_name": "1_2_3-Tribromobenzenes",
        "concept_occurrences": "1",
        "concept_name_original": "\"1,2,3-Tribromobenzenes\"",
        "concept_code": "Concept_113"
    },
    {
        "concept_molecule_example": "\"043140\"",
        "svg_path": "12\\6\\1\\6\\svg\\GMVJKSNPLYBFSO-UHFFFAOYSA-N_043140",
        "concept_name": "Tribromobenzenes",
        "concept_occurrences": "18",
        "concept_name_original": "Tribromobenzenes",
        "concept_code": "Concept_333"
    },
    {
        "concept_molecule_example": "\"043315\"",
        "svg_path": "15\\4\\1\\0\\svg\\YNAVUWVOSKDBBP-UHFFFAOYSA-N_043315",
        "concept_name": "Morpholine",
        "concept_occurrences": "2099",
        "concept_name_original": "Morpholine",
        "concept_code": "Concept_285"
    },
    {
        "concept_molecule_example": "\"043401\"",
        "svg_path": "12\\2\\0\\0\\svg\\PIICEJLVQHRZGT-UHFFFAOYSA-N_043401",
        "concept_name": "Diamine",
        "concept_occurrences": "10346",
        "concept_name_original": "Diamine",
        "concept_code": "Concept_83"
    },
    {
        "concept_molecule_example": "\"043420\"",
        "svg_path": "20\\6\\3\\0\\svg\\IMNIMPAHZVJRPE-UHFFFAOYSA-N_043420",
        "concept_name": "Bicyclo[2.2.2]octanes",
        "concept_occurrences": "150",
        "concept_name_original": "Bicyclo[2.2.2]octanes",
        "concept_code": "Concept_364"
    },
    {
        "concept_molecule_example": "\"043433\"",
        "svg_path": "20\\5\\0\\0\\svg\\FESAXEDIWWXCNG-UHFFFAOYSA-N_043433",
        "concept_name": "Borinic ester",
        "concept_occurrences": "2",
        "concept_name_original": "Borinic ester",
        "concept_code": "Concept_65"
    },
    {
        "concept_molecule_example": "\"043870\"",
        "svg_path": "17\\6\\1\\6\\svg\\DMNYFEWFSFTYEL-UHFFFAOYSA-N_043870",
        "concept_name": "Pentafluorosulfanyl benzenes",
        "concept_occurrences": "135",
        "concept_name_original": "Pentafluorosulfanyl benzenes",
        "concept_code": "Concept_294"
    },
    {
        "concept_molecule_example": "\"043890\"",
        "svg_path": "8\\2\\1\\5\\svg\\NSPMIYGKQJPBQR-UHFFFAOYSA-N_043890",
        "concept_name": "1_2_4-Triazole",
        "concept_occurrences": "1855",
        "concept_name_original": "\"1,2,4-Triazole\"",
        "concept_code": "Concept_122"
    },
    {
        "concept_molecule_example": "\"044557\"",
        "svg_path": "21\\12\\3\\13\\svg\\TXCDCPKCNAJMEE-UHFFFAOYSA-N_044557",
        "concept_name": "Dibenzofuran",
        "concept_occurrences": "43",
        "concept_name_original": "Dibenzofuran",
        "concept_code": "Concept_202"
    },
    {
        "concept_molecule_example": "\"044571\"",
        "svg_path": "15\\7\\2\\9\\svg\\BAXOFTOLAUCFNW-UHFFFAOYSA-N_044571",
        "concept_name": "Indazole",
        "concept_occurrences": "1097",
        "concept_name_original": "Indazole",
        "concept_code": "Concept_251"
    },
    {
        "concept_molecule_example": "\"044613\"",
        "svg_path": "12\\6\\1\\6\\svg\\MVPPADPHJFYWMZ-UHFFFAOYSA-N_044613",
        "concept_name": "Monochlorobenzenes",
        "concept_occurrences": "12173",
        "concept_name_original": "Monochlorobenzenes",
        "concept_code": "Concept_258"
    },
    {
        "concept_molecule_example": "\"046054\"",
        "svg_path": "11\\6\\1\\5\\svg\\LWISLHRIEATKTM-UHFFFAOYSA-N_046054",
        "concept_name": "Terminal alkyne (aromatic hydrocarbon)",
        "concept_occurrences": "277",
        "concept_name_original": "Terminal alkyne (aromatic hydrocarbon)",
        "concept_code": "Concept_68"
    },
    {
        "concept_molecule_example": "\"046054\"",
        "svg_path": "11\\6\\1\\5\\svg\\LWISLHRIEATKTM-UHFFFAOYSA-N_046054",
        "concept_name": "Terminal alkyne (heteroaromatic)",
        "concept_occurrences": "164",
        "concept_name_original": "Terminal alkyne (heteroaromatic)",
        "concept_code": "Concept_73"
    },
    {
        "concept_molecule_example": "\"046382\"",
        "svg_path": "8\\3\\1\\5\\svg\\ZLTPDFXIESTBQG-UHFFFAOYSA-N_046382",
        "concept_name": "Isothiazole",
        "concept_occurrences": "140",
        "concept_name_original": "Isothiazole",
        "concept_code": "Concept_255"
    },
    {
        "concept_molecule_example": "\"046719\"",
        "svg_path": "24\\6\\0\\0\\svg\\VLSDXINSOMDCBK-BQYQJAHWSA-N_046719",
        "concept_name": "Azo",
        "concept_occurrences": "86",
        "concept_name_original": "Azo",
        "concept_code": "Concept_33"
    },
    {
        "concept_molecule_example": "\"046756\"",
        "svg_path": "18\\6\\1\\0\\svg\\XDTMQSROBMDMFD-UHFFFAOYSA-N_046756",
        "concept_name": "Cyclohexane",
        "concept_occurrences": "4165",
        "concept_name_original": "Cyclohexane",
        "concept_code": "Concept_379"
    },
    {
        "concept_molecule_example": "\"046777\"",
        "svg_path": "10\\2\\0\\0\\svg\\IAZDPXIOMUYVGZ-UHFFFAOYSA-N_046777",
        "concept_name": "Sulfoxide",
        "concept_occurrences": "117",
        "concept_name_original": "Sulfoxide",
        "concept_code": "Concept_48"
    },
    {
        "concept_molecule_example": "\"046805\"",
        "svg_path": "7\\2\\1\\0\\svg\\VOVUARRWDCVURC-UHFFFAOYSA-N_046805",
        "concept_name": "3-Membered heterocycle",
        "concept_occurrences": "330",
        "concept_name_original": "3-Membered heterocycle",
        "concept_code": "Concept_162"
    },
    {
        "concept_molecule_example": "\"046805\"",
        "svg_path": "7\\2\\1\\0\\svg\\VOVUARRWDCVURC-UHFFFAOYSA-N_046805",
        "concept_name": "Heterocycle",
        "concept_occurrences": "109097",
        "concept_name_original": "Heterocycle",
        "concept_code": "Concept_20"
    },
    {
        "concept_molecule_example": "\"046805\"",
        "svg_path": "7\\2\\1\\0\\svg\\VOVUARRWDCVURC-UHFFFAOYSA-N_046805",
        "concept_name": "Sulfide",
        "concept_occurrences": "16512",
        "concept_name_original": "Sulfide",
        "concept_code": "Concept_46"
    },
    {
        "concept_molecule_example": "\"046805\"",
        "svg_path": "7\\2\\1\\0\\svg\\VOVUARRWDCVURC-UHFFFAOYSA-N_046805",
        "concept_name": "Thiirane",
        "concept_occurrences": "1",
        "concept_name_original": "Thiirane",
        "concept_code": "Concept_331"
    },
    {
        "concept_molecule_example": "\"046825\"",
        "svg_path": "12\\6\\1\\6\\svg\\CAYGQBVSOZLICD-UHFFFAOYSA-N_046825",
        "concept_name": "Hexabromobenzenes",
        "concept_occurrences": "1",
        "concept_name_original": "Hexabromobenzenes",
        "concept_code": "Concept_237"
    },
    {
        "concept_molecule_example": "\"046831\"",
        "svg_path": "19\\9\\2\\6\\svg\\PQNFLJBBNBOBRQ-UHFFFAOYSA-N_046831",
        "concept_name": "Indane",
        "concept_occurrences": "581",
        "concept_name_original": "Indane",
        "concept_code": "Concept_373"
    },
    {
        "concept_molecule_example": "\"049745\"",
        "svg_path": "14\\6\\2\\9\\svg\\VTVRXITWWZGKHV-UHFFFAOYSA-N_049745",
        "concept_name": "Imidazo[1_2-b]pyridazine",
        "concept_occurrences": "77",
        "concept_name_original": "\"Imidazo[1,2-b]pyridazine\"",
        "concept_code": "Concept_244"
    },
    {
        "concept_molecule_example": "\"056771\"",
        "svg_path": "13\\3\\1\\0\\svg\\DISXFZWKRTZTRI-UHFFFAOYSA-N_056771",
        "concept_name": "2-Imidazoline",
        "concept_occurrences": "145",
        "concept_name_original": "2-Imidazoline",
        "concept_code": "Concept_157"
    },
    {
        "concept_molecule_example": "\"062213\"",
        "svg_path": "11\\2\\0\\0\\svg\\BSULWPSUVMOMAN-UHFFFAOYSA-N_062213",
        "concept_name": "Azide",
        "concept_occurrences": "211",
        "concept_name_original": "Azide",
        "concept_code": "Concept_32"
    },
    {
        "concept_molecule_example": "100400",
        "svg_path": "12\\3\\0\\0\\svg\\PXAJQJMDEXJWFB-UHFFFAOYSA-N_100400",
        "concept_name": "Ketoxime",
        "concept_occurrences": "222",
        "concept_name_original": "Ketoxime",
        "concept_code": "Concept_43"
    },
    {
        "concept_molecule_example": "101255",
        "svg_path": "26\\10\\4\\0\\svg\\ORILYTVJVMAKLC-UHFFFAOYSA-N_101255",
        "concept_name": "Adamantanes",
        "concept_occurrences": "417",
        "concept_name_original": "Adamantanes",
        "concept_code": "Concept_369"
    },
    {
        "concept_molecule_example": "130700",
        "svg_path": "14\\5\\1\\0\\svg\\BUDQDWGNQVEFAC-UHFFFAOYSA-N_130700",
        "concept_name": "3_4-Dihydro-2H-pyran",
        "concept_occurrences": "40",
        "concept_name_original": "\"3,4-Dihydro-2H-pyran\"",
        "concept_code": "Concept_160"
    },
    {
        "concept_molecule_example": "131402",
        "svg_path": "12\\6\\1\\6\\svg\\LFMWZTSOMGDDJU-UHFFFAOYSA-N_131402",
        "concept_name": "1_4-Diiodobenzenes",
        "concept_occurrences": "12",
        "concept_name_original": "\"1,4-Diiodobenzenes\"",
        "concept_code": "Concept_147"
    },
    {
        "concept_molecule_example": "137901",
        "svg_path": "13\\4\\1\\0\\svg\\PQXKWPLDPFFDJP-QWWZWVQMSA-N_137901",
        "concept_name": "Dimethyl",
        "concept_occurrences": "7804",
        "concept_name_original": "Dimethyl",
        "concept_code": "Concept_92"
    },
    {
        "concept_molecule_example": "152800",
        "svg_path": "9\\1\\0\\0\\svg\\AFVFQIVMOAPDHO-UHFFFAOYSA-N_152800",
        "concept_name": "Sulfonic acid",
        "concept_occurrences": "706",
        "concept_name_original": "Sulfonic acid",
        "concept_code": "Concept_51"
    },
    {
        "concept_molecule_example": "159800",
        "svg_path": "16\\8\\2\\10\\svg\\FLBAYUMRQUHISI-UHFFFAOYSA-N_159800",
        "concept_name": "1_8-Naphthyridine",
        "concept_occurrences": "83",
        "concept_name_original": "\"1,8-Naphthyridine\"",
        "concept_code": "Concept_151"
    },
    {
        "concept_molecule_example": "163200",
        "svg_path": "14\\6\\1\\6\\svg\\KHCZSJXTDDHLGJ-UHFFFAOYSA-N_163200",
        "concept_name": "Pentachlorobenzenes",
        "concept_occurrences": "6",
        "concept_name_original": "Pentachlorobenzenes",
        "concept_code": "Concept_291"
    },
    {
        "concept_molecule_example": "167000",
        "svg_path": "13\\5\\0\\0\\svg\\RIZZXCJMFIGMON-UHFFFAOYSA-N_167000",
        "concept_name": "Terminal alkyne (ester)",
        "concept_occurrences": "47",
        "concept_name_original": "Terminal alkyne (ester)",
        "concept_code": "Concept_71"
    },
    {
        "concept_molecule_example": "167200",
        "svg_path": "8\\3\\0\\0\\svg\\TVDSBUOJIPERQY-UHFFFAOYSA-N_167200",
        "concept_name": "Terminal alkyne (alcohol)",
        "concept_occurrences": "99",
        "concept_name_original": "Terminal alkyne (alcohol)",
        "concept_code": "Concept_69"
    },
    {
        "concept_molecule_example": "995154",
        "svg_path": "11\\5\\1\\6\\svg\\JUJWROOIHBZHMG-UHFFFAOYSA-N_995154",
        "concept_name": "Pyridine",
        "concept_occurrences": "14861",
        "concept_name_original": "Pyridine",
        "concept_code": "Concept_306"
    },
    {
        "concept_molecule_example": "BR1010",
        "svg_path": "14\\6\\2\\9\\svg\\QRUDEWIWKLJBPS-UHFFFAOYSA-N_BR1010",
        "concept_name": "Benzotriazole",
        "concept_occurrences": "293",
        "concept_name_original": "Benzotriazole",
        "concept_code": "Concept_187"
    },
    {
        "concept_molecule_example": "BR1026",
        "svg_path": "12\\6\\1\\6\\svg\\QARVLSVVCXYDNA-UHFFFAOYSA-N_BR1026",
        "concept_name": "Monobromobenzenes",
        "concept_occurrences": "8878",
        "concept_name_original": "Monobromobenzenes",
        "concept_code": "Concept_257"
    },
    {
        "concept_molecule_example": "BR1151",
        "svg_path": "12\\6\\1\\6\\svg\\SWJPEBQEEAHIGZ-UHFFFAOYSA-N_BR1151",
        "concept_name": "1_4-Dibromobenzenes",
        "concept_occurrences": "81",
        "concept_name_original": "\"1,4-Dibromobenzenes\"",
        "concept_code": "Concept_144"
    },
    {
        "concept_molecule_example": "BR1207",
        "svg_path": "18\\8\\1\\6\\svg\\UTNQAECQSKUEGD-UHFFFAOYSA-L_BR1207",
        "concept_name": "1_2_3_4-Tetrabromobenzenes",
        "concept_occurrences": "1",
        "concept_name_original": "\"1,2,3,4-Tetrabromobenzenes\"",
        "concept_code": "Concept_102"
    },
    {
        "concept_molecule_example": "M03001",
        "svg_path": "10\\2\\0\\0\\svg\\DHMQDGOQFOQNFH-UHFFFAOYSA-N_M03001",
        "concept_name": "Alpha-amino acid",
        "concept_occurrences": "1182",
        "concept_name_original": "Alpha-amino acid",
        "concept_code": "Concept_74"
    },
    {
        "concept_molecule_example": "M03001",
        "svg_path": "10\\2\\0\\0\\svg\\DHMQDGOQFOQNFH-UHFFFAOYSA-N_M03001",
        "concept_name": "Amino acid",
        "concept_occurrences": "2865",
        "concept_name_original": "Amino acid",
        "concept_code": "Concept_66"
    },
    {
        "concept_molecule_example": "M03438",
        "svg_path": "14\\3\\0\\0\\svg\\BKMMTJMQCTUHRP-VKHMYHEASA-N_M03438",
        "concept_name": "Chiral amino alcohols (chiral amino)",
        "concept_occurrences": "983",
        "concept_name_original": "Chiral amino alcohols (chiral amino)",
        "concept_code": "Concept_195"
    },
    {
        "concept_molecule_example": "\"049959\"",
        "svg_path": "7\\1\\0\\0\\svg\\BAVYZALUXZFZLV-UHFFFAOYSA-N_049959",
        "concept_name": "All amines (P+S+T)",
        "concept_occurrences": "63868",
        "concept_name_original": "All amines (P+S+T)",
        "concept_code": "Concept_26"
    },
    {
        "concept_molecule_example": "\"049959\"",
        "svg_path": "7\\1\\0\\0\\svg\\BAVYZALUXZFZLV-UHFFFAOYSA-N_049959",
        "concept_name": "N-methyl",
        "concept_occurrences": "14484",
        "concept_name_original": "N-methyl",
        "concept_code": "Concept_90"
    },
    {
        "concept_molecule_example": "\"049959\"",
        "svg_path": "7\\1\\0\\0\\svg\\BAVYZALUXZFZLV-UHFFFAOYSA-N_049959",
        "concept_name": "Primary amine",
        "concept_occurrences": "33194",
        "concept_name_original": "Primary amine",
        "concept_code": "Concept_23"
    },
    {
        "concept_molecule_example": "995231",
        "svg_path": "6\\2\\0\\0\\svg\\WEVYAHXRMPXWCK-UHFFFAOYSA-N_995231",
        "concept_name": "Nitrile",
        "concept_occurrences": "8177",
        "concept_name_original": "Nitrile",
        "concept_code": "Concept_37"
    },
    {
        "concept_molecule_example": "\"050095\"",
        "svg_path": "20\\8\\1\\6\\svg\\HASCQPSFPAKVEK-UHFFFAOYSA-N_050095",
        "concept_name": "Phosphines",
        "concept_occurrences": "109",
        "concept_name_original": "Phosphines",
        "concept_code": "Concept_58"
    },
    {
        "concept_molecule_example": "BRP102",
        "svg_path": "11\\3\\1\\0\\svg\\WNXJIVFYUVYPPR-UHFFFAOYSA-N_BRP102",
        "concept_name": "1_3-Dioxolane",
        "concept_occurrences": "615",
        "concept_name_original": "\"1,3-Dioxolane\"",
        "concept_code": "Concept_142"
    },
    {
        "concept_molecule_example": "\"067903\"",
        "svg_path": "14\\5\\1\\6\\svg\\JQJOGAGLBDBMLU-UHFFFAOYSA-N_067903",
        "concept_name": "6-Membered heterocyclic sulfonyl chloride",
        "concept_occurrences": "56",
        "concept_name_original": "6-Membered heterocyclic sulfonyl chloride",
        "concept_code": "Concept_173"
    },
    {
        "concept_molecule_example": "\"044647\"",
        "svg_path": "12\\6\\1\\6\\svg\\NTAZOPPTLZSXQH-UHFFFAOYSA-N_044647",
        "concept_name": "1_3_5-Triiodobenzenes",
        "concept_occurrences": "3",
        "concept_name_original": "\"1,3,5-Triiodobenzenes\"",
        "concept_code": "Concept_137"
    },
    {
        "concept_molecule_example": "\"044647\"",
        "svg_path": "12\\6\\1\\6\\svg\\NTAZOPPTLZSXQH-UHFFFAOYSA-N_044647",
        "concept_name": "Triiodobenzenes",
        "concept_occurrences": "4",
        "concept_name_original": "Triiodobenzenes",
        "concept_code": "Concept_342"
    },
    {
        "concept_molecule_example": "\"044719\"",
        "svg_path": "14\\4\\1\\0\\svg\\RYHBNJHYFVUHQT-UHFFFAOYSA-N_044719",
        "concept_name": "1_4-Dioxane",
        "concept_occurrences": "42",
        "concept_name_original": "\"1,4-Dioxane\"",
        "concept_code": "Concept_148"
    },
    {
        "concept_molecule_example": "\"044721\"",
        "svg_path": "8\\2\\0\\0\\svg\\QTBSBXVTEAMEQO-UHFFFAOYSA-N_044721",
        "concept_name": "Carboxylic acid",
        "concept_occurrences": "29457",
        "concept_name_original": "Carboxylic acid",
        "concept_code": "Concept_9"
    },
    {
        "concept_molecule_example": "\"044723\"",
        "svg_path": "14\\6\\1\\6\\svg\\PAYRUJLWNCNPSJ-UHFFFAOYSA-N_044723",
        "concept_name": "Aniline",
        "concept_occurrences": "6101",
        "concept_name_original": "Aniline",
        "concept_code": "Concept_377"
    },
    {
        "concept_molecule_example": "\"044814\"",
        "svg_path": "9\\3\\1\\0\\svg\\ROADCYAOHVSOLQ-UHFFFAOYSA-N_044814",
        "concept_name": "Oxetane",
        "concept_occurrences": "342",
        "concept_name_original": "Oxetane",
        "concept_code": "Concept_287"
    },
    {
        "concept_molecule_example": "\"044838\"",
        "svg_path": "8\\2\\0\\0\\svg\\VMHLLURERBWHNL-UHFFFAOYSA-M_044838",
        "concept_name": "Carboxylate",
        "concept_occurrences": "484",
        "concept_name_original": "Carboxylate",
        "concept_code": "Concept_8"
    },
    {
        "concept_molecule_example": "\"044859\"",
        "svg_path": "9\\2\\0\\0\\svg\\DLFVBJFMPXGRIB-UHFFFAOYSA-N_044859",
        "concept_name": "Amide",
        "concept_occurrences": "23034",
        "concept_name_original": "Amide",
        "concept_code": "Concept_22"
    },
    {
        "concept_molecule_example": "\"065249\"",
        "svg_path": "13\\6\\1\\5\\svg\\OTMRXENQDSQACG-UHFFFAOYSA-N_065249",
        "concept_name": "Dialdehyde",
        "concept_occurrences": "43",
        "concept_name_original": "Dialdehyde",
        "concept_code": "Concept_82"
    },
    {
        "concept_molecule_example": "\"067662\"",
        "svg_path": "7\\3\\0\\0\\svg\\LJZPPWWHKPGCHS-UHFFFAOYSA-N_067662",
        "concept_name": "Alkyne",
        "concept_occurrences": "1118",
        "concept_name_original": "Alkyne",
        "concept_code": "Concept_0"
    },
    {
        "concept_molecule_example": "\"067662\"",
        "svg_path": "7\\3\\0\\0\\svg\\LJZPPWWHKPGCHS-UHFFFAOYSA-N_067662",
        "concept_name": "Terminal alkyne",
        "concept_occurrences": "727",
        "concept_name_original": "Terminal alkyne",
        "concept_code": "Concept_1"
    },
    {
        "concept_molecule_example": "\"067662\"",
        "svg_path": "7\\3\\0\\0\\svg\\LJZPPWWHKPGCHS-UHFFFAOYSA-N_067662",
        "concept_name": "Terminal alkyne (aliphatic hydrocarbon)",
        "concept_occurrences": "440",
        "concept_name_original": "Terminal alkyne (aliphatic hydrocarbon)",
        "concept_code": "Concept_67"
    },
    {
        "concept_molecule_example": "\"067807\"",
        "svg_path": "10\\3\\0\\0\\svg\\BCMYXYHEMGPZJN-UHFFFAOYSA-N_067807",
        "concept_name": "Isocyanate",
        "concept_occurrences": "233",
        "concept_name_original": "Isocyanate",
        "concept_code": "Concept_35"
    },
    {
        "concept_molecule_example": "\"069166\"",
        "svg_path": "15\\7\\2\\9\\svg\\UTCSSFWDNNEEBH-UHFFFAOYSA-N_069166",
        "concept_name": "Imidazo[1_2-a]pyridine",
        "concept_occurrences": "876",
        "concept_name_original": "\"Imidazo[1,2-a]pyridine\"",
        "concept_code": "Concept_242"
    },
    {
        "concept_molecule_example": "\"069188\"",
        "svg_path": "15\\7\\2\\10\\svg\\YEYHFKBVNARCNE-UHFFFAOYSA-N_069188",
        "concept_name": "Pyrido[2_3-b]pyrazine",
        "concept_occurrences": "33",
        "concept_name_original": "\"Pyrido[2,3-b]pyrazine\"",
        "concept_code": "Concept_307"
    },
    {
        "concept_molecule_example": "\"069196\"",
        "svg_path": "14\\6\\2\\9\\svg\\INSWZAQOISIYDT-UHFFFAOYSA-N_069196",
        "concept_name": "Imidazo[1_2-a]pyrimidine",
        "concept_occurrences": "88",
        "concept_name_original": "\"Imidazo[1,2-a]pyrimidine\"",
        "concept_code": "Concept_243"
    },
    {
        "concept_molecule_example": "\"069107\"",
        "svg_path": "16\\8\\2\\10\\svg\\VSOSXKMEQPYESP-UHFFFAOYSA-N_069107",
        "concept_name": "1_6-Naphthyridine",
        "concept_occurrences": "71",
        "concept_name_original": "\"1,6-Naphthyridine\"",
        "concept_code": "Concept_150"
    },
    {
        "concept_molecule_example": "\"068792\"",
        "svg_path": "16\\8\\2\\9\\svg\\SIKJAQJRHWYJAI-UHFFFAOYSA-N_068792",
        "concept_name": "Indole",
        "concept_occurrences": "2588",
        "concept_name_original": "Indole",
        "concept_code": "Concept_252"
    },
    {
        "concept_molecule_example": "\"044992\"",
        "svg_path": "11\\2\\0\\0\\svg\\FRYHCSODNHYDPU-UHFFFAOYSA-N_044992",
        "concept_name": "Sulfonyl chloride",
        "concept_occurrences": "1550",
        "concept_name_original": "Sulfonyl chloride",
        "concept_code": "Concept_52"
    },
    {
        "concept_molecule_example": "\"044754\"",
        "svg_path": "10\\2\\0\\0\\svg\\ROSDSFDQCJNGOL-UHFFFAOYSA-N_044754",
        "concept_name": "Secondary amine",
        "concept_occurrences": "20315",
        "concept_name_original": "Secondary amine",
        "concept_code": "Concept_24"
    },
    {
        "concept_molecule_example": "\"050372\"",
        "svg_path": "12\\6\\1\\6\\svg\\FWAJPSIPOULHHH-UHFFFAOYSA-N_050372",
        "concept_name": "1_2_4-Tribromobenzenes",
        "concept_occurrences": "3",
        "concept_name_original": "\"1,2,4-Tribromobenzenes\"",
        "concept_code": "Concept_123"
    },
    {
        "concept_molecule_example": "\"076158\"",
        "svg_path": "14\\6\\2\\9\\svg\\JJTNLWSCFYERCK-UHFFFAOYSA-N_076158",
        "concept_name": "7H-Pyrrolo[2_3-d]pyrimidine",
        "concept_occurrences": "206",
        "concept_name_original": "\"7H-Pyrrolo[2,3-d]pyrimidine\"",
        "concept_code": "Concept_175"
    },
    {
        "concept_molecule_example": "\"076552\"",
        "svg_path": "8\\3\\1\\5\\svg\\CTAPFRYPJLPFDF-UHFFFAOYSA-N_076552",
        "concept_name": "Isoxazole",
        "concept_occurrences": "1173",
        "concept_name_original": "Isoxazole",
        "concept_code": "Concept_256"
    },
    {
        "concept_molecule_example": "\"076792\"",
        "svg_path": "14\\6\\2\\9\\svg\\MBVAHHOKMIRXLP-UHFFFAOYSA-N_076792",
        "concept_name": "Imidazo[1_2-a]pyrazine",
        "concept_occurrences": "67",
        "concept_name_original": "\"Imidazo[1,2-a]pyrazine\"",
        "concept_code": "Concept_241"
    },
    {
        "concept_molecule_example": "\"078468\"",
        "svg_path": "16\\6\\2\\10\\svg\\UYEUUXMDVNYCAM-UHFFFAOYSA-N_078468",
        "concept_name": "Pteridine",
        "concept_occurrences": "9",
        "concept_name_original": "Pteridine",
        "concept_code": "Concept_300"
    },
    {
        "concept_molecule_example": "\"078542\"",
        "svg_path": "23\\6\\1\\0\\svg\\WZMXSPCYRSRMJT-UHFFFAOYSA-N_078542",
        "concept_name": "Sulfamide",
        "concept_occurrences": "1",
        "concept_name_original": "Sulfamide",
        "concept_code": "Concept_88"
    },
    {
        "concept_molecule_example": "\"078654\"",
        "svg_path": "12\\3\\1\\0\\svg\\YAMHXTCMCPHKLN-UHFFFAOYSA-N_078654",
        "concept_name": "Imidazolidine",
        "concept_occurrences": "278",
        "concept_name_original": "Imidazolidine",
        "concept_code": "Concept_250"
    },
    {
        "concept_molecule_example": "\"078693\"",
        "svg_path": "13\\5\\2\\9\\svg\\KDCGOANMDULRCW-UHFFFAOYSA-N_078693",
        "concept_name": "7H-Purine",
        "concept_occurrences": "295",
        "concept_name_original": "7H-Purine",
        "concept_code": "Concept_174"
    },
    {
        "concept_molecule_example": "\"078701\"",
        "svg_path": "16\\8\\2\\10\\svg\\JWVCLYRUEFBMGU-UHFFFAOYSA-N_078701",
        "concept_name": "Quinazoline",
        "concept_occurrences": "707",
        "concept_name_original": "Quinazoline",
        "concept_code": "Concept_318"
    },
    {
        "concept_molecule_example": "\"078727\"",
        "svg_path": "15\\8\\2\\9\\svg\\IANQTJSKSUMEQM-UHFFFAOYSA-N_078727",
        "concept_name": "Benzofuran",
        "concept_occurrences": "375",
        "concept_name_original": "Benzofuran",
        "concept_code": "Concept_184"
    },
    {
        "concept_molecule_example": "\"078910\"",
        "svg_path": "14\\7\\2\\9\\svg\\BCMCBBGGLRIHSE-UHFFFAOYSA-N_078910",
        "concept_name": "Benzoxazole",
        "concept_occurrences": "534",
        "concept_name_original": "Benzoxazole",
        "concept_code": "Concept_188"
    },
    {
        "concept_molecule_example": "\"078957\"",
        "svg_path": "14\\5\\2\\0\\svg\\GJEZBVHHZQAEDB-UHFFFAOYSA-N_078957",
        "concept_name": "Bicyclo[3.1.0]hexanes",
        "concept_occurrences": "190",
        "concept_name_original": "Bicyclo[3.1.0]hexanes",
        "concept_code": "Concept_363"
    },
    {
        "concept_molecule_example": "\"079215\"",
        "svg_path": "10\\4\\1\\6\\svg\\PBMFSQRYOILNGV-UHFFFAOYSA-N_079215",
        "concept_name": "Pyridazine",
        "concept_occurrences": "821",
        "concept_name_original": "Pyridazine",
        "concept_code": "Concept_305"
    },
    {
        "concept_molecule_example": "\"079216\"",
        "svg_path": "16\\8\\2\\10\\svg\\XSCHRSMBECNVNS-UHFFFAOYSA-N_079216",
        "concept_name": "Quinoxaline",
        "concept_occurrences": "315",
        "concept_name_original": "Quinoxaline",
        "concept_code": "Concept_320"
    },
    {
        "concept_molecule_example": "\"079460\"",
        "svg_path": "14\\3\\0\\0\\svg\\HXKKHQJGJAFBHI-VKHMYHEASA-N_079460",
        "concept_name": "Chiral amino alcohols (all)",
        "concept_occurrences": "1378",
        "concept_name_original": "Chiral amino alcohols (all)",
        "concept_code": "Concept_192"
    },
    {
        "concept_molecule_example": "\"079460\"",
        "svg_path": "14\\3\\0\\0\\svg\\HXKKHQJGJAFBHI-VKHMYHEASA-N_079460",
        "concept_name": "Chiral amino alcohols (chiral alcohol)",
        "concept_occurrences": "795",
        "concept_name_original": "Chiral amino alcohols (chiral alcohol)",
        "concept_code": "Concept_194"
    },
    {
        "concept_molecule_example": "\"079486\"",
        "svg_path": "12\\6\\1\\6\\svg\\YWDUZLFWHVQCHY-UHFFFAOYSA-N_079486",
        "concept_name": "1_3_5-Tribromobenzenes",
        "concept_occurrences": "14",
        "concept_name_original": "\"1,3,5-Tribromobenzenes\"",
        "concept_code": "Concept_134"
    },
    {
        "concept_molecule_example": "\"079722\"",
        "svg_path": "9\\3\\1\\5\\svg\\CZWWCTHQXBMHDA-UHFFFAOYSA-N_079722",
        "concept_name": "Aromatic alcohol",
        "concept_occurrences": "7423",
        "concept_name_original": "Aromatic alcohol",
        "concept_code": "Concept_93"
    },
    {
        "concept_molecule_example": "\"079977\"",
        "svg_path": "14\\6\\2\\9\\svg\\KDOPAZIWBAHVJB-UHFFFAOYSA-N_079977",
        "concept_name": "5H-Pyrrolo[3_2-d]pyrimidine",
        "concept_occurrences": "31",
        "concept_name_original": "\"5H-Pyrrolo[3,2-d]pyrimidine\"",
        "concept_code": "Concept_167"
    },
    {
        "concept_molecule_example": "\"080281\"",
        "svg_path": "14\\4\\1\\0\\svg\\RWRDLPDLKQPQOW-UHFFFAOYSA-N_080281",
        "concept_name": "Pyrrolidine",
        "concept_occurrences": "7879",
        "concept_name_original": "Pyrrolidine",
        "concept_code": "Concept_311"
    },
    {
        "concept_molecule_example": "\"091686\"",
        "svg_path": "22\\12\\3\\13\\svg\\UJOBWOGCFQCDNV-UHFFFAOYSA-N_091686",
        "concept_name": "9H-Carbazole",
        "concept_occurrences": "108",
        "concept_name_original": "9H-Carbazole",
        "concept_code": "Concept_179"
    },
    {
        "concept_molecule_example": "\"091888\"",
        "svg_path": "8\\3\\1\\5\\svg\\ZCQWOFVYLHDMMC-UHFFFAOYSA-N_091888",
        "concept_name": "Oxazole",
        "concept_occurrences": "582",
        "concept_name_original": "Oxazole",
        "concept_code": "Concept_286"
    },
    {
        "concept_molecule_example": "\"091901\"",
        "svg_path": "9\\2\\1\\5\\svg\\PVGHNTXQMCYYGF-UHFFFAOYSA-N_091901",
        "concept_name": "1_2_3-Thiadiazole",
        "concept_occurrences": "78",
        "concept_name_original": "\"1,2,3-Thiadiazole\"",
        "concept_code": "Concept_111"
    },
    {
        "concept_molecule_example": "\"091938\"",
        "svg_path": "13\\4\\1\\0\\svg\\NCTCZGRRDXIGIY-UHFFFAOYSA-N_091938",
        "concept_name": "Methyl",
        "concept_occurrences": "35511",
        "concept_name_original": "Methyl",
        "concept_code": "Concept_89"
    },
    {
        "concept_molecule_example": "\"093165\"",
        "svg_path": "18\\8\\2\\9\\svg\\OAZGQWMFODAEEV-UHFFFAOYSA-N_093165",
        "concept_name": "Pyrrolo[1_2-c]pyrimdine",
        "concept_occurrences": "3",
        "concept_name_original": "\"Pyrrolo[1,2-c]pyrimdine\"",
        "concept_code": "Concept_315"
    },
    {
        "concept_molecule_example": "\"093451\"",
        "svg_path": "14\\7\\2\\9\\svg\\IOJUPLGTWVMSFF-UHFFFAOYSA-N_093451",
        "concept_name": "Benzothiazole",
        "concept_occurrences": "1045",
        "concept_name_original": "Benzothiazole",
        "concept_code": "Concept_185"
    },
    {
        "concept_molecule_example": "\"093620\"",
        "svg_path": "15\\7\\2\\9\\svg\\QSLLFYVBWXWUQT-UHFFFAOYSA-N_093620",
        "concept_name": "Pyrrolo[1_2-a]pyrazine",
        "concept_occurrences": "5",
        "concept_name_original": "\"Pyrrolo[1,2-a]pyrazine\"",
        "concept_code": "Concept_312"
    },
    {
        "concept_molecule_example": "208731",
        "svg_path": "23\\7\\3\\0\\svg\\XJKNACDCUAFDHD-UHFFFAOYSA-N_208731",
        "concept_name": "Bicyclo[3.2.2]nonanes",
        "concept_occurrences": "18",
        "concept_name_original": "Bicyclo[3.2.2]nonanes",
        "concept_code": "Concept_366"
    },
    {
        "concept_molecule_example": "208772",
        "svg_path": "16\\8\\2\\10\\svg\\LFSXCDWNBUNEEM-UHFFFAOYSA-N_208772",
        "concept_name": "Phthalazine",
        "concept_occurrences": "108",
        "concept_name_original": "Phthalazine",
        "concept_code": "Concept_297"
    },
    {
        "concept_molecule_example": "209469",
        "svg_path": "20\\7\\2\\5\\svg\\CVRRKUUSGIROSB-UHFFFAOYSA-N_209469",
        "concept_name": "1_3_2-dioxaborinane",
        "concept_occurrences": "44",
        "concept_name_original": "\"1,3,2-dioxaborinane\"",
        "concept_code": "Concept_376"
    },
    {
        "concept_molecule_example": "211658",
        "svg_path": "37\\16\\2\\12\\svg\\RLUUJGCHAAXMFW-UHFFFAOYSA-N_211658",
        "concept_name": "Borinic acid",
        "concept_occurrences": "2",
        "concept_name_original": "Borinic acid",
        "concept_code": "Concept_64"
    },
    {
        "concept_molecule_example": "\"050767\"",
        "svg_path": "16\\4\\0\\0\\svg\\IOGXOCVLYRDXLW-UHFFFAOYSA-N_050767",
        "concept_name": "Nitrite",
        "concept_occurrences": "2",
        "concept_name_original": "Nitrite",
        "concept_code": "Concept_39"
    },
    {
        "concept_molecule_example": "\"094007\"",
        "svg_path": "6\\2\\0\\0\\svg\\LEQAOMBKQFMDFZ-UHFFFAOYSA-N_094007",
        "concept_name": "Aldehyde",
        "concept_occurrences": "6353",
        "concept_name_original": "Aldehyde",
        "concept_code": "Concept_5"
    },
    {
        "concept_molecule_example": "\"094104\"",
        "svg_path": "7\\2\\0\\0\\svg\\WETWJCDKMRHUPV-UHFFFAOYSA-N_094104",
        "concept_name": "Acyl halide",
        "concept_occurrences": "1698",
        "concept_name_original": "Acyl halide",
        "concept_code": "Concept_6"
    },
    {
        "concept_molecule_example": "214665",
        "svg_path": "9\\1\\0\\0\\svg\\KTMKRRPZPWUYKK-UHFFFAOYSA-N_214665",
        "concept_name": "Boronic acid",
        "concept_occurrences": "2435",
        "concept_name_original": "Boronic acid",
        "concept_code": "Concept_62"
    },
    {
        "concept_molecule_example": "214783",
        "svg_path": "15\\7\\2\\9\\svg\\DVUBDHRTVYLIPA-UHFFFAOYSA-N_214783",
        "concept_name": "Pyrazolo[1_5-a]pyridine",
        "concept_occurrences": "122",
        "concept_name_original": "\"Pyrazolo[1,5-a]pyridine\"",
        "concept_code": "Concept_304"
    },
    {
        "concept_molecule_example": "216310",
        "svg_path": "12\\6\\1\\6\\svg\\RFFLAFLAYFXFSW-UHFFFAOYSA-N_216310",
        "concept_name": "1_2-Dichlorobenzenes",
        "concept_occurrences": "1221",
        "concept_name_original": "\"1,2-Dichlorobenzenes\"",
        "concept_code": "Concept_128"
    },
    {
        "concept_molecule_example": "216564",
        "svg_path": "18\\7\\1\\6\\svg\\DWWMSEANWMWMCB-UHFFFAOYSA-N_216564",
        "concept_name": "Tribromomethylthio benzenes",
        "concept_occurrences": "1",
        "concept_name_original": "Tribromomethylthio benzenes",
        "concept_code": "Concept_335"
    },
    {
        "concept_molecule_example": "217599",
        "svg_path": "9\\3\\1\\0\\svg\\DQOHDRDDPZNSQI-UHFFFAOYSA-N_217599",
        "concept_name": "Thietane",
        "concept_occurrences": "20",
        "concept_name_original": "Thietane",
        "concept_code": "Concept_330"
    },
    {
        "concept_molecule_example": "219977",
        "svg_path": "15\\7\\2\\9\\svg\\JMANUKZDKDKBJP-UHFFFAOYSA-N_219977",
        "concept_name": "Imidazo[1_5-a]pyridine",
        "concept_occurrences": "60",
        "concept_name_original": "\"Imidazo[1,5-a]pyridine\"",
        "concept_code": "Concept_246"
    },
    {
        "concept_molecule_example": "220515",
        "svg_path": "13\\5\\2\\9\\svg\\VQNDBXJTIJKJPV-UHFFFAOYSA-N_220515",
        "concept_name": "[1_2_3]Triazolo[4_5-b]pyridine",
        "concept_occurrences": "12",
        "concept_name_original": "\"[1,2,3]Triazolo[4,5-b]pyridine\"",
        "concept_code": "Concept_99"
    },
    {
        "concept_molecule_example": "223376",
        "svg_path": "14\\6\\2\\9\\svg\\GAMYYCRTACQSBR-UHFFFAOYSA-N_223376",
        "concept_name": "Imidazo[4_5-b]pyridine",
        "concept_occurrences": "96",
        "concept_name_original": "\"Imidazo[4,5-b]pyridine\"",
        "concept_code": "Concept_248"
    },
    {
        "concept_molecule_example": "224865",
        "svg_path": "25\\7\\1\\0\\svg\\FOQJHZPURACERJ-UHFFFAOYSA-N_224865",
        "concept_name": "Boronic ester",
        "concept_occurrences": "1780",
        "concept_name_original": "Boronic ester",
        "concept_code": "Concept_63"
    },
    {
        "concept_molecule_example": "226137",
        "svg_path": "16\\8\\2\\10\\svg\\WCZVZNOTHYJIEI-UHFFFAOYSA-N_226137",
        "concept_name": "Cinnoline",
        "concept_occurrences": "22",
        "concept_name_original": "Cinnoline",
        "concept_code": "Concept_199"
    },
    {
        "concept_molecule_example": "227698",
        "svg_path": "17\\9\\2\\10\\svg\\SMWDFEZZVXVKRB-UHFFFAOYSA-N_227698",
        "concept_name": "Quinoline",
        "concept_occurrences": "3288",
        "concept_name_original": "Quinoline",
        "concept_code": "Concept_319"
    },
    {
        "concept_molecule_example": "227722",
        "svg_path": "17\\9\\2\\10\\svg\\AWJUIBRHMBBTKR-UHFFFAOYSA-N_227722",
        "concept_name": "Isoquinoline",
        "concept_occurrences": "597",
        "concept_name_original": "Isoquinoline",
        "concept_code": "Concept_254"
    },
    {
        "concept_molecule_example": "227758",
        "svg_path": "20\\5\\0\\0\\svg\\HDPNBNXLBDFELL-UHFFFAOYSA-N_227758",
        "concept_name": "Orthoester",
        "concept_occurrences": "18",
        "concept_name_original": "Orthoester",
        "concept_code": "Concept_19"
    },
    {
        "concept_molecule_example": "228069",
        "svg_path": "18\\6\\2\\0\\svg\\GYLMCBOAXJVARF-UHFFFAOYSA-N_228069",
        "concept_name": "Bicyclo[2.2.1]heptanes",
        "concept_occurrences": "266",
        "concept_name_original": "Bicyclo[2.2.1]heptanes",
        "concept_code": "Concept_365"
    },
    {
        "concept_molecule_example": "230646",
        "svg_path": "16\\7\\2\\5\\svg\\QGWOZLJTLRKDOF-UHFFFAOYSA-N_230646",
        "concept_name": "Pyrrolo[1_2-b]pyridazine",
        "concept_occurrences": "5",
        "concept_name_original": "\"Pyrrolo[1,2-b]pyridazine\"",
        "concept_code": "Concept_314"
    },
    {
        "concept_molecule_example": "233923",
        "svg_path": "7\\2\\1\\5\\svg\\FKASFBLJDCHBNZ-UHFFFAOYSA-N_233923",
        "concept_name": "1_3_4-Oxadiazole",
        "concept_occurrences": "448",
        "concept_name_original": "\"1,3,4-Oxadiazole\"",
        "concept_code": "Concept_132"
    },
    {
        "concept_molecule_example": "234994",
        "svg_path": "9\\3\\1\\6\\svg\\JIHQDMXYYFUGFV-UHFFFAOYSA-N_234994",
        "concept_name": "1_3_5-Triazine",
        "concept_occurrences": "284",
        "concept_name_original": "\"1,3,5-Triazine\"",
        "concept_code": "Concept_133"
    },
    {
        "concept_molecule_example": "235007",
        "svg_path": "15\\7\\2\\9\\svg\\HYZJCKYKOHLVJF-UHFFFAOYSA-N_235007",
        "concept_name": "Benzimidazole",
        "concept_occurrences": "1322",
        "concept_name_original": "Benzimidazole",
        "concept_code": "Concept_183"
    },
    {
        "concept_molecule_example": "238174",
        "svg_path": "12\\6\\1\\6\\svg\\GBDZXPJXOMHESU-UHFFFAOYSA-N_238174",
        "concept_name": "1_2_3_4-Tetrachlorobenzenes",
        "concept_occurrences": "8",
        "concept_name_original": "\"1,2,3,4-Tetrachlorobenzenes\"",
        "concept_code": "Concept_103"
    },
    {
        "concept_molecule_example": "238174",
        "svg_path": "12\\6\\1\\6\\svg\\GBDZXPJXOMHESU-UHFFFAOYSA-N_238174",
        "concept_name": "Tetrachlorobenzenes",
        "concept_occurrences": "18",
        "concept_name_original": "Tetrachlorobenzenes",
        "concept_code": "Concept_322"
    },
    {
        "concept_molecule_example": "239024",
        "svg_path": "12\\6\\1\\6\\svg\\QZYNWJQFTJXIRN-UHFFFAOYSA-N_239024",
        "concept_name": "1_2_3_5-Tetrachlorobenzenes",
        "concept_occurrences": "2",
        "concept_name_original": "\"1,2,3,5-Tetrachlorobenzenes\"",
        "concept_code": "Concept_107"
    },
    {
        "concept_molecule_example": "239785",
        "svg_path": "14\\2\\0\\0\\svg\\KKUKTXOBAWVSHC-UHFFFAOYSA-N_239785",
        "concept_name": "Phosphodiester",
        "concept_occurrences": "29",
        "concept_name_original": "Phosphodiester",
        "concept_code": "Concept_61"
    },
    {
        "concept_molecule_example": "240012",
        "svg_path": "12\\6\\1\\6\\svg\\JHBKHLUZVFWLAG-UHFFFAOYSA-N_240012",
        "concept_name": "1_2_4_5-Tetrachlorobenzenes",
        "concept_occurrences": "8",
        "concept_name_original": "\"1,2,4,5-Tetrachlorobenzenes\"",
        "concept_code": "Concept_118"
    },
    {
        "concept_molecule_example": "240500",
        "svg_path": "11\\2\\1\\0\\svg\\DZKOKXZNCDGVRY-UHFFFAOYSA-N_240500",
        "concept_name": "Disulfide",
        "concept_occurrences": "114",
        "concept_name_original": "Disulfide",
        "concept_code": "Concept_47"
    },
    {
        "concept_molecule_example": "240561",
        "svg_path": "18\\10\\2\\10\\svg\\UFWIBTONFRDIAS-UHFFFAOYSA-N_240561",
        "concept_name": "Naphthalene",
        "concept_occurrences": "1602",
        "concept_name_original": "Naphthalene",
        "concept_code": "Concept_374"
    },
    {
        "concept_molecule_example": "241021",
        "svg_path": "12\\6\\1\\6\\svg\\ZPQOPVIELGIULI-UHFFFAOYSA-N_241021",
        "concept_name": "1_3-Dichlorobenzenes",
        "concept_occurrences": "1955",
        "concept_name_original": "\"1,3-Dichlorobenzenes\"",
        "concept_code": "Concept_139"
    },
    {
        "concept_molecule_example": "241235",
        "svg_path": "12\\6\\1\\6\\svg\\RELMFMZEBKVZJC-UHFFFAOYSA-N_241235",
        "concept_name": "1_2_3-Trichlorobenzenes",
        "concept_occurrences": "29",
        "concept_name_original": "\"1,2,3-Trichlorobenzenes\"",
        "concept_code": "Concept_114"
    },
    {
        "concept_molecule_example": "241850",
        "svg_path": "20\\6\\0\\0\\svg\\RPVBCVQKDPOMII-FNORWQNLSA-N_241850",
        "concept_name": "Secondary aldimine",
        "concept_occurrences": "124",
        "concept_name_original": "Secondary aldimine",
        "concept_code": "Concept_30"
    },
    {
        "concept_molecule_example": "242571",
        "svg_path": "15\\7\\1\\6\\svg\\MFHPYLFZSCSNST-UHFFFAOYSA-N_242571",
        "concept_name": "Monosubstituted (ortho) trichloromethylbenzenes",
        "concept_occurrences": "2",
        "concept_name_original": "Monosubstituted (ortho) trichloromethylbenzenes",
        "concept_code": "Concept_274"
    },
    {
        "concept_molecule_example": "242934",
        "svg_path": "16\\4\\1\\0\\svg\\ZSEGSUBKDDEALH-UHFFFAOYSA-N_242934",
        "concept_name": "Thioester",
        "concept_occurrences": "144",
        "concept_name_original": "Thioester",
        "concept_code": "Concept_57"
    },
    {
        "concept_molecule_example": "243023",
        "svg_path": "15\\7\\1\\6\\svg\\VCJZTATVUDMNLU-UHFFFAOYSA-N_243023",
        "concept_name": "Dibromomethyl/dibromomethoxy benzenes",
        "concept_occurrences": "10",
        "concept_name_original": "Dibromomethyl/dibromomethoxy benzenes",
        "concept_code": "Concept_205"
    },
    {
        "concept_molecule_example": "300975",
        "svg_path": "14\\6\\2\\9\\svg\\AQWOIRBQLOOZGX-UHFFFAOYSA-N_300975",
        "concept_name": "[1_2_3]Triazolo[1_5-a]pyridine",
        "concept_occurrences": "3",
        "concept_name_original": "\"[1,2,3]Triazolo[1,5-a]pyridine\"",
        "concept_code": "Concept_98"
    },
    {
        "concept_molecule_example": "307008",
        "svg_path": "9\\3\\0\\0\\svg\\JKANAVGODYYCQF-UHFFFAOYSA-N_307008",
        "concept_name": "Terminal alkyne (amine)",
        "concept_occurrences": "137",
        "concept_name_original": "Terminal alkyne (amine)",
        "concept_code": "Concept_72"
    },
    {
        "concept_molecule_example": "308069",
        "svg_path": "14\\4\\1\\5\\svg\\GNYHZQWYCIQTEJ-UHFFFAOYSA-N_308069",
        "concept_name": "1_2_3-Oxadiazole",
        "concept_occurrences": "3",
        "concept_name_original": "\"1,2,3-Oxadiazole\"",
        "concept_code": "Concept_110"
    },
    {
        "concept_molecule_example": "308209",
        "svg_path": "21\\10\\2\\9\\svg\\YUQPUNMMDJWKAK-UHFFFAOYSA-N_308209",
        "concept_name": "Indolizine",
        "concept_occurrences": "28",
        "concept_name_original": "Indolizine",
        "concept_code": "Concept_253"
    },
    {
        "concept_molecule_example": "312171",
        "svg_path": "10\\4\\0\\0\\svg\\KKAHGSQLSTUDAV-UHFFFAOYSA-N_312171",
        "concept_name": "Terminal alkyne (carboxylic acid)",
        "concept_occurrences": "66",
        "concept_name_original": "Terminal alkyne (carboxylic acid)",
        "concept_code": "Concept_70"
    },
    {
        "concept_molecule_example": "313366",
        "svg_path": "14\\4\\1\\0\\svg\\PYTANBUURZFYHD-QWWZWVQMSA-N_313366",
        "concept_name": "Chiral amines",
        "concept_occurrences": "7880",
        "concept_name_original": "Chiral amines",
        "concept_code": "Concept_191"
    },
    {
        "concept_molecule_example": "317270",
        "svg_path": "10\\2\\1\\5\\svg\\XZGLNCKSNVGDNX-UHFFFAOYSA-N_317270",
        "concept_name": "Tetrazole",
        "concept_occurrences": "462",
        "concept_name_original": "Tetrazole",
        "concept_code": "Concept_327"
    },
    {
        "concept_molecule_example": "\"051163\"",
        "svg_path": "8\\2\\1\\5\\svg\\QWENRTYMTSOGBR-UHFFFAOYSA-N_051163",
        "concept_name": "1_2_3-Triazole",
        "concept_occurrences": "355",
        "concept_name_original": "\"1,2,3-Triazole\"",
        "concept_code": "Concept_112"
    },
    {
        "concept_molecule_example": "318750",
        "svg_path": "20\\9\\2\\6\\svg\\VZWXIQHBIQLMPN-UHFFFAOYSA-N_318750",
        "concept_name": "Chromane",
        "concept_occurrences": "335",
        "concept_name_original": "Chromane",
        "concept_code": "Concept_372"
    },
    {
        "concept_molecule_example": "321438",
        "svg_path": "14\\6\\2\\9\\svg\\AYSYSOQSKKDJJY-UHFFFAOYSA-N_321438",
        "concept_name": "[1_2_4]Triazolo[4_3-a]pyridine",
        "concept_occurrences": "149",
        "concept_name_original": "\"[1,2,4]Triazolo[4,3-a]pyridine\"",
        "concept_code": "Concept_100"
    },
    {
        "concept_molecule_example": "329306",
        "svg_path": "18\\8\\1\\6\\svg\\SKWWCKGHUDPXOZ-UHFFFAOYSA-N_329306",
        "concept_name": "Monosubstituted (meta) trichloromethylbenzenes",
        "concept_occurrences": "1",
        "concept_name_original": "Monosubstituted (meta) trichloromethylbenzenes",
        "concept_code": "Concept_266"
    },
    {
        "concept_molecule_example": "330894",
        "svg_path": "20\\6\\3\\0\\svg\\OMFJJGDERYLMRX-UHFFFAOYSA-N_330894",
        "concept_name": "Bicyclo[3.1.1]heptanes",
        "concept_occurrences": "60",
        "concept_name_original": "Bicyclo[3.1.1]heptanes",
        "concept_code": "Concept_362"
    },
    {
        "concept_molecule_example": "333746",
        "svg_path": "16\\7\\2\\6\\svg\\LVWFGUZOHQWWFU-UHFFFAOYSA-N_333746",
        "concept_name": "Pyrido[3_4-d]pyridazine",
        "concept_occurrences": "4",
        "concept_name_original": "\"Pyrido[3,4-d]pyridazine\"",
        "concept_code": "Concept_308"
    },
    {
        "concept_molecule_example": "358241",
        "svg_path": "9\\2\\0\\0\\svg\\FZENGILVLUJGJX-NSCUHMNNSA-N_358241",
        "concept_name": "Aldoxime",
        "concept_occurrences": "144",
        "concept_name_original": "Aldoxime",
        "concept_code": "Concept_42"
    },
    {
        "concept_molecule_example": "358241",
        "svg_path": "9\\2\\0\\0\\svg\\FZENGILVLUJGJX-NSCUHMNNSA-N_358241",
        "concept_name": "Oxime",
        "concept_occurrences": "366",
        "concept_name_original": "Oxime",
        "concept_code": "Concept_44"
    },
    {
        "concept_molecule_example": "358278",
        "svg_path": "15\\7\\1\\6\\svg\\ZMZGFLUUZLELNE-UHFFFAOYSA-N_358278",
        "concept_name": "1_2_4-Triiodobenzenes",
        "concept_occurrences": "1",
        "concept_name_original": "\"1,2,4-Triiodobenzenes\"",
        "concept_code": "Concept_126"
    },
    {
        "concept_molecule_example": "358427",
        "svg_path": "11\\2\\0\\0\\svg\\HHVIBTZHLRERCL-UHFFFAOYSA-N_358427",
        "concept_name": "Sulfone",
        "concept_occurrences": "1760",
        "concept_name_original": "Sulfone",
        "concept_code": "Concept_49"
    },
    {
        "concept_molecule_example": "\"094453\"",
        "svg_path": "5\\0\\1\\0\\svg\\XOFCYBXAPXVQBR-UHFFFAOYSA-N_094453",
        "concept_name": "Peroxide",
        "concept_occurrences": "12",
        "concept_name_original": "Peroxide",
        "concept_code": "Concept_13"
    },
    {
        "concept_molecule_example": "\"094484\"",
        "svg_path": "9\\2\\0\\0\\svg\\DNJIEGIFACGWOD-UHFFFAOYSA-N_094484",
        "concept_name": "Aliphatic thiol",
        "concept_occurrences": "401",
        "concept_name_original": "Aliphatic thiol",
        "concept_code": "Concept_45"
    },
    {
        "concept_molecule_example": "\"094510\"",
        "svg_path": "10\\3\\1\\0\\svg\\KMTRUDSVKNLOMY-UHFFFAOYSA-N_094510",
        "concept_name": "Carbonate ester",
        "concept_occurrences": "106",
        "concept_name_original": "Carbonate ester",
        "concept_code": "Concept_7"
    },
    {
        "concept_molecule_example": "\"094600\"",
        "svg_path": "14\\6\\1\\6\\svg\\LQNUZADURLCDLV-UHFFFAOYSA-N_094600",
        "concept_name": "Nitrobenzene",
        "concept_occurrences": "6901",
        "concept_name_original": "Nitrobenzene",
        "concept_code": "Concept_381"
    },
    {
        "concept_molecule_example": "\"094617\"",
        "svg_path": "24\\6\\1\\0\\svg\\KEQUNHIAUQQPAC-UHFFFAOYSA-N_094617",
        "concept_name": "Hemiketal",
        "concept_occurrences": "12",
        "concept_name_original": "Hemiketal",
        "concept_code": "Concept_16"
    },
    {
        "concept_molecule_example": "\"094630\"",
        "svg_path": "17\\5\\0\\0\\svg\\RUPAXCPQAAOIPB-UHFFFAOYSA-N_094630",
        "concept_name": "BOC",
        "concept_occurrences": "11804",
        "concept_name_original": "BOC",
        "concept_code": "Concept_97"
    },
    {
        "concept_molecule_example": "\"094632\"",
        "svg_path": "18\\8\\1\\6\\svg\\YNQLUTRBYVCPMQ-UHFFFAOYSA-N_094632",
        "concept_name": "Benzyl",
        "concept_occurrences": "28502",
        "concept_name_original": "Benzyl",
        "concept_code": "Concept_95"
    },
    {
        "concept_molecule_example": "\"094668\"",
        "svg_path": "14\\3\\0\\0\\svg\\HQMLIDZJXVVKCW-REOHCLBHSA-N_094668",
        "concept_name": "Chiral amides",
        "concept_occurrences": "3788",
        "concept_name_original": "Chiral amides",
        "concept_code": "Concept_190"
    },
    {
        "concept_molecule_example": "\"094994\"",
        "svg_path": "12\\6\\1\\6\\svg\\OCJBOOLMMGQPQU-UHFFFAOYSA-N_094994",
        "concept_name": "1_4-Dichlorobenzenes",
        "concept_occurrences": "467",
        "concept_name_original": "\"1,4-Dichlorobenzenes\"",
        "concept_code": "Concept_145"
    },
    {
        "concept_molecule_example": "\"094994\"",
        "svg_path": "12\\6\\1\\6\\svg\\OCJBOOLMMGQPQU-UHFFFAOYSA-N_094994",
        "concept_name": "Dichlorobenzenes",
        "concept_occurrences": "3638",
        "concept_name_original": "Dichlorobenzenes",
        "concept_code": "Concept_206"
    },
    {
        "concept_molecule_example": "366482",
        "svg_path": "19\\7\\2\\11\\svg\\DVKATNGXDAQGKF-UHFFFAOYSA-N_366482",
        "concept_name": "1_2_3_4-Oxatriazole",
        "concept_occurrences": "1",
        "concept_name_original": "\"1,2,3,4-Oxatriazole\"",
        "concept_code": "Concept_101"
    },
    {
        "concept_molecule_example": "375568",
        "svg_path": "11\\2\\0\\0\\svg\\RQIFXTOWUNAUJC-UHFFFAOYSA-N_375568",
        "concept_name": "Sulfinic acid",
        "concept_occurrences": "7",
        "concept_name_original": "Sulfinic acid",
        "concept_code": "Concept_50"
    },
    {
        "concept_molecule_example": "383221",
        "svg_path": "14\\4\\3\\0\\svg\\UOLVJRQJDZMNDG-UHFFFAOYSA-N_383221",
        "concept_name": "Bicyclo[2.1.1]hexanes",
        "concept_occurrences": "18",
        "concept_name_original": "Bicyclo[2.1.1]hexanes",
        "concept_code": "Concept_367"
    },
    {
        "concept_molecule_example": "386319",
        "svg_path": "12\\1\\0\\0\\svg\\MGRVRXRGTBOSHW-UHFFFAOYSA-N_386319",
        "concept_name": "Phosphonic acid",
        "concept_occurrences": "53",
        "concept_name_original": "Phosphonic acid",
        "concept_code": "Concept_59"
    },
    {
        "concept_molecule_example": "392532",
        "svg_path": "9\\2\\1\\5\\svg\\XWESVPXIDGLPNX-UHFFFAOYSA-N_392532",
        "concept_name": "1_2_4-Oxadiazole",
        "concept_occurrences": "1041",
        "concept_name_original": "\"1,2,4-Oxadiazole\"",
        "concept_code": "Concept_121"
    },
    {
        "concept_molecule_example": "403682",
        "svg_path": "13\\4\\1\\0\\svg\\RBACIKXCRWGCBB-SCSAIBSYSA-N_403682",
        "concept_name": "Ethyl",
        "concept_occurrences": "2300",
        "concept_name_original": "Ethyl",
        "concept_code": "Concept_91"
    },
    {
        "concept_molecule_example": "\"095387\"",
        "svg_path": "4\\0\\0\\0\\svg\\KUNSUQLRTQLHQQ-UHFFFAOYSA-N_095387",
        "concept_name": "Stannane",
        "concept_occurrences": "82",
        "concept_name_original": "Stannane",
        "concept_code": "Concept_84"
    },
    {
        "concept_molecule_example": "\"097798\"",
        "svg_path": "13\\4\\0\\0\\svg\\MLFOVJOEMUMSNN-GSVOUGTGSA-N_097798",
        "concept_name": "Chiral isocyanates",
        "concept_occurrences": "9",
        "concept_name_original": "Chiral isocyanates",
        "concept_code": "Concept_198"
    },
    {
        "concept_molecule_example": "433365",
        "svg_path": "9\\3\\0\\0\\svg\\BPCWCZCOOFUXGQ-UHFFFAOYSA-N_433365",
        "concept_name": "Isocyanide",
        "concept_occurrences": "318",
        "concept_name_original": "Isocyanide",
        "concept_code": "Concept_75"
    },
    {
        "concept_molecule_example": "433365",
        "svg_path": "9\\3\\0\\0\\svg\\BPCWCZCOOFUXGQ-UHFFFAOYSA-N_433365",
        "concept_name": "Isonitrile",
        "concept_occurrences": "318",
        "concept_name_original": "Isonitrile",
        "concept_code": "Concept_38"
    },
    {
        "concept_molecule_example": "440455",
        "svg_path": "14\\6\\2\\9\\svg\\PQWQQQGKMHENOC-UHFFFAOYSA-N_440455",
        "concept_name": "Imidazo[1_2-c]pyrimidine",
        "concept_occurrences": "16",
        "concept_name_original": "\"Imidazo[1,2-c]pyrimidine\"",
        "concept_code": "Concept_245"
    },
    {
        "concept_molecule_example": "447008",
        "svg_path": "14\\4\\1\\0\\svg\\JNODDICFTDYODH-UHFFFAOYSA-N_447008",
        "concept_name": "Hemiacetal",
        "concept_occurrences": "68",
        "concept_name_original": "Hemiacetal",
        "concept_code": "Concept_15"
    },
    {
        "concept_molecule_example": "467146",
        "svg_path": "12\\6\\1\\6\\svg\\QCKHVNQHBOGZER-UHFFFAOYSA-N_467146",
        "concept_name": "1_2_4_5-Tetrabromobenzenes",
        "concept_occurrences": "3",
        "concept_name_original": "\"1,2,4,5-Tetrabromobenzenes\"",
        "concept_code": "Concept_117"
    },
    {
        "concept_molecule_example": "467146",
        "svg_path": "12\\6\\1\\6\\svg\\QCKHVNQHBOGZER-UHFFFAOYSA-N_467146",
        "concept_name": "Tetrabromobenzenes",
        "concept_occurrences": "4",
        "concept_name_original": "Tetrabromobenzenes",
        "concept_code": "Concept_321"
    },
    {
        "concept_molecule_example": "467438",
        "svg_path": "19\\7\\1\\0\\svg\\FPDGGJZURPQAMW-UHFFFAOYSA-N_467438",
        "concept_name": "Cyclobutene",
        "concept_occurrences": "4",
        "concept_name_original": "Cyclobutene",
        "concept_code": "Concept_201"
    },
    {
        "concept_molecule_example": "470201",
        "svg_path": "13\\3\\0\\0\\svg\\YYWZDZMCXPSGDF-NSCUHMNNSA-N_470201",
        "concept_name": "Nitroso",
        "concept_occurrences": "25",
        "concept_name_original": "Nitroso",
        "concept_code": "Concept_41"
    },
    {
        "concept_molecule_example": "470912",
        "svg_path": "15\\7\\1\\6\\svg\\CAHQGWAXKLQREW-UHFFFAOYSA-N_470912",
        "concept_name": "Dichloromethyl/dichloromethoxy benzenes",
        "concept_occurrences": "13",
        "concept_name_original": "Dichloromethyl/dichloromethoxy benzenes",
        "concept_code": "Concept_207"
    },
    {
        "concept_molecule_example": "474708",
        "svg_path": "14\\7\\1\\6\\svg\\CWHFDTWZHFRTAB-UHFFFAOYSA-N_474708",
        "concept_name": "Cyanate",
        "concept_occurrences": "7",
        "concept_name_original": "Cyanate",
        "concept_code": "Concept_34"
    },
    {
        "concept_molecule_example": "479008",
        "svg_path": "13\\3\\1\\0\\svg\\KMFMGDTYKKZYSE-UHFFFAOYSA-N_479008",
        "concept_name": "Pyrazolidine",
        "concept_occurrences": "154",
        "concept_name_original": "Pyrazolidine",
        "concept_code": "Concept_303"
    },
    {
        "concept_molecule_example": "480053",
        "svg_path": "9\\3\\0\\0\\svg\\IPMQOHUWDPGFKO-UHFFFAOYSA-N_480053",
        "concept_name": "Primary ketimine",
        "concept_occurrences": "10",
        "concept_name_original": "Primary ketimine",
        "concept_code": "Concept_27"
    },
    {
        "concept_molecule_example": "480315",
        "svg_path": "24\\10\\1\\0\\svg\\ZTAHANLIYMMGHK-VTBWALSUSA-N_480315",
        "concept_name": "4-Membered heteroaromatic",
        "concept_occurrences": "1",
        "concept_name_original": "4-Membered heteroaromatic",
        "concept_code": "Concept_164"
    },
    {
        "concept_molecule_example": "493278",
        "svg_path": "32\\8\\0\\0\\svg\\KXUMNSXPAYCKPR-UHFFFAOYSA-N_493278",
        "concept_name": "Phosphoramidite",
        "concept_occurrences": "4",
        "concept_name_original": "Phosphoramidite",
        "concept_code": "Concept_380"
    },
    {
        "concept_molecule_example": "506660",
        "svg_path": "26\\11\\2\\11\\svg\\QDZRKMVGVOWFHT-UHFFFAOYSA-N_506660",
        "concept_name": "7-Membered heteroaromatic",
        "concept_occurrences": "3",
        "concept_name_original": "7-Membered heteroaromatic",
        "concept_code": "Concept_176"
    },
    {
        "concept_molecule_example": "D-070",
        "svg_path": "2\\0\\0\\0\\svg\\VEXZGXHMUGYJMC-DYCDLGHISA-N_D-070",
        "concept_name": "Chloro",
        "concept_occurrences": "33894",
        "concept_name_original": "Chloro",
        "concept_code": "Concept_77"
    },
    {
        "concept_molecule_example": "510962",
        "svg_path": "17\\4\\0\\0\\svg\\QQHZPQUHCAKSOL-UHFFFAOYSA-N_510962",
        "concept_name": "Nitrate",
        "concept_occurrences": "12",
        "concept_name_original": "Nitrate",
        "concept_code": "Concept_36"
    },
    {
        "concept_molecule_example": "\"098761\"",
        "svg_path": "11\\3\\0\\0\\svg\\KXKVLQRXCPHEJC-UHFFFAOYSA-N_098761",
        "concept_name": "Ester",
        "concept_occurrences": "21077",
        "concept_name_original": "Ester",
        "concept_code": "Concept_10"
    },
    {
        "concept_molecule_example": "\"099635\"",
        "svg_path": "20\\6\\1\\0\\svg\\ZSIQJIWKELUFRJ-UHFFFAOYSA-N_099635",
        "concept_name": "Azepane",
        "concept_occurrences": "467",
        "concept_name_original": "Azepane",
        "concept_code": "Concept_181"
    },
    {
        "concept_molecule_example": "512913",
        "svg_path": "6\\1\\0\\0\\svg\\OKKJLVBELUTLKV-OUBTZVSYSA-N_512913",
        "concept_name": "Aliphatic alcohol",
        "concept_occurrences": "14695",
        "concept_name_original": "Aliphatic alcohol",
        "concept_code": "Concept_3"
    },
    {
        "concept_molecule_example": "512966",
        "svg_path": "11\\3\\1\\0\\svg\\MCGBIXXDQFWVDW-UHFFFAOYSA-N_512966",
        "concept_name": "2-Pyrazoline",
        "concept_occurrences": "275",
        "concept_name_original": "2-Pyrazoline",
        "concept_code": "Concept_158"
    },
    {
        "concept_molecule_example": "516271",
        "svg_path": "20\\8\\6\\0\\svg\\YEJJHZWJVQXKIU-UHFFFAOYSA-N_516271",
        "concept_name": "Cubanes",
        "concept_occurrences": "17",
        "concept_name_original": "Cubanes",
        "concept_code": "Concept_368"
    },
    {
        "concept_molecule_example": "\"099918\"",
        "svg_path": "16\\4\\0\\0\\svg\\DAZXVJBJRMWXJP-UHFFFAOYSA-N_099918",
        "concept_name": "Tertiary amine",
        "concept_occurrences": "18170",
        "concept_name_original": "Tertiary amine",
        "concept_code": "Concept_25"
    },
    {
        "concept_molecule_example": "245966",
        "svg_path": "12\\1\\0\\0\\svg\\AQLJVWUFPCUVLO-UHFFFAOYSA-N_245966",
        "concept_name": "Hydroperoxide",
        "concept_occurrences": "3",
        "concept_name_original": "Hydroperoxide",
        "concept_code": "Concept_12"
    },
    {
        "concept_molecule_example": "520427",
        "svg_path": "14\\5\\2\\0\\svg\\APUDJEBZDAGSQY-UHFFFAOYSA-N_520427",
        "concept_name": "Spirocycles",
        "concept_occurrences": "2304",
        "concept_name_original": "Spirocycles",
        "concept_code": "Concept_371"
    },
    {
        "concept_molecule_example": "533454",
        "svg_path": "13\\5\\3\\0\\svg\\XFXJTOJDGTYWDX-UHFFFAOYSA-N_533454",
        "concept_name": "Bicyclo[1.1.1]pentanes",
        "concept_occurrences": "67",
        "concept_name_original": "Bicyclo[1.1.1]pentanes",
        "concept_code": "Concept_361"
    },
    {
        "concept_molecule_example": "535730",
        "svg_path": "14\\4\\1\\0\\svg\\FGRJGEWVJCCOJJ-UHFFFAOYSA-N_535730",
        "concept_name": "Aziridine",
        "concept_occurrences": "30",
        "concept_name_original": "Aziridine",
        "concept_code": "Concept_182"
    },
    {
        "concept_molecule_example": "538608",
        "svg_path": "17\\4\\0\\0\\svg\\FERWBXLFSBWTDE-QWWZWVQMSA-N_538608",
        "concept_name": "Chiral amino alcohols (both chiral)",
        "concept_occurrences": "400",
        "concept_name_original": "Chiral amino alcohols (both chiral)",
        "concept_code": "Concept_193"
    },
    {
        "concept_molecule_example": "542368",
        "svg_path": "5\\1\\0\\0\\svg\\TUDWMIUPYRKEFN-UHFFFAOYSA-N_542368",
        "concept_name": "Bromo",
        "concept_occurrences": "21009",
        "concept_name_original": "Bromo",
        "concept_code": "Concept_78"
    },
    {
        "concept_molecule_example": "\"004356\"",
        "svg_path": "5\\1\\0\\0\\svg\\NBVXSUQYWXRMNV-UHFFFAOYSA-N_004356",
        "concept_name": "Fluoro",
        "concept_occurrences": "35095",
        "concept_name_original": "Fluoro",
        "concept_code": "Concept_76"
    },
    {
        "concept_molecule_example": "\"004356\"",
        "svg_path": "5\\1\\0\\0\\svg\\NBVXSUQYWXRMNV-UHFFFAOYSA-N_004356",
        "concept_name": "Halo",
        "concept_occurrences": "78900",
        "concept_name_original": "Halo",
        "concept_code": "Concept_80"
    },
    {
        "concept_molecule_example": "515747",
        "svg_path": "15\\6\\1\\0\\svg\\HGXQSSLRNWUAHU-UHFFFAOYSA-N_515747",
        "concept_name": "Thione",
        "concept_occurrences": "4",
        "concept_name_original": "Thione",
        "concept_code": "Concept_55"
    },
].map(row => ({
    name: row.concept_name,
    code: row.concept_code,
    count: parseInt(row.concept_occurrences),
    svg: row.svg_path,
    example: row.concept_molecule_example,
}));
unsortedConceptBlocks.sort((a, b) => b.count - a.count);


export const conceptBlocks = unsortedConceptBlocks;


export const filtersData: {
    concepts: [string, number][],
    ranges: { [key: string]: [number, number] }
} = {
    "concepts": [
        [
            "SG255",
            531442
        ],
        [
            "SG256",
            472085
        ],
        [
            "FG022",
            339237
        ],
        [
            "FG003",
            276914
        ],
        [
            "FG083",
            247538
        ],
        [
            "FG023",
            233175
        ],
        [
            "FG028",
            216941
        ],
        [
            "SG054",
            187921
        ],
        [
            "SG057",
            185211
        ],
        [
            "SG053",
            144471
        ],
        [
            "FG016",
            137719
        ],
        [
            "SG056",
            118316
        ],
        [
            "FG092",
            114593
        ],
        [
            "FG079",
            112725
        ],
        [
            "FG080",
            101831
        ],
        [
            "FG025",
            100951
        ],
        [
            "FG010",
            79919
        ],
        [
            "FG026",
            78954
        ],
        [
            "FG081",
            71106
        ],
        [
            "FG011",
            64933
        ],
        [
            "FG027",
            63868
        ],
        [
            "FG024",
            63053
        ],
        [
            "FG012",
            57036
        ],
        [
            "FG047",
            52858
        ],
        [
            "FG005",
            52632
        ],
        [
            "FG004",
            50978
        ],
        [
            "SG181",
            48471
        ],
        [
            "FG108",
            48166
        ],
        [
            "FG089",
            47239
        ],
        [
            "FG093",
            44515
        ],
        [
            "FG134",
            42701
        ],
        [
            "SG135",
            41172
        ],
        [
            "FG086",
            38121
        ],
        [
            "SG134",
            37304
        ],
        [
            "FG101",
            36124
        ],
        [
            "SG265",
            35310
        ],
        [
            "SG175",
            29610
        ],
        [
            "SG133",
            29607
        ],
        [
            "FG100",
            28180
        ],
        [
            "SG178",
            26346
        ],
        [
            "007",
            26253
        ],
        [
            "FG039",
            26013
        ],
        [
            "FG042",
            24563
        ],
        [
            "FG095",
            24264
        ],
        [
            "FG069",
            23938
        ],
        [
            "SG075",
            21480
        ],
        [
            "FG096",
            21335
        ],
        [
            "SG186",
            19441
        ],
        [
            "FG006",
            18934
        ],
        [
            "FG104",
            18841
        ],
        [
            "FG174",
            18755
        ],
        [
            "FG129",
            18725
        ],
        [
            "FG109",
            18636
        ],
        [
            "SG248",
            18359
        ],
        [
            "SG252",
            16023
        ],
        [
            "SG341",
            15389
        ],
        [
            "SG184",
            14609
        ],
        [
            "SG253",
            14316
        ],
        [
            "FG090",
            13595
        ],
        [
            "FG077",
            13216
        ],
        [
            "SG203",
            12736
        ],
        [
            "SG174",
            12705
        ],
        [
            "SG274",
            12604
        ],
        [
            "FG082",
            12390
        ],
        [
            "CG022",
            11939
        ],
        [
            "SG481",
            10967
        ],
        [
            "FG268",
            10827
        ],
        [
            "SG092",
            10780
        ],
        [
            "SG250",
            10761
        ],
        [
            "SG090",
            9466
        ],
        [
            "SG190",
            9461
        ],
        [
            "SG199",
            9163
        ],
        [
            "SG080",
            9009
        ],
        [
            "FG066",
            8856
        ],
        [
            "SG503",
            8542
        ],
        [
            "FG084",
            8509
        ],
        [
            "FG098",
            8453
        ],
        [
            "SG242",
            8305
        ],
        [
            "SG128",
            8273
        ],
        [
            "SG267",
            8251
        ],
        [
            "FG094",
            8024
        ],
        [
            "SG073",
            7888
        ],
        [
            "SG294",
            7783
        ],
        [
            "SG154",
            7759
        ],
        [
            "SG074",
            7625
        ],
        [
            "FG013",
            7469
        ],
        [
            "FG001",
            7469
        ],
        [
            "FG065",
            7454
        ],
        [
            "FG070",
            7414
        ],
        [
            "FG071",
            7414
        ],
        [
            "SG155",
            7230
        ],
        [
            "CG016",
            7207
        ],
        [
            "SG315",
            6995
        ],
        [
            "SG314",
            6948
        ],
        [
            "SG161",
            6948
        ],
        [
            "SG120",
            6903
        ],
        [
            "SG051",
            6855
        ],
        [
            "SG125",
            6636
        ],
        [
            "SG106",
            6593
        ],
        [
            "SG466",
            6475
        ],
        [
            "SG322",
            6424
        ],
        [
            "FG127",
            6319
        ],
        [
            "SG245",
            6223
        ],
        [
            "SG249",
            6132
        ],
        [
            "FG050",
            6020
        ],
        [
            "SG061",
            6019
        ],
        [
            "SG036",
            5731
        ],
        [
            "FG261",
            5584
        ],
        [
            "SG257",
            5563
        ],
        [
            "SG153",
            5419
        ],
        [
            "SG105",
            5343
        ],
        [
            "SG136",
            5296
        ],
        [
            "SG035",
            5234
        ],
        [
            "FG123",
            5041
        ],
        [
            "SG476",
            5026
        ],
        [
            "FG112",
            5021
        ],
        [
            "SG076",
            4932
        ],
        [
            "SG195",
            4868
        ],
        [
            "SG081",
            4814
        ],
        [
            "SG114",
            4785
        ],
        [
            "SG084",
            4768
        ],
        [
            "006",
            4763
        ],
        [
            "SG102",
            4744
        ],
        [
            "SG112",
            4728
        ],
        [
            "SG389",
            4687
        ],
        [
            "CG037",
            4660
        ],
        [
            "CG038",
            4630
        ],
        [
            "SG067",
            4550
        ],
        [
            "SG127",
            4465
        ],
        [
            "FG122",
            4461
        ],
        [
            "SG295",
            4431
        ],
        [
            "FG019",
            4385
        ],
        [
            "SG477",
            4323
        ],
        [
            "SG113",
            4318
        ],
        [
            "CG032",
            4317
        ],
        [
            "FG053",
            4258
        ],
        [
            "Conecpt_FG268",
            4258
        ],
        [
            "SG101",
            4170
        ],
        [
            "SG185",
            4116
        ],
        [
            "FG118",
            4111
        ],
        [
            "SG064",
            4102
        ],
        [
            "SG478",
            4098
        ],
        [
            "SG132",
            4000
        ],
        [
            "SG263",
            3775
        ],
        [
            "SG079",
            3768
        ],
        [
            "SG196",
            3620
        ],
        [
            "SG146",
            3552
        ],
        [
            "SG138",
            3533
        ],
        [
            "FG007",
            3528
        ],
        [
            "FG126",
            3509
        ],
        [
            "SG026",
            3506
        ],
        [
            "FG165",
            3471
        ],
        [
            "SG147",
            3411
        ],
        [
            "FG105",
            3400
        ],
        [
            "FG033",
            3390
        ],
        [
            "SG139",
            3249
        ],
        [
            "SG321",
            3189
        ],
        [
            "SG177",
            3131
        ],
        [
            "SG363",
            3088
        ],
        [
            "SG189",
            3080
        ],
        [
            "SG137",
            3057
        ],
        [
            "SG025",
            3035
        ],
        [
            "SG211",
            2949
        ],
        [
            "SG019",
            2891
        ],
        [
            "SG078",
            2888
        ],
        [
            "CG009",
            2863
        ],
        [
            "SG069",
            2748
        ],
        [
            "FG244",
            2740
        ],
        [
            "SG264",
            2701
        ],
        [
            "CG031",
            2665
        ],
        [
            "SG104",
            2638
        ],
        [
            "SG180",
            2616
        ],
        [
            "SG100",
            2599
        ],
        [
            "CG023",
            2560
        ],
        [
            "SG268",
            2559
        ],
        [
            "FG099",
            2556
        ],
        [
            "FG139",
            2521
        ],
        [
            "SG356",
            2503
        ],
        [
            "SG098",
            2500
        ],
        [
            "SG370",
            2422
        ],
        [
            "CG017",
            2341
        ],
        [
            "SG374",
            2339
        ],
        [
            "SG440",
            2317
        ],
        [
            "SG244",
            2284
        ],
        [
            "SG162",
            2188
        ],
        [
            "SG097",
            2155
        ],
        [
            "SG145",
            2135
        ],
        [
            "CG011",
            2109
        ],
        [
            "SG130",
            2033
        ],
        [
            "SG292",
            2024
        ],
        [
            "SG071",
            1977
        ],
        [
            "SG143",
            1959
        ],
        [
            "SG465",
            1853
        ],
        [
            "SG118",
            1849
        ],
        [
            "SG259",
            1841
        ],
        [
            "SG068",
            1835
        ],
        [
            "SG159",
            1825
        ],
        [
            "FG110",
            1816
        ],
        [
            "SG210",
            1809
        ],
        [
            "CG019",
            1792
        ],
        [
            "FG125",
            1772
        ],
        [
            "FG020",
            1763
        ],
        [
            "FG169",
            1752
        ],
        [
            "FG128",
            1750
        ],
        [
            "SG077",
            1724
        ],
        [
            "005",
            1712
        ],
        [
            "FG119",
            1656
        ],
        [
            "SG261",
            1643
        ],
        [
            "SG224",
            1636
        ],
        [
            "SG198",
            1614
        ],
        [
            "CG012",
            1610
        ],
        [
            "SG243",
            1594
        ],
        [
            "SG088",
            1584
        ],
        [
            "SG226",
            1574
        ],
        [
            "SG070",
            1568
        ],
        [
            "SG040",
            1561
        ],
        [
            "FG133",
            1553
        ],
        [
            "FG140",
            1553
        ],
        [
            "SG072",
            1539
        ],
        [
            "SG424",
            1532
        ],
        [
            "SG417",
            1532
        ],
        [
            "SG029",
            1521
        ],
        [
            "CG021",
            1486
        ],
        [
            "SG225",
            1480
        ],
        [
            "SG439",
            1477
        ],
        [
            "SG048",
            1431
        ],
        [
            "SG353",
            1424
        ],
        [
            "SG352",
            1424
        ],
        [
            "FG136",
            1405
        ],
        [
            "SG220",
            1404
        ],
        [
            "SG375",
            1393
        ],
        [
            "SG415",
            1369
        ],
        [
            "SG320",
            1283
        ],
        [
            "SG228",
            1273
        ],
        [
            "SG290",
            1256
        ],
        [
            "SG059",
            1246
        ],
        [
            "FG009",
            1242
        ],
        [
            "SG065",
            1218
        ],
        [
            "SG039",
            1214
        ],
        [
            "FG263",
            1187
        ],
        [
            "CG028",
            1185
        ],
        [
            "SG236",
            1185
        ],
        [
            "SG289",
            1179
        ],
        [
            "FG045",
            1157
        ],
        [
            "SG332",
            1148
        ],
        [
            "SG163",
            1144
        ],
        [
            "FG097",
            1126
        ],
        [
            "SG326",
            1110
        ],
        [
            "SG464",
            1098
        ],
        [
            "SG273",
            1091
        ],
        [
            "SG387",
            1089
        ],
        [
            "SG445",
            1087
        ],
        [
            "SG034",
            1085
        ],
        [
            "SG126",
            1074
        ],
        [
            "SG316",
            1069
        ],
        [
            "SG116",
            1064
        ],
        [
            "FG173",
            1021
        ],
        [
            "SG216",
            1021
        ],
        [
            "SG117",
            1020
        ],
        [
            "SG191",
            1005
        ],
        [
            "FG075",
            1001
        ],
        [
            "FG052",
            988
        ],
        [
            "SG151",
            980
        ],
        [
            "SG221",
            976
        ],
        [
            "SG240",
            964
        ],
        [
            "SG390",
            963
        ],
        [
            "SG013",
            959
        ],
        [
            "SG348",
            954
        ],
        [
            "SG096",
            951
        ],
        [
            "SG347",
            941
        ],
        [
            "SG222",
            938
        ],
        [
            "FG157",
            928
        ],
        [
            "FG111",
            919
        ],
        [
            "FG046",
            915
        ],
        [
            "SG156",
            900
        ],
        [
            "SG164",
            896
        ],
        [
            "SG431",
            894
        ],
        [
            "SG055",
            893
        ],
        [
            "CG018",
            874
        ],
        [
            "SG218",
            864
        ],
        [
            "CG006",
            862
        ],
        [
            "FG044",
            857
        ],
        [
            "SG487",
            844
        ],
        [
            "FG076",
            834
        ],
        [
            "FG056",
            822
        ],
        [
            "SG392",
            817
        ],
        [
            "SG459",
            806
        ],
        [
            "SG452",
            800
        ],
        [
            "SG475",
            798
        ],
        [
            "FG138",
            795
        ],
        [
            "SG110",
            789
        ],
        [
            "FG239",
            787
        ],
        [
            "SG463",
            783
        ],
        [
            "CG004",
            783
        ],
        [
            "SG235",
            763
        ],
        [
            "SG262",
            755
        ],
        [
            "SG514",
            744
        ],
        [
            "SG093",
            736
        ],
        [
            "FG060",
            734
        ],
        [
            "SG217",
            725
        ],
        [
            "FG266",
            713
        ],
        [
            "SG525",
            705
        ],
        [
            "FG124",
            705
        ],
        [
            "SG404",
            700
        ],
        [
            "SG453",
            692
        ],
        [
            "concept_CG036",
            689
        ],
        [
            "SG063",
            688
        ],
        [
            "FG162",
            686
        ],
        [
            "CG007",
            685
        ],
        [
            "FG209",
            679
        ],
        [
            "SG405",
            679
        ],
        [
            "SG286",
            661
        ],
        [
            "SG529",
            660
        ],
        [
            "SG234",
            654
        ],
        [
            "SG508",
            638
        ],
        [
            "SG022",
            610
        ],
        [
            "SG269",
            606
        ],
        [
            "FG240",
            597
        ],
        [
            "CG005",
            589
        ],
        [
            "SG469",
            586
        ],
        [
            "FG034",
            586
        ],
        [
            "SG317",
            575
        ],
        [
            "SG472",
            572
        ],
        [
            "SG416",
            561
        ],
        [
            "FG037",
            560
        ],
        [
            "SG109",
            551
        ],
        [
            "SG107",
            545
        ],
        [
            "SG115",
            543
        ],
        [
            "SG284",
            539
        ],
        [
            "FG211",
            534
        ],
        [
            "SG229",
            532
        ],
        [
            "FG146",
            514
        ],
        [
            "SG498",
            510
        ],
        [
            "SG232",
            510
        ],
        [
            "SG419",
            496
        ],
        [
            "FG106",
            492
        ],
        [
            "SG426",
            491
        ],
        [
            "FG241",
            489
        ],
        [
            "SG207",
            487
        ],
        [
            "SG148",
            479
        ],
        [
            "FG219",
            478
        ],
        [
            "FG177",
            474
        ],
        [
            "FG049",
            472
        ],
        [
            "SG044",
            465
        ],
        [
            "SG131",
            464
        ],
        [
            "SG230",
            464
        ],
        [
            "SG394",
            464
        ],
        [
            "SG437",
            459
        ],
        [
            "SG266",
            457
        ],
        [
            "FG196",
            457
        ],
        [
            "CG040",
            449
        ],
        [
            "SG173",
            443
        ],
        [
            "SG368",
            442
        ],
        [
            "SG169",
            435
        ],
        [
            "FG064",
            430
        ],
        [
            "SG140",
            424
        ],
        [
            "SG446",
            423
        ],
        [
            "CG002",
            422
        ],
        [
            "SG425",
            417
        ],
        [
            "FG206",
            417
        ],
        [
            "FG072",
            417
        ],
        [
            "SG340",
            413
        ],
        [
            "SG372",
            409
        ],
        [
            "SG433",
            404
        ],
        [
            "FG185",
            403
        ],
        [
            "FG260",
            403
        ],
        [
            "FG008",
            403
        ],
        [
            "FG179",
            396
        ],
        [
            "SG427",
            391
        ],
        [
            "FG054",
            385
        ],
        [
            "FG267",
            385
        ],
        [
            "SG103",
            384
        ],
        [
            "SG246",
            383
        ],
        [
            "SG365",
            379
        ],
        [
            "FG091",
            373
        ],
        [
            "SG384",
            373
        ],
        [
            "FG205",
            363
        ],
        [
            "CG034",
            362
        ],
        [
            "CG015",
            360
        ],
        [
            "SG393",
            359
        ],
        [
            "FG087",
            357
        ],
        [
            "SG227",
            354
        ],
        [
            "SG212",
            353
        ],
        [
            "FG115",
            348
        ],
        [
            "CG025",
            343
        ],
        [
            "SG223",
            332
        ],
        [
            "FG193",
            327
        ],
        [
            "FG078",
            323
        ],
        [
            "FG040",
            323
        ],
        [
            "FG242",
            317
        ],
        [
            "SG436",
            317
        ],
        [
            "SG462",
            310
        ],
        [
            "FG207",
            307
        ],
        [
            "SG108",
            306
        ],
        [
            "SG444",
            305
        ],
        [
            "SG270",
            305
        ],
        [
            "FG059",
            303
        ],
        [
            "FG043",
            302
        ],
        [
            "SG542",
            301
        ],
        [
            "FG032",
            297
        ],
        [
            "FG048",
            295
        ],
        [
            "SG441",
            293
        ],
        [
            "FG073",
            292
        ],
        [
            "CG003",
            289
        ],
        [
            "SG233",
            289
        ],
        [
            "SG388",
            288
        ],
        [
            "SG528",
            273
        ],
        [
            "SG515",
            272
        ],
        [
            "SG381",
            271
        ],
        [
            "SG504",
            271
        ],
        [
            "FG210",
            270
        ],
        [
            "SG376",
            264
        ],
        [
            "FG198",
            264
        ],
        [
            "SG418",
            263
        ],
        [
            "FG215",
            262
        ],
        [
            "FG035",
            259
        ],
        [
            "SG083",
            258
        ],
        [
            "SG038",
            257
        ],
        [
            "CG033",
            257
        ],
        [
            "FG085",
            254
        ],
        [
            "CG008",
            252
        ],
        [
            "SG421",
            251
        ],
        [
            "SG238",
            244
        ],
        [
            "SG024",
            242
        ],
        [
            "SG032",
            242
        ],
        [
            "FG017",
            241
        ],
        [
            "SG492",
            241
        ],
        [
            "SG449",
            235
        ],
        [
            "SG488",
            235
        ],
        [
            "SG505",
            233
        ],
        [
            "SG021",
            233
        ],
        [
            "SG331",
            231
        ],
        [
            "SG260",
            230
        ],
        [
            "SG058",
            226
        ],
        [
            "SG409",
            222
        ],
        [
            "SG099",
            220
        ],
        [
            "SG471",
            220
        ],
        [
            "SG247",
            218
        ],
        [
            "FG074",
            214
        ],
        [
            "SG299",
            213
        ],
        [
            "SG219",
            212
        ],
        [
            "SG094",
            211
        ],
        [
            "SG526",
            210
        ],
        [
            "SG382",
            210
        ],
        [
            "SG330",
            206
        ],
        [
            "SG527",
            205
        ],
        [
            "FG245",
            205
        ],
        [
            "FG161",
            205
        ],
        [
            "FG269",
            204
        ],
        [
            "SG447",
            203
        ],
        [
            "SG086",
            201
        ],
        [
            "SG325",
            201
        ],
        [
            "SG017",
            199
        ],
        [
            "CG027",
            199
        ],
        [
            "SG470",
            198
        ],
        [
            "FG061",
            196
        ],
        [
            "SG511",
            195
        ],
        [
            "SG323",
            193
        ],
        [
            "CG030",
            193
        ],
        [
            "CG042",
            191
        ],
        [
            "CG029",
            190
        ],
        [
            "FG137",
            190
        ],
        [
            "SG298",
            186
        ],
        [
            "FG203",
            183
        ],
        [
            "SG129",
            183
        ],
        [
            "SG540",
            182
        ],
        [
            "FG114",
            176
        ],
        [
            "SG432",
            175
        ],
        [
            "SG031",
            172
        ],
        [
            "SG395",
            171
        ],
        [
            "SG282",
            170
        ],
        [
            "SG037",
            168
        ],
        [
            "FG265",
            167
        ],
        [
            "SG279",
            167
        ],
        [
            "SG524",
            166
        ],
        [
            "FG055",
            166
        ],
        [
            "FG183",
            164
        ],
        [
            "SG448",
            163
        ],
        [
            "FG254",
            160
        ],
        [
            "SG369",
            158
        ],
        [
            "SG403",
            156
        ],
        [
            "SG536",
            154
        ],
        [
            "FG062",
            153
        ],
        [
            "SG119",
            153
        ],
        [
            "SG484",
            152
        ],
        [
            "SG046",
            152
        ],
        [
            "SG231",
            152
        ],
        [
            "FG212",
            148
        ],
        [
            "SG493",
            148
        ],
        [
            "FG191",
            147
        ],
        [
            "SG383",
            145
        ],
        [
            "SG333",
            139
        ],
        [
            "FG184",
            126
        ],
        [
            "FG247",
            126
        ],
        [
            "FG246",
            125
        ],
        [
            "SG420",
            125
        ],
        [
            "SG285",
            122
        ],
        [
            "SG423",
            122
        ],
        [
            "FG102",
            120
        ],
        [
            "FG113",
            117
        ],
        [
            "SG111",
            116
        ],
        [
            "CG010",
            115
        ],
        [
            "FG063",
            115
        ],
        [
            "SG428",
            114
        ],
        [
            "SG170",
            113
        ],
        [
            "FG153",
            112
        ],
        [
            "FG154",
            108
        ],
        [
            "CG050",
            108
        ],
        [
            "SG066",
            106
        ],
        [
            "FG262",
            105
        ],
        [
            "SG271",
            104
        ],
        [
            "CG054",
            104
        ],
        [
            "SG407",
            103
        ],
        [
            "SG176",
            103
        ],
        [
            "SG272",
            100
        ],
        [
            "SG434",
            97
        ],
        [
            "SG201",
            97
        ],
        [
            "FG171",
            96
        ],
        [
            "SG204",
            95
        ],
        [
            "SG373",
            95
        ],
        [
            "CG013",
            94
        ],
        [
            "FG264",
            93
        ],
        [
            "FG130",
            90
        ],
        [
            "FG194",
            87
        ],
        [
            "FG029",
            87
        ],
        [
            "FG021",
            84
        ],
        [
            "SG179",
            83
        ],
        [
            "SG408",
            83
        ],
        [
            "SG012",
            83
        ],
        [
            "SG349",
            83
        ],
        [
            "FG216",
            82
        ],
        [
            "SG534",
            82
        ],
        [
            "SG182",
            81
        ],
        [
            "FG250",
            81
        ],
        [
            "FG249",
            81
        ],
        [
            "CG052",
            79
        ],
        [
            "SG239",
            79
        ],
        [
            "SG004",
            78
        ],
        [
            "SG364",
            77
        ],
        [
            "SG288",
            77
        ],
        [
            "FG149",
            77
        ],
        [
            "SG530",
            75
        ],
        [
            "SG254",
            74
        ],
        [
            "SG539",
            73
        ],
        [
            "SG535",
            72
        ],
        [
            "SG473",
            71
        ],
        [
            "SG486",
            71
        ],
        [
            "FG235",
            70
        ],
        [
            "SG386",
            70
        ],
        [
            "FG238",
            70
        ],
        [
            "",
            70
        ],
        [
            "FG234",
            68
        ],
        [
            "CG035",
            68
        ],
        [
            "SG537",
            67
        ],
        [
            "SG087",
            66
        ],
        [
            "FG156",
            66
        ],
        [
            "SG391",
            66
        ],
        [
            "FG030",
            64
        ],
        [
            "SG438",
            64
        ],
        [
            "SG513",
            64
        ],
        [
            "CG041",
            64
        ],
        [
            "CG020",
            63
        ],
        [
            "SG237",
            63
        ],
        [
            "CG046",
            63
        ],
        [
            "FG192",
            63
        ],
        [
            "SG030",
            63
        ],
        [
            "CG024",
            60
        ],
        [
            "CG001",
            60
        ],
        [
            "SG538",
            59
        ],
        [
            "FG057",
            58
        ],
        [
            "CG044",
            58
        ],
        [
            "FG145",
            58
        ],
        [
            "SG258",
            58
        ],
        [
            "FG144",
            57
        ],
        [
            "FG170",
            56
        ],
        [
            "FG018",
            56
        ],
        [
            "SG339",
            54
        ],
        [
            "SG458",
            51
        ],
        [
            "FG197",
            51
        ],
        [
            "SG429",
            51
        ],
        [
            "SG451",
            50
        ],
        [
            "FG015",
            50
        ],
        [
            "CG014",
            49
        ],
        [
            "SG367",
            49
        ],
        [
            "SG213",
            49
        ],
        [
            "SG510",
            46
        ],
        [
            "SG296",
            46
        ],
        [
            "SG045",
            45
        ],
        [
            "SG509",
            45
        ],
        [
            "SG344",
            45
        ],
        [
            "FG189",
            45
        ],
        [
            "CG026",
            41
        ],
        [
            "SG410",
            41
        ],
        [
            "FG164",
            41
        ],
        [
            "CG039",
            40
        ],
        [
            "SG460",
            39
        ],
        [
            "SG533",
            39
        ],
        [
            "FG229",
            38
        ],
        [
            "SG033",
            38
        ],
        [
            "SG089",
            38
        ],
        [
            "SG546",
            37
        ],
        [
            "SG506",
            37
        ],
        [
            "FG213",
            36
        ],
        [
            "FG117",
            36
        ],
        [
            "SG313",
            36
        ],
        [
            "SG327",
            36
        ],
        [
            "SG468",
            35
        ],
        [
            "SG457",
            35
        ],
        [
            "FG038",
            35
        ],
        [
            "SG187",
            35
        ],
        [
            "SG188",
            34
        ],
        [
            "SG490",
            34
        ],
        [
            "FG187",
            32
        ],
        [
            "SG541",
            31
        ],
        [
            "FG200",
            29
        ],
        [
            "CG049",
            29
        ],
        [
            "FG243",
            29
        ],
        [
            "SG167",
            28
        ],
        [
            "SG003",
            28
        ],
        [
            "CG048",
            28
        ],
        [
            "SG430",
            28
        ],
        [
            "SG467",
            28
        ],
        [
            "SG483",
            27
        ],
        [
            "SG297",
            27
        ],
        [
            "FG175",
            27
        ],
        [
            "FG120",
            27
        ],
        [
            "SG041",
            25
        ],
        [
            "SG544",
            25
        ],
        [
            "SG251",
            25
        ],
        [
            "CG043",
            25
        ],
        [
            "SG329",
            25
        ],
        [
            "SG454",
            25
        ],
        [
            "SG008",
            24
        ],
        [
            "FG088",
            24
        ],
        [
            "SG091",
            24
        ],
        [
            "SG016",
            23
        ],
        [
            "FG253",
            23
        ],
        [
            "FG220",
            23
        ],
        [
            "FG051",
            23
        ],
        [
            "FG257",
            23
        ],
        [
            "FG132",
            22
        ],
        [
            "FG218",
            22
        ],
        [
            "FG181",
            22
        ],
        [
            "FG201",
            22
        ],
        [
            "SG020",
            20
        ],
        [
            "SG385",
            20
        ],
        [
            "SG318",
            20
        ],
        [
            "CG045",
            20
        ],
        [
            "SG028",
            19
        ],
        [
            "FG158",
            19
        ],
        [
            "SG082",
            19
        ],
        [
            "SG502",
            19
        ],
        [
            "SG479",
            18
        ],
        [
            "FG172",
            18
        ],
        [
            "CG051",
            18
        ],
        [
            "SG060",
            18
        ],
        [
            "FG163",
            18
        ],
        [
            "SG522",
            18
        ],
        [
            "SG494",
            17
        ],
        [
            "SG183",
            16
        ],
        [
            "SG010",
            16
        ],
        [
            "FG166",
            15
        ],
        [
            "SG456",
            15
        ],
        [
            "FG116",
            15
        ],
        [
            "FG160",
            14
        ],
        [
            "SG085",
            14
        ],
        [
            "SG366",
            14
        ],
        [
            "FG152",
            14
        ],
        [
            "FG248",
            14
        ],
        [
            "SG531",
            13
        ],
        [
            "SG371",
            13
        ],
        [
            "FG135",
            13
        ],
        [
            "SG011",
            12
        ],
        [
            "SG491",
            12
        ],
        [
            "FG214",
            12
        ],
        [
            "SG338",
            12
        ],
        [
            "SG337",
            11
        ],
        [
            "FG236",
            11
        ],
        [
            "CG047",
            11
        ],
        [
            "SG312",
            11
        ],
        [
            "SG517",
            10
        ],
        [
            "SG015",
            10
        ],
        [
            "SG413",
            9
        ],
        [
            "FG202",
            9
        ],
        [
            "SG165",
            9
        ],
        [
            "SG324",
            8
        ],
        [
            "SG007",
            8
        ],
        [
            "FG041",
            8
        ],
        [
            "SG499",
            8
        ],
        [
            "SG042",
            8
        ],
        [
            "FG014",
            8
        ],
        [
            "FG251",
            8
        ],
        [
            "SG414",
            8
        ],
        [
            "SG412",
            8
        ],
        [
            "SG023",
            8
        ],
        [
            "SG474",
            8
        ],
        [
            "SG442",
            7
        ],
        [
            "SG002",
            7
        ],
        [
            "SG480",
            7
        ],
        [
            "FG159",
            7
        ],
        [
            "SG543",
            7
        ],
        [
            "FG223",
            7
        ],
        [
            "FG204",
            7
        ],
        [
            "FG131",
            7
        ],
        [
            "FG199",
            7
        ],
        [
            "SG345",
            7
        ],
        [
            "SG052",
            6
        ],
        [
            "FG068",
            6
        ],
        [
            "SG512",
            6
        ],
        [
            "SG346",
            6
        ],
        [
            "SG496",
            6
        ],
        [
            "FG103",
            6
        ],
        [
            "FG180",
            6
        ],
        [
            "SG342",
            6
        ],
        [
            "FG107",
            6
        ],
        [
            "FG155",
            6
        ],
        [
            "FG036",
            5
        ],
        [
            "SG158",
            5
        ],
        [
            "FG225",
            5
        ],
        [
            "SG359",
            5
        ],
        [
            "FG148",
            5
        ],
        [
            "SG545",
            5
        ],
        [
            "SG443",
            5
        ],
        [
            "SG358",
            5
        ],
        [
            "FG067",
            5
        ],
        [
            "SG202",
            5
        ],
        [
            "FG232",
            5
        ],
        [
            "SG343",
            5
        ],
        [
            "SG461",
            5
        ],
        [
            "FG182",
            4
        ],
        [
            "SG208",
            4
        ],
        [
            "SG027",
            4
        ],
        [
            "CG053",
            4
        ],
        [
            "SG450",
            4
        ],
        [
            "SG501",
            4
        ],
        [
            "FG237",
            4
        ],
        [
            "FG208",
            4
        ],
        [
            "SG482",
            4
        ],
        [
            "SG328",
            4
        ],
        [
            "SG293",
            4
        ],
        [
            "SG150",
            4
        ],
        [
            "FG168",
            4
        ],
        [
            "SG014",
            3
        ],
        [
            "FG151",
            3
        ],
        [
            "SG455",
            3
        ],
        [
            "FG259",
            3
        ],
        [
            "FG222",
            3
        ],
        [
            "SG241",
            3
        ],
        [
            "FG221",
            3
        ],
        [
            "SG200",
            3
        ],
        [
            "FG256",
            3
        ],
        [
            "FG233",
            3
        ],
        [
            "SG280",
            3
        ],
        [
            "SG142",
            3
        ],
        [
            "FG230",
            3
        ],
        [
            "SG396",
            3
        ],
        [
            "SG497",
            3
        ],
        [
            "SG523",
            3
        ],
        [
            "SG400",
            3
        ],
        [
            "SG291",
            3
        ],
        [
            "FG252",
            3
        ],
        [
            "FG217",
            3
        ],
        [
            "FG227",
            3
        ],
        [
            "SG435",
            3
        ],
        [
            "SG377",
            2
        ],
        [
            "FG224",
            2
        ],
        [
            "FG147",
            2
        ],
        [
            "SG018",
            2
        ],
        [
            "SG360",
            2
        ],
        [
            "SG518",
            2
        ],
        [
            "SG319",
            2
        ],
        [
            "SG507",
            2
        ],
        [
            "SG362",
            2
        ],
        [
            "FG228",
            2
        ],
        [
            "SG379",
            1
        ],
        [
            "FG143",
            1
        ],
        [
            "FG167",
            1
        ],
        [
            "FG031",
            1
        ],
        [
            "SG422",
            1
        ],
        [
            "SG489",
            1
        ],
        [
            "SG401",
            1
        ],
        [
            "SG009",
            1
        ],
        [
            "SG001",
            1
        ],
        [
            "SG206",
            1
        ],
        [
            "FG195",
            1
        ],
        [
            "SG495",
            1
        ],
        [
            "FG231",
            1
        ],
        [
            "SG520",
            1
        ],
        [
            "SG335",
            1
        ],
        [
            "SG122",
            1
        ],
        [
            "SG354",
            1
        ],
        [
            "SG485",
            1
        ],
        [
            "SG121",
            1
        ],
        [
            "SG521",
            1
        ],
        [
            "SG157",
            1
        ],
        [
            "FG186",
            1
        ],
        [
            "SG123",
            1
        ],
        [
            "FG058",
            1
        ],
        [
            "FG178",
            1
        ],
        [
            "SG519",
            1
        ],
        [
            "FG258",
            1
        ],
        [
            "SG050",
            1
        ],
        [
            "SG411",
            1
        ],
        [
            "SG171",
            1
        ]
    ],
    "ranges": {
        "asymetric_atoms": [
            0,
            57
        ],
        "fsp3": [
            0,
            1
        ],
        "h_bond_acceptors": [
            0,
            102
        ],
        "h_bond_donors": [
            0,
            93
        ],
        "log_p": [
            -35.63999938964844,
            28.5
        ],
        "molecular_weight": [
            0,
            6511.509765625
        ],
        "purity": [
            0,
            101
        ]
    }
};



export const conceptsMapOld: { [key: string]: string } = {
    "005": "Iodopyridine (Pyridyl Iodide)",
    "006": "Fluoropyridine (Pyridyl Fluoride)",
    "007": "Halopyridine",
    "FG001": "Alkyne",
    "FG003": "Phenyl",
    "FG004": "Aliphatic alcohol",
    "FG005": "Ketone",
    "FG006": "Aldehyde",
    "FG007": "Acyl halide",
    "FG008": "Carbonate ester",
    "FG009": "Carboxylate",
    "FG010": "Carboxylic acid",
    "FG011": "Ester",
    "FG012": "Methoxy",
    "FG013": "Terminal alkyne",
    "FG014": "Hydroperoxide",
    "FG015": "Peroxide",
    "FG016": "Ether",
    "FG017": "Hemiacetal",
    "FG018": "Hemiketal",
    "FG019": "Acetal",
    "FG020": "Ketal",
    "FG021": "Orthoester",
    "FG022": "Heterocycle",
    "FG023": "Heteroaromatic",
    "FG024 ": "Amide",
    "FG025": "Primary amine",
    "FG026": "Secondary amine",
    "FG027": "Tertiary amine",
    "FG028": "Amine (P+S+T)",
    "FG029": "Primary ketimine",
    "FG030": "Secondary ketimine",
    "FG031": "Primary aldimine",
    "FG032": "Secondary aldimine",
    "FG033": "Imide",
    "FG034": "Azide",
    "FG035": "Azo",
    "FG036": "Cyanate",
    "FG037": "Isocyanate",
    "FG038": "Nitrate",
    "FG039": "Nitrile",
    "FG040": "Isonitrile",
    "FG041": "Nitrite",
    "FG042": "Nitro",
    "FG043": "Aldoxime",
    "FG044": "Ketoxime",
    "FG045": "Oxime",
    "FG046": "Aliphatic thiol",
    "FG047": "Sulfide",
    "FG048": "Disulfide",
    "FG049": "Sulfoxide",
    "FG050": "Sulfone",
    "FG051": "Sulfinic acid",
    "FG052": "Sulfonic acid",
    "FG053": "Sulfonyl chloride",
    "FG054": "Sulfonyl fluoride",
    "FG055": "Thiocyanate",
    "FG056": "Isothiocyanate",
    "FG057": "Thione",
    "FG058": "Thial",
    "FG059": "Thioester",
    "FG060": "Phosphine",
    "FG061": "Phosphonic acid",
    "FG062": "Phosphate",
    "FG063": "Phosphodiester",
    "FG064": "Phosphonate",
    "FG065": "Boronic acid",
    "FG066": "Boronic ester",
    "FG067": "Borinic acid",
    "FG068": "Borinic ester",
    "FG069": "Amino acid",
    "FG070": "Terminal alkyne (aliphatic hydrocarbon)",
    "FG071": "Terminal alkyne (aromatic hydrocarbon)",
    "FG072": "Terminal alkyne (alcohol)",
    "FG073": "Terminal alkyne (carboxylic acid)",
    "FG074": "Terminal alkyne (ester)",
    "FG075": "Terminal alkyne (amine)",
    "FG076": "Terminal alkyne (heteroaromatic)",
    "FG077": "Alpha-amino acid",
    "FG078": "Isocyanide",
    "FG079": "Fluoro",
    "FG080": "Chloro",
    "FG081": "Bromo",
    "FG082": "Iodo",
    "FG083": "Halo",
    "FG084": "Pinacol boronate",
    "FG085": "Dialdehyde",
    "FG086": "Diamine",
    "FG087": "Stannyl",
    "FG088": "Distannyl",
    "FG089": "Chiral",
    "FG090": "Sulfonamide",
    "FG091": "Sulfamide",
    "FG092": "Methyl",
    "FG093": "N-methyl",
    "FG094": "Ethyl",
    "FG095": "Dimethyl",
    "FG096": "Aromatic alcohol",
    "FG097": "Aromatic thiol",
    "FG098": "NBenzyl",
    "FG099": "NFmoc",
    "FG100": "NBOC",
    "FG101": "Trifluoromethyl",
    "FG102": "SF5",
    "FG103": "SF3",
    "FG104": "Lactam",
    "FG105": "Lactone",
    "FG106": "Acid anhydride",
    "FG107": "Dicarbonate",
    "FG108": "Tolyl",
    "FG109": "Alkene",
    "FG110": "Enone",
    "FG111": "Malonate",
    "FG112": "Beta-amino acid",
    "FG113": "Ynone",
    "FG114": "Ynoate",
    "FG115": "Ynoic acid",
    "FG116": "Ynamide",
    "FG117": "Enyne",
    "FG118": "Metallic",
    "FG119": "Sulfonate",
    "FG120": "Diazo",
    "FG121": "Azoxy",
    "FG122": "Organosilicon",
    "FG123": "NCbz",
    "FG124": "Ammonium",
    "FG125": "Urea",
    "FG126": "Trifluoromethoxy",
    "FG127": "Difluoromethyl",
    "FG128": "Difluoromethoxy",
    "FG129": "Carbamate",
    "FG130": "Chloroformate",
    "FG131": "Chlorosulfate",
    "FG132": "Diazonium",
    "FG133": "Hydrobromide",
    "FG134": "Hydrochloride",
    "FG135": "Hydrofluoride",
    "FG136": "Hydrate",
    "FG137": "Hydrazone",
    "FG138": "Guanidine",
    "FG139": "Hydrazine",
    "FG140": "Hydroxylamine",
    "FG141": "Fluoronium",
    "FG142": "Chloronium",
    "FG143": "Bromonium",
    "FG144": "Iodonium",
    "FG145": "Halonium",
    "FG146": "N-oxide",
    "FG147": "Nitrosonium",
    "FG148": "Carbazide",
    "FG149": "Semicarbazide",
    "FG150": "Carbazone",
    "FG151": "Semicarbazone",
    "FG152": "Sulfanide",
    "FG153": "Sulfate",
    "FG154": "Sulfinate",
    "FG155": "Sulfonic anhydride",
    "FG156": "Sulfonium",
    "FG157": "Thioamide",
    "FG158": "O-thiocarbamate",
    "FG159": "S-thiocarbamate",
    "FG160": "Thiolate",
    "FG161": "Thiosemicarbazide",
    "FG162": "Thiourea",
    "FG163": "Uronium",
    "FG164": "Acyl fluoride",
    "FG165": "Acyl chloride",
    "FG166": "Acyl bromide",
    "FG167": "Acyl iodide",
    "FG168": "Difluoroborane",
    "FG169": "Difluoromethoxy",
    "FG170": "Difluoromethylsulfone",
    "FG171": "Difluoromethylsulfide",
    "FG172": "Acetylacetonate",
    "FG173": "Amidine",
    "FG174": "Amino acid ester",
    "FG175": "Ammonia",
    "FG176": "Biguanidine",
    "FG177": "Tetrafluoroborate",
    "FG178": "Sulfur hexafluoride",
    "FG179": "Triflate",
    "FG180": "Triflic acid",
    "FG181": "Trifluoroacetate",
    "FG182": "Trifluoroborane",
    "FG183": "Trifluoroborate",
    "FG184": "Trifluoromethyl sulfone",
    "FG185": "Carboximidate",
    "FG186": "Acetimidate",
    "FG187": "Borate ester",
    "FG188": "Nitronium",
    "FG189": "Lanthanide (Inorganic)",
    "FG190": "Actinide (Inorganic)",
    "FG191": "Alkali metal (Inorganic)",
    "FG192": "Alkaline earth metal (Inorganic)",
    "FG193": "Halogen (Inorganic)",
    "FG194": "Metalloid (Inorganic)",
    "FG195": "Noble gas (Inorganic)",
    "FG196": "Other non-metal (Inorganic)",
    "FG197": "Post-transition metal (Inorganic)",
    "FG198": "Transition metal (Inorganic)",
    "FG199": "Organoaluminium",
    "FG200": "Organocopper",
    "FG201": "Organogermanium",
    "FG202": "Organolead",
    "FG203": "Organolithium",
    "FG204": "Organomercury",
    "FG205": "Organotin",
    "FG206": "Organozinc",
    "FG207": "Grignard reagent",
    "FG208": "Metallocene",
    "FG209": "Trialkoxysilane",
    "FG210": "Tributyltin",
    "FG211": "Alkane",
    "FG212": "Sulfinamide",
    "FG213": "Phosphite ester",
    "FG214": "Diselenide",
    "FG215": "Phosphonium",
    "FG216": "Phosphate ester",
    "FG217": "Phosphorothioic triamide",
    "FG218": "Carbodiimide",
    "FG219": "N-hydroxy imidamide",
    "FG220": "Sulfite ester",
    "FG221": "Phosphoramide",
    "FG222": "Phosphorous triamide",
    "FG223": "Trisulfide",
    "FG224": "Tetrasulfide",
    "FG225": "Dithiobiurea",
    "FG226": "Carbothioic O-acid",
    "FG227": "Carbothioic S-acid",
    "FG228": "Biurea",
    "FG229": "Cyanamide",
    "FG230": "Sulfoxonium",
    "FG231": "Biuret",
    "FG232": "Dicyanamine",
    "FG233": "Boranetriamine",
    "FG234": "C-nitroso",
    "FG235": "N-nitroso",
    "FG236": "O-nitroso",
    "FG237": "S-nitroso",
    "FG238": "N-nitro",
    "FG239": "Chloride",
    "FG240": "Bromide",
    "FG241": "Amidoxime",
    "FG242": "Phosphine oxide",
    "FG243": "Nitrosamine",
    "FG244": "Hydrazide",
    "FG245": "Thiosemicarbazide",
    "FG246": "Carbonyl thiosemicarbazide",
    "FG247": "Cabonyl thiourea",
    "FG248": "Carbonic acid",
    "FG249": "Dithiocarbamate",
    "FG250": "Hexafluorophosphate",
    "FG251": "Diaminophosphinate",
    "FG252": "Diaminophosphinic acid",
    "FG253": "Iminium",
    "FG254": "Hydrazine carboxylate",
    "FG255": "Hydrazine-1,2-dicarboxylate",
    "FG256": "Hydrazine-1,1,2-tricarboxylate",
    "FG257": "Borane",
    "FG258": "Tetracyanoborate",
    "FG259": "Tetraphenylborate",
    "FG260": "Imidic acid ester",
    "SG001": "1,2,3,4-Oxatriazole",
    "SG002": "1,2,3,4-Tetrabromobenzene",
    "SG003": "1,2,3,4-Tetrachlorobenzene",
    "SG004": "1,2,3,4-Tetrafluorobenzene",
    "SG005": "1,2,3,4-Tetraiodobenzene",
    "SG006": "1,2,3,5-Tetrabromobenzene",
    "SG007": "1,2,3,5-Tetrachlorobenzene",
    "SG008": "1,2,3,5-Tetrafluorobenzene",
    "SG009": "1,2,3,5-Tetraiodobenzene",
    "SG010": "1,2,3-Oxadiazole",
    "SG011": "1,2,3-Tribromobenzene",
    "SG012": "1,2,3-Trichlorobenzene",
    "SG013": "1,2,3-Trifluorobenzene",
    "SG014": "1,2,3-Triiodobenzene",
    "SG015": "1,2,4,5-Tetrabromobenzene",
    "SG016": "1,2,4,5-Tetrachlorobenzene",
    "SG017": "1,2,4,5-Tetrafluorobenzene",
    "SG018": "1,2,4,5-Tetraiodobenzene",
    "SG019": "1,2,4-Oxadiazole",
    "SG020": "1,2,4-Tribromobenzene",
    "SG021": "1,2,4-Trichlorobenzene",
    "SG022": "1,2,4-Trifluorobenzene",
    "SG023": "1,2,4-Triiodobenzene",
    "SG024": "1,2-Dibromobenzene",
    "SG025": "1,2-Dichlorobenzene",
    "SG026": "1,2-Difluorobenzene",
    "SG027": "1,2-Dihydroazete",
    "SG028": "1,2-Diiodobenzene",
    "SG029": "1,3,4-Oxadiazole",
    "SG030": "1,3,5-Tribromobenzene",
    "SG031": "1,3,5-Trichlorobenzene",
    "SG032": "1,3,5-Trifluorobenzene",
    "SG033": "1,3,5-Triiodobenzene",
    "SG034": "1,3-Dibromobenzene",
    "SG035": "1,3-Dichlorobenzene",
    "SG036": "1,3-Difluorobenzene",
    "SG037": "1,3-Diiodobenzene",
    "SG038": "1,4-Dibromobenzene",
    "SG039": "1,4-Dichlorobenzene",
    "SG040": "1,4-Difluorobenzene",
    "SG041": "1,4-Diiodobenzene",
    "SG042": "1,4-Dithiane",
    "SG043": "2H-Thiete",
    "SG044": "2-Pyrazoline",
    "SG045": "2-Pyrroline",
    "SG046": "3,4-Dihydro-2H-pyran",
    "SG047": "3-Membered heteroaromatic",
    "SG048": "3-Membered heterocycle",
    "SG049": "3-Membered heterocyclic sulfonyl chloride",
    "SG050": "4-Membered heteroaromatic",
    "SG051": "4-Membered heterocycle",
    "SG052": "4-Membered heterocyclic sulfonyl chloride",
    "SG053": "5-Membered heteroaromatic",
    "SG054": "5-Membered heterocycle",
    "SG055": "5-Membered heterocyclic sulfonyl chloride",
    "SG056": "6-Membered heteroaromatic",
    "SG057": "6-Membered heterocycle",
    "SG058": "6-Membered heterocyclic sulfonyl chloride",
    "SG059": "Purine",
    "SG060": "7-Membered heteroaromatic",
    "SG061": "7-Membered heterocycle",
    "SG062": "7-Membered heterocyclic sulfonyl chloride",
    "SG063": "Carbazole",
    "SG064": "Atezidine",
    "SG065": "Azepane",
    "SG066": "Aziridine",
    "SG067": "Benzimidazole",
    "SG068": "Benzofuran",
    "SG069": "Benzothiazole",
    "SG070": "Benzothiophene",
    "SG071": "Benzotriazole",
    "SG072": "Benzoxazole",
    "SG073": "Chiral alcohol",
    "SG074": "Chiral amide",
    "SG075": "Chiral amine",
    "SG076": "Chiral amino alcohol (all)",
    "SG077": "Chiral amino alcohol (both chiral)",
    "SG078": "Chiral amino alcohol (chiral alcohol)",
    "SG079": "Chiral amino alcohol (chiral amino)",
    "SG080": "Chiral carboxylic acid",
    "SG081": "Chiral ester",
    "SG082": "Chiral isocyanate",
    "SG083": "Cinnoline",
    "SG084": "Cyclobutane",
    "SG085": "Cyclobutene",
    "SG086": "Dibenzofuran",
    "SG087": "Dibenzothiophene",
    "SG088": "Dibromobenzene",
    "SG089": "Dibromomethyl/dibromomethoxy benzene",
    "SG090": "Dichlorobenzene",
    "SG091": "Dichloromethyl/dichloromethoxy benzene",
    "SG092": "Difluorobenzene",
    "SG093": "Difluoromethyl/difluoromethoxy benzene",
    "SG094": "Diiodobenzene",
    "SG095": "Diiodomethyl/diiodomethoxy benzene",
    "SG096": "Disubstituted (2,3)- monobromobenzene",
    "SG097": "Disubstituted (2,3)- monochlorobenzene",
    "SG098": "Disubstituted (2,3)- monofluorobenzene",
    "SG099": "Disubstituted (2,3)- monoiodobenzene",
    "SG100": "Disubstituted (2,4)- monobromobenzene",
    "SG101": "Disubstituted (2,4)- monochlorobenzene",
    "SG102": "Disubstituted (2,4)- monofluorobenzene",
    "SG103": "Disubstituted (2,4)- monoiodobenzene",
    "SG104": "Disubstituted (2,5)- monobromobenzene",
    "SG105": "Disubstituted (2,5)- monochlorobenzene",
    "SG106": "Disubstituted (2,5)- monofluorobenzene",
    "SG107": "Disubstituted (2,5)- monoiodobenzene",
    "SG108": "Disubstituted (2,6)- monobromobenzene",
    "SG109": "Disubstituted (2,6)- monochlorobenzene",
    "SG110": "Disubstituted (2,6)- monofluorobenzene",
    "SG111": "Disubstituted (2,6)- monoiodobenzene",
    "SG112": "Disubstituted (3,4)- monobromobenzene",
    "SG113": "Disubstituted (3,4)- monochlorobenzene",
    "SG114": "Disubstituted (3,4)- monofluorobenzene",
    "SG115": "Disubstituted (3,4)- monoiodobenzene",
    "SG116": "Disubstituted (3,5)- monobromobenzene",
    "SG117": "Disubstituted (3,5)- monochlorobenzene",
    "SG118": "Disubstituted (3,5)- monofluorobenzene",
    "SG119": "Disubstituted (3,5)- monoiodobenzene",
    "SG120": "Furan",
    "SG121": "Hexabromobenzene",
    "SG122": "Hexachlorobenzene",
    "SG123": "Hexafluorobenzene",
    "SG124": "Hexaiodobenzene",
    "SG125": "Imidazole",
    "SG126": "Imidazolidine",
    "SG127": "Indazole",
    "SG128": "Indole",
    "SG129": "Indolizine",
    "SG130": "Isoquinoline",
    "SG131": "Isothiazole",
    "SG132": "Isoxazole",
    "SG133": "Monobromobenzene",
    "SG134": "Monochlorobenzene",
    "SG135": "Monofluorobenzene",
    "SG136": "Monoiodobenzenes",
    "SG137": "Monosubstituted (meta) monobromobenzene",
    "SG138": "Monosubstituted (meta) monochlorobenzene",
    "SG139": "Monosubstituted (meta) monofluorobenzene",
    "SG140": "Monosubstituted (meta) monoiodobenzene",
    "SG141": "Monosubstituted (meta) tribromomethylbenzene",
    "SG142": "Monosubstituted (meta) trichloromethylbenzene",
    "SG143": "Monosubstituted (meta) trifluoromethylbenzene",
    "SG144": "Monosubstituted (meta) triiodomethylbenzene",
    "SG145": "Monosubstituted (ortho) monobromobenzene",
    "SG146": "Monosubstituted (ortho) monochlorobenzene",
    "SG147": "Monosubstituted (ortho) monofluorobenzene",
    "SG148": "Monosubstituted (ortho) monoiodobenzene",
    "SG149": "Monosubstituted (ortho) tribromomethylbenzene",
    "SG150": "Monosubstituted (ortho) trichloromethylbenzene",
    "SG151": "Monosubstituted (ortho) trifluoromethylbenzene",
    "SG152": "Monosubstituted (ortho) triiodomethylbenzene",
    "SG153": "Monosubstituted (para) monobromobenzene",
    "SG154": "Monosubstituted (para) monochlorobenzene",
    "SG155": "Monosubstituted (para) monofluorobenzene",
    "SG156": "Monosubstituted (para) monoiodobenzene",
    "SG157": "Monosubstituted (para) tribromomethylbenzene",
    "SG158": "Monosubstituted (para) trichloromethylbenzene",
    "SG159": "Monosubstituted (para) trifluoromethylbenzene",
    "SG160": "Monosubstituted (para) triiodomethylbenzene",
    "SG161": "Morpholine",
    "SG162": "Oxazole",
    "SG163": "Oxetane",
    "SG164": "Oxirane",
    "SG165": "Pentabromobenzene",
    "SG166": "Pentabromosulfanyl benzene",
    "SG167": "Pentachlorobenzene",
    "SG168": "Pentachlorosulfanyl benzene",
    "SG169": "Pentafluorobenzene",
    "SG170": "Pentafluorosulfanyl benzene",
    "SG171": "Pentaiodobenzene",
    "SG172": "Pentaiodosulfanyl benzene",
    "SG173": "Phthalazine",
    "SG174": "Piperazine",
    "SG175": "Piperidine",
    "SG176": "Pteridine",
    "SG177": "Pyrazine",
    "SG178": "Pyrazole",
    "SG179": "Pyrazolidine",
    "SG180": "Pyridazine",
    "SG181": "Pyridine",
    "SG182": "Pyrido[2,3-b]pyrazine",
    "SG183": "Pyrido[3,4-d]pyridazine",
    "SG184": "Pyrimidine",
    "SG185": "Pyrrole",
    "SG186": "Pyrrolidine",
    "SG187": "Pyrrolo[1,2-a]pyrazine",
    "SG188": "Pyrrolo[1,2-b]pyridazine",
    "SG189": "Quinazoline",
    "SG190": "Quinoline",
    "SG191": "Quinoxaline",
    "SG195": "Tetrahydro-2H-pyran",
    "SG196": "Tetrahydrofuran",
    "SG198": "Tetrazole",
    "SG199": "Thiazole",
    "SG200": "Thiepane",
    "SG201": "Thietane",
    "SG202": "Thiirane",
    "SG203": "Thiophene",
    "SG204": "Tribromobenzenes",
    "SG205": "Tribromomethoxy benzene",
    "SG206": "Tribromomethylthio benzene",
    "SG207": "Trichlorobenzene",
    "SG208": "Trichloromethoxy benzene",
    "SG209": "Trichloromethylthio benzene",
    "SG210": "Trifluorobenzene",
    "SG211": "Trifluoromethoxy benzene",
    "SG212": "Trifluoromethylthio benzene",
    "SG213": "Triiodobenzene",
    "SG214": "Triiodomethoxy benzene",
    "SG215": "Triiodomethylthio benzene",
    "SG216": "Trisubstituted (2,3,4)- monobromobenzene",
    "SG217": "Trisubstituted (2,3,4)- monochlorobenzene",
    "SG218": "Trisubstituted (2,3,4)- monofluorobenzene",
    "SG219": "Trisubstituted (2,3,4)- monoiodobenzene",
    "SG220": "Trisubstituted (2,3,5)- monobromobenzene",
    "SG221": "Trisubstituted (2,3,5)- monochlorobenzene",
    "SG222": "Trisubstituted (2,3,5)- monofluorobenzene",
    "SG223": "Trisubstituted (2,3,5)- monoiodobenzene",
    "SG224": "Trisubstituted (2,4,5)- monobromobenzene",
    "SG225": "Trisubstituted (2,4,5)- monochlorobenzene",
    "SG226": "Trisubstituted (2,4,5)- monofluorobenzene",
    "SG227": "Trisubstituted (2,4,5)- monoiodobenzene",
    "SG228": "Trisubstituted (3,4,5)- monobromobenzene",
    "SG229": "Trisubstituted (3,4,5)- monochlorobenzene",
    "SG230": "Trisubstituted (3,4,5)- monofluorobenzene",
    "SG231": "Trisubstituted (3,4,5)- monoiodobenzene",
    "SG232": "Bicyclo[1.1.1]pentane",
    "SG233": "Bicyclo[3.1.1]heptane",
    "SG234": "Bicyclo[3.1.0]hexane",
    "SG235": "Bicyclo[2.2.2]octane",
    "SG236": "Bicyclo[2.2.1]heptane",
    "SG237": "Bicyclo[3.2.2]nonane",
    "SG238": "Bicyclo[2.1.1]hexane",
    "SG239": "Cubane",
    "SG240": "Adamantane",
    "SG241": "Noradamantane",
    "SG242": "Spirocycle",
    "SG243": "Chromane",
    "SG244": "Indane",
    "SG245": "Naphthalene",
    "SG246": "Diaminocyclohexane",
    "SG247": "1,3,2-dioxaborinane",
    "SG248": "Aniline",
    "SG249": "Cyclopentane",
    "SG250": "Cyclohexane",
    "SG251": "Phosphoramidite",
    "SG252": "Nitrobenzene",
    "SG253": "Phenol",
    "SG254": "Benzyl fluoride",
    "SG255": "Cyclic",
    "SG256": "Aromatic",
    "SG257": "PEG-3",
    "SG258": "PEG-4",
    "SG259": "Benzanilide",
    "SG260": "Anthraquinone",
    "SG261": "Tetralin",
    "SG262": "Cinnamic acid",
    "SG263": "Azetidine",
    "SG264": "Acetophenone",
    "SG265": "Anisole",
    "SG266": "Anthracene",
    "SG267": "Benzaldehyde",
    "SG268": "Benzhydryl",
    "SG269": "Benzhydrol",
    "SG270": "Benzisoxazole",
    "SG271": "Benzisothiazole",
    "SG272": "Xanthene",
    "SG273": "Benzoyl halide",
    "SG274": "Benzoic acid",
    "SG275": "1,2-Diazepine",
    "SG276": "1,3-Diazepine",
    "SG277": "1,4-Diazepine",
    "SG278": "Diazepine",
    "SG279": "1,4-Benzodiazepine",
    "SG280": "1,3-Benzodiazepine",
    "SG281": "1,2-Benzodiazepine",
    "SG282": "Benzodiazepine",
    "SG283": "1,2-Dioxane",
    "SG284": "1,3-Dioxane",
    "SG285": "1,4-Dioxane",
    "SG286": "Dioxane",
    "SG287": "1,2-Benzodioxane",
    "SG288": "1,3-Benzodioxane",
    "SG289": "1,4-Benzodioxane",
    "SG290": "Benzodioxane",
    "SG291": "1,4-Benzodioxine",
    "SG292": "1,3-Benzodioxole",
    "SG293": "Isobenzofuran",
    "SG294": "Benzonitrile",
    "SG295": "Benzophenone",
    "SG296": "benzothiadiazine",
    "SG297": "1,2,3-Benzothiadiazole",
    "SG298": "2,1,3-Benzothiadiazole",
    "SG299": "Benzothiadiazole",
    "SG300": "2H-1,2-Oxazine",
    "SG301": "4H-1,2-Oxazine",
    "SG302": "6H-1,2-Oxazine",
    "SG303": "1,2-Oxazine",
    "SG304": "2H-1,3-Oxazine",
    "SG305": "4H-1,3-Oxazine",
    "SG306": "6H-1,3-Oxazine",
    "SG307": "1,3-Oxazine",
    "SG312": "1,2-Oxazinane",
    "SG313": "1,3-Oxazinane",
    "SG314": "1,4-Oxazinane",
    "SG315": "Oxazinane",
    "SG316": "Benzoyl chloride",
    "SG317": "Benzyl chloride",
    "SG318": "Benzoyl fluoride",
    "SG319": "Benzoyl bromide",
    "SG320": "Benzyl bromide",
    "SG321": "Benzyl alcohol",
    "SG322": "Biphenyl",
    "SG323": "2H-Chromene",
    "SG324": "4H-Chromene",
    "SG325": "Chromene",
    "SG326": "Coumarin",
    "SG327": "Crown ether",
    "SG328": "Cycloheptatriene",
    "SG329": "Cyclohexa-1,3-diene",
    "SG330": "Cyclohexa-1,4-diene",
    "SG331": "Cyclohexadiene",
    "SG332": "Cyclohexene",
    "SG333": "Cyclooctane",
    "SG334": "1,2-Cyclooctadiene",
    "SG335": "1,3-Cyclooctadiene",
    "SG336": "1,4-Cyclooctadiene",
    "SG337": "1,5-Cyclooctadiene",
    "SG338": "Cyclooctadiene",
    "SG339": "Cyclopentadiene",
    "SG340": "Cyclopentene",
    "SG341": "Cyclopropane",
    "SG342": "Cyclotetrasiloxane",
    "SG343": "Cyclotrisiloxane",
    "SG344": "Decalin",
    "SG345": "1,2-Diazepane",
    "SG346": "1,3-Diazepane",
    "SG347": "1,4-Diazepane",
    "SG348": "Diazepane",
    "SG349": "Diazirine",
    "SG350": "Diaziridine",
    "SG351": "1,2-Dioxolane",
    "SG352": "1,3-Dioxolane",
    "SG353": "Dioxolane",
    "SG354": "1,3-Dioxole",
    "SG355": "3H-Dioxole",
    "SG356": "Diphenyl ether",
    "SG357": "1,2-Dithietane",
    "SG358": "1,3-Dithietane",
    "SG359": "Dithietane",
    "SG360": "1,2-Oxathietane",
    "SG361": "1,3-Oxathietane",
    "SG362": "Oxathietane",
    "SG363": "Fluorene",
    "SG364": "Furo[2,3-b]pyridine",
    "SG365": "2-Imidazoline",
    "SG366": "3-Imidazoline",
    "SG367": "4-Imidazoline",
    "SG368": "Imidazoline",
    "SG369": "Indene",
    "SG370": "Indoline",
    "SG371": "Benziodoxole",
    "SG372": "Isatin",
    "SG373": "Isatoic anhydride",
    "SG374": "Isoindoline",
    "SG375": "Phthalimide",
    "SG376": "Isophthalic acid",
    "SG377": "Naphtho[1,2-b]thiophene",
    "SG378": "Naphtho[1,2-c]isoxazole",
    "SG379": "4,5-Dihydro-naphtho[1,2-c]isoxazole",
    "SG380": "Naphtho[1,2-d]isoxazole",
    "SG381": "1,5-Naphthyridine",
    "SG382": "1,6-Naphthyridine",
    "SG383": "1,7-Naphthyridine",
    "SG384": "1,8-Naphthyridine",
    "SG385": "2,6-Naphthyridine",
    "SG386": "2,7-Naphthyridine",
    "SG387": "Naphthyridine",
    "SG388": "1,2,5-Oxadiazole",
    "SG389": "Oxadiazole",
    "SG390": "Oxazolidine",
    "SG391": "Isoxazolidine",
    "SG392": "Oxindole",
    "SG393": "Phenacyl bromide",
    "SG394": "Propiophenone",
    "SG395": "Phenanthrene",
    "SG396": "2H-1,2-Thiazine",
    "SG397": "4H-1,2-Thiazine",
    "SG398": "2H-1,3-Thiazine",
    "SG399": "4H-1,3-Thiazine",
    "SG400": "6H-1,3-Thiazine",
    "SG401": "2H-1,4-Thiazine",
    "SG402": "4H-1,4-Thiazine",
    "SG403": "Phenothiazine",
    "SG404": "Thiomorpholine",
    "SG405": "Phenylacetylene",
    "SG406": "Cyclotriphosphazene",
    "SG407": "Phthalic acid",
    "SG408": "Phthalic anhydride",
    "SG409": "Phthalide",
    "SG410": "Pyrylium",
    "SG411": "2H-Pyran",
    "SG412": "4H-pyran",
    "SG413": "Pyran",
    "SG414": "Pyrazolo[3,4-c]pyrazole",
    "SG415": "Pyrazolo[1,5-a]pyrimidine",
    "SG416": "Pyrazolo[1,5-a]pyridine",
    "SG417": "Pyrazolo[3,4-b]pyridine",
    "SG418": "Pyrazolo[3,4-c]pyridine",
    "SG419": "Pyrazolo[4,3-c]pyridine",
    "SG420": "Pyrido[3,2-d]pyrimidine",
    "SG421": "Pyrido[4,3-d]pyrimidine",
    "SG422": "Pyrrolo[3,2-c]pyrazole",
    "SG423": "Pyrrolo[3,4-c]pyrazole",
    "SG424": "Pyrrolo[2,3-b]pyridine",
    "SG425": "Pyrrolo[3,2-b]pyridine",
    "SG426": "Pyrrolo[2,3-c]pyridine",
    "SG427": "Pyrrolo[3,2-c]pyridine",
    "SG428": "Pyrrolo[3,4-c]pyridine",
    "SG429": "Pyrrolo[1,2-a]pyrimidine",
    "SG430": "Pyrrolo[1,2-c]pyrimdine",
    "SG431": "Pyrrolo[2,3-d]pyrimidine",
    "SG432": "Pyrrolo[3,2-d]pyrimidine",
    "SG433": "Styrene",
    "SG434": "Terephthalic acid",
    "SG435": "Tetraphenyl borate",
    "SG436": "1,2,3-Thiadiazole",
    "SG437": "1,2,4-Thiadiazole",
    "SG438": "1,2,5-Thiadiazole",
    "SG439": "1,3,4-Thiadiazole",
    "SG440": "Thiadiazole",
    "SG441": "2-Thiazoline",
    "SG442": "3-Thiazoline",
    "SG443": "4-Thiazoline",
    "SG444": "Thiazoline",
    "SG445": "Thiazolane",
    "SG446": "Thieno[2,3-b]pyridine",
    "SG447": "Thieno[2,3-c]pyridine",
    "SG448": "Thieno[3,2-b]pyridine",
    "SG449": "Thieno[3,2-c]pyridine",
    "SG450": "Thieno[3,4-b]pyridine",
    "SG451": "Thieno[2,3-c]pyrazole",
    "SG452": "Thieno[2,3-d]pyrimidine",
    "SG453": "Thieno[3,2-d]pyrimidine",
    "SG454": "Thieno[2,3-c]pyran",
    "SG455": "Thieno[3,2-b]pyran",
    "SG456": "Thieno[3,2-c]pyran",
    "SG457": "Thieno[3,4-b][1,4]dioxine",
    "SG458": "Thiochroman",
    "SG459": "Tetrahydrothiophene",
    "SG460": "Thioxanthene",
    "SG461": "1,2,3-Triazine",
    "SG462": "1,2,4-Triazine",
    "SG463": "1,3,5-Triazine",
    "SG464": "Triazine",
    "SG465": "1,2,3-Triazole",
    "SG466": "1,2,4-Triazole",
    "SG467": "[1,2,3]Triazolo[1,5-a]pyridine",
    "SG468": "[1,2,3]Triazolo[4,5-b]pyridine",
    "SG469": "[1,2,4]Triazolo[4,3-a]pyridine",
    "SG470": "[1,2,4]Triazolo[4,3-a]pyrazine",
    "SG471": "[1,2,4]Triazolo[4,3-b]pyridazine",
    "SG472": "[1,2,4]Triazolo[1,5-a]pyrimidine",
    "SG473": "[1,2,4]Triazolo[4,3-a]pyrimidine",
    "SG474": "Triphenylborane",
    "SG475": "Bicyclo[3.2.1]octane",
    "SG476": "Phenylboronic acid",
    "SG477": "Phenylboronic acid ester",
    "SG478": "Phenylboronic acid pinacol ester",
    "SG479": "Benzimidazolidine",
    "SG480": "Benzo[g]indazole",
    "SG481": "Benzoate",
    "SG482": "1,3-Benzodithiole",
    "SG483": "1,3-Benzothiazine",
    "SG484": "1,4-Benzothiazine",
    "SG485": "1,2-Benzoxazine",
    "SG486": "1,3-Benzoxazine",
    "SG487": "1,4-Benzoxazine",
    "SG488": "3,1-Benzoxazine",
    "SG489": "Biphenylene",
    "SG490": "Pyrido[2,3-b]indole",
    "SG491": "Pyrido[3,2-b]indole",
    "SG492": "Pyrido[3,4-b]indole",
    "SG493": "Pyrido[4,3-b]indole",
    "SG494": "Cyclohepta[c]pyrazole",
    "SG495": "Tropylium",
    "SG496": "Cyclohepta[b]quinoline",
    "SG497": "Imidazo[1,2-a][1,8]naphthyridine",
    "SG498": "Trifluoroacetophenone",
    "SG499": "Cyclopropene",
    "SG500": "Dioxaborirane",
    "SG501": "Silacyclobutane",
    "SG502": "Cyclopropylidene",
    "SG503": "1,3,2-Dioxaborolane",
    "SG504": "Cyclobutanone",
    "SG505": "2-Azetidinone",
    "SG506": "3-Azetidinone",
    "SG507": "1,2,3-Dithiazolium",
    "SG508": "2,5-Dihydro-1H-pyrrole",
    "SG509": "3,4-Dihydro-2H-pyrrole",
    "SG510": "2,3-Dihydropyran",
    "SG511": "2,5-Dihydropyran",
    "SG512": "1-Pyrrolinium",
    "SG513": "Methylenecyclobutane",
    "SG514": "Oxazoline",
    "SG515": "4,5-Dihydro-1,2-oxazole",
    "SG516": "1-Sila-3-cyclopentene",
    "SG517": "Silacyclopenatne",
    "SG518": "1-Aza-2,5-disilacyclopentane",
    "SG519": "1,2,5-Oxadisilolane",
    "SG520": "1,2-Thiasilolane",
    "SG521": "Azasilacyclopentane",
    "SG522": "3-Methylideneoxetane",
    "SG523": "3-Oxetanone",
    "SG524": "Pyrene",
    "SG525": "Chromone",
    "SG526": "Triphenylphosphine",
    "SG527": "Triphenylphosphonium",
    "SG528": "Triphenylphosphorane",
    "SG529": "Cycloheptane",
    "SG530": "Cyclobutenedione",
    "SG531": "Cycloheptatrienone",
    "SG532": "Indolium",
    "SG533": "Estrin",
    "SG534": "Hydrindane",
    "SG535": "Pregnane",
    "SG536": "1,1'-Binaphthalene",
    "SG537": "Spiro[2.5]octane",
    "SG538": "Spiro[3.5]nonane",
    "SG539": "Spiro[2.3]hexane",
    "SG540": "Spiro[3.3]heptane",
    "SG541": "Spirobiindane",
    "SG542": "Diphenylacetylene",
    "SG543": "Pentacene",
    "SG544": "Cyclododecane",
    "SG545": "Acenaphthylene",
    "SG546": "Acenaphthene"
};

export const conceptsMap: { [key: string]: string } = {
    "005": "Iodopyridine (Pyridyl Iodide)",
    "006": "Fluoropyridine (Pyridyl Fluoride)",
    "007": "Halopyridine",
    "CG001": "2-Iodobenzonitrile",
    "CG002": "1-Bromo-2-nitrobenzene",
    "CG003": "1-Bromo-4-nitrobenzene",
    "CG004": "1-Chloro-2-nitrobenzene",
    "CG005": "1-Chloro-4-nitrobenzene",
    "CG006": "1-Fluoro-2-nitrobenzene",
    "CG007": "1-Fluoro-4-nitrobenzene",
    "CG008": "2-Bromobenzonitrile",
    "CG009": "2-Bromopyridine",
    "CG010": "2-Bromopyrimidine",
    "CG011": "1-Halo-2-nitrobenzene",
    "CG012": "1-Halo-4-nitrobenzene",
    "CG013": "1-Iodo-2-nitrobenzene",
    "CG014": "1-Iodo-4-nitrobenzene",
    "CG015": "2-Chlorobenzonitrile",
    "CG016": "2-Chloropyridine",
    "CG017": "2-Chloropyrimidine",
    "CG018": "2-Fluorobenzonitrile",
    "CG019": "2-Fluoropyridine",
    "CG020": "2-Fluoropyrimidine",
    "CG021": "2-Halobenzonitrile",
    "CG022": "2-Halopyridine",
    "CG023": "2-Halopyrimidine",
    "CG024": "2-Iodobenzonitrile",
    "CG025": "2-Iodopyridine",
    "CG026": "2-Iodopyrimidine",
    "CG027": "4-Bromobenzonitrile",
    "CG028": "4-Bromopyridine",
    "CG029": "4-Bromopyrimidine",
    "CG030": "4-Chlorobenzonitrile",
    "CG031": "4-Chloropyridine",
    "CG032": "4-Chloropyrimidine",
    "CG033": "4-Fluorobenzonitrile",
    "CG034": "4-Fluoropyridine",
    "CG035": "4-Fluoropyrimidine",
    "CG037": "4-Halopyridine",
    "CG038": "4-Halopyrimidine",
    "CG039": "4-Iodobenzonitrile",
    "CG040": "4-Iodopyridine",
    "CG041": "4-Iodopyrimidine",
    "CG042": "PROTAC: Ligand | CRBN Analogs",
    "CG043": "PROTAC: Ligand | CRBN Building Block (Phthalic Anhydride)",
    "CG044": "PROTAC: Ligand | VHL Building Block 1(Thiazole Chain)",
    "CG045": "PROTAC: Ligand | VHL Building Block 2 (Hydroxy proline)",
    "CG046": "PROTAC Ligand | VHL Building Block 3 (Amino Chain)",
    "CG047": "PROTAC: Ligand | VHL Building Block (Thio amino chain)",
    "CG048": "PROTAC Linker | Alkyne",
    "CG049": "PROTAC: Linker | Aminophenol",
    "CG050": "PROTAC: Linker | Piperazine - Cyclic",
    "CG051": "PROTAC: Linker | Piperazine - Di functionalised",
    "CG052": "PROTAC: Linker | Piperazine - Mono functionalised",
    "CG053": "PROTAC: Linker | Piperazine - PEG",
    "CG054": "PROTAC: Linker | Pyrazole",
    "FG001": "Alkyne",
    "FG003": "Phenyl",
    "FG004": "Aliphatic alcohol",
    "FG005": "Ketone",
    "FG006": "Aldehyde",
    "FG007": "Acyl halide",
    "FG008": "Carbonate ester",
    "FG009": "Carboxylate",
    "FG010": "Carboxylic acid",
    "FG011": "Ester",
    "FG012": "Methoxy",
    "FG013": "Terminal alkyne",
    "FG014": "Hydroperoxide",
    "FG015": "Peroxide",
    "FG016": "Ether",
    "FG017": "Hemiacetal",
    "FG018": "Hemiketal",
    "FG019": "Acetal",
    "FG020": "Ketal",
    "FG021": "Orthoester",
    "FG022": "Heterocycle",
    "FG023": "Heteroaromatic",
    "FG024": "Amide",
    "FG025": "Primary amine",
    "FG026": "Secondary amine",
    "FG027": "Tertiary amine",
    "FG028": "Amine (P+S+T)",
    "FG029": "Primary ketimine",
    "FG030": "Secondary ketimine",
    "FG031": "Primary aldimine",
    "FG032": "Secondary aldimine",
    "FG033": "Imide",
    "FG034": "Azide",
    "FG035": "Azo",
    "FG036": "Cyanate",
    "FG037": "Isocyanate",
    "FG038": "Nitrate",
    "FG039": "Nitrile",
    "FG040": "Isonitrile",
    "FG041": "Nitrite",
    "FG042": "Nitro",
    "FG043": "Aldoxime",
    "FG044": "Ketoxime",
    "FG045": "Oxime",
    "FG046": "Aliphatic thiol",
    "FG047": "Sulfide",
    "FG048": "Disulfide",
    "FG049": "Sulfoxide",
    "FG050": "Sulfone",
    "FG051": "Sulfinic acid",
    "FG052": "Sulfonic acid",
    "FG053": "Sulfonyl chloride",
    "FG054": "Sulfonyl fluoride",
    "FG055": "Thiocyanate",
    "FG056": "Isothiocyanate",
    "FG057": "Thione",
    "FG058": "Thial",
    "FG059": "Thioester",
    "FG060": "Phosphine",
    "FG061": "Phosphonic acid",
    "FG062": "Phosphate",
    "FG063": "Phosphodiester",
    "FG064": "Phosphonate",
    "FG065": "Boronic acid",
    "FG066": "Boronic ester",
    "FG067": "Borinic acid",
    "FG068": "Borinic ester",
    "FG069": "Amino acid",
    "FG070": "Terminal alkyne (aliphatic hydrocarbon)",
    "FG071": "Terminal alkyne (aromatic hydrocarbon)",
    "FG072": "Terminal alkyne (alcohol)",
    "FG073": "Terminal alkyne (carboxylic acid)",
    "FG074": "Terminal alkyne (ester)",
    "FG075": "Terminal alkyne (amine)",
    "FG076": "Terminal alkyne (heteroaromatic)",
    "FG077": "Alpha-amino acid",
    "FG078": "Isocyanide",
    "FG079": "Fluoro",
    "FG080": "Chloro",
    "FG081": "Bromo",
    "FG082": "Iodo",
    "FG083": "Halo",
    "FG084": "Pinacol boronate",
    "FG085": "Dialdehyde",
    "FG086": "Diamine",
    "FG087": "Stannyl",
    "FG088": "Distannyl",
    "FG089": "Chiral",
    "FG090": "Sulfonamide",
    "FG091": "Sulfamide",
    "FG092": "Methyl",
    "FG093": "N-methyl",
    "FG094": "Ethyl",
    "FG095": "Dimethyl",
    "FG096": "Aromatic alcohol",
    "FG097": "Aromatic thiol",
    "FG098": "NBenzyl",
    "FG099": "NFmoc",
    "FG100": "NBOC",
    "FG101": "Trifluoromethyl",
    "FG102": "SF5",
    "FG103": "SF3",
    "FG104": "Lactam",
    "FG105": "Lactone",
    "FG106": "Acid anhydride",
    "FG107": "Dicarbonate",
    "FG108": "Tolyl",
    "FG109": "Alkene",
    "FG110": "Enone",
    "FG111": "Malonate",
    "FG112": "Beta-amino acid",
    "FG113": "Ynone",
    "FG114": "Ynoate",
    "FG115": "Ynoic acid",
    "FG116": "Ynamide",
    "FG117": "Enyne",
    "FG118": "Metallic",
    "FG119": "Sulfonate",
    "FG120": "Diazo",
    "FG121": "Azoxy",
    "FG122": "Organosilicon",
    "FG123": "NCbz",
    "FG124": "Ammonium",
    "FG125": "Urea",
    "FG126": "Trifluoromethoxy",
    "FG127": "Difluoromethyl",
    "FG128": "Difluoromethoxy",
    "FG129": "Carbamate",
    "FG130": "Chloroformate",
    "FG131": "Chlorosulfate",
    "FG132": "Diazonium",
    "FG133": "Hydrobromide",
    "FG134": "Hydrochloride",
    "FG135": "Hydrofluoride",
    "FG136": "Hydrate",
    "FG137": "Hydrazone",
    "FG138": "Guanidine",
    "FG139": "Hydrazine",
    "FG140": "Hydroxylamine",
    "FG141": "Fluoronium",
    "FG142": "Chloronium",
    "FG143": "Bromonium",
    "FG144": "Iodonium",
    "FG145": "Halonium",
    "FG146": "N-oxide",
    "FG147": "Nitrosonium",
    "FG148": "Carbazide",
    "FG149": "Semicarbazide",
    "FG150": "Carbazone",
    "FG151": "Semicarbazone",
    "FG152": "Sulfanide",
    "FG153": "Sulfate",
    "FG154": "Sulfinate",
    "FG155": "Sulfonic anhydride",
    "FG156": "Sulfonium",
    "FG157": "Thioamide",
    "FG158": "O-thiocarbamate",
    "FG159": "S-thiocarbamate",
    "FG160": "Thiolate",
    "FG161": "Thiosemicarbazide",
    "FG162": "Thiourea",
    "FG163": "Uronium",
    "FG164": "Acyl fluoride",
    "FG165": "Acyl chloride",
    "FG166": "Acyl bromide",
    "FG167": "Acyl iodide",
    "FG168": "Difluoroborane",
    "FG169": "Difluoromethoxy",
    "FG170": "Difluoromethylsulfone",
    "FG171": "Difluoromethylsulfide",
    "FG172": "Acetylacetonate",
    "FG173": "Amidine",
    "FG174": "Amino acid ester",
    "FG175": "Ammonia",
    "FG176": "Biguanidine",
    "FG177": "Tetrafluoroborate",
    "FG178": "Sulfur hexafluoride",
    "FG179": "Triflate",
    "FG180": "Triflic acid",
    "FG181": "Trifluoroacetate",
    "FG182": "Trifluoroborane",
    "FG183": "Trifluoroborate",
    "FG184": "Trifluoromethyl sulfone",
    "FG185": "Carboximidate",
    "FG186": "Acetimidate",
    "FG187": "Borate ester",
    "FG188": "Nitronium",
    "FG189": "Lanthanide (Inorganic)",
    "FG190": "Actinide (Inorganic)",
    "FG191": "Alkali metal (Inorganic)",
    "FG192": "Alkaline earth metal (Inorganic)",
    "FG193": "Halogen (Inorganic)",
    "FG194": "Metalloid (Inorganic)",
    "FG195": "Noble gas (Inorganic)",
    "FG196": "Other non-metal (Inorganic)",
    "FG197": "Post-transition metal (Inorganic)",
    "FG198": "Transition metal (Inorganic)",
    "FG199": "Organoaluminium",
    "FG200": "Organocopper",
    "FG201": "Organogermanium",
    "FG202": "Organolead",
    "FG203": "Organolithium",
    "FG204": "Organomercury",
    "FG205": "Organotin",
    "FG206": "Organozinc",
    "FG207": "Grignard reagent",
    "FG208": "Metallocene",
    "FG209": "Trialkoxysilane",
    "FG210": "Tributyltin",
    "FG211": "Alkane",
    "FG212": "Sulfinamide",
    "FG213": "Phosphite ester",
    "FG214": "Diselenide",
    "FG215": "Phosphonium",
    "FG216": "Phosphate ester",
    "FG217": "Phosphorothioic triamide",
    "FG218": "Carbodiimide",
    "FG219": "N-hydroxy imidamide",
    "FG220": "Sulfite ester",
    "FG221": "Phosphoramide",
    "FG222": "Phosphorous triamide",
    "FG223": "Trisulfide",
    "FG224": "Tetrasulfide",
    "FG225": "Dithiobiurea",
    "FG226": "Carbothioic O-acid",
    "FG227": "Carbothioic S-acid",
    "FG228": "Biurea",
    "FG229": "Cyanamide",
    "FG230": "Sulfoxonium",
    "FG231": "Biuret",
    "FG232": "Dicyanamine",
    "FG233": "Boranetriamine",
    "FG234": "C-nitroso",
    "FG235": "N-nitroso",
    "FG236": "O-nitroso",
    "FG237": "S-nitroso",
    "FG238": "N-nitro",
    "FG239": "Chloride",
    "FG240": "Bromide",
    "FG241": "Amidoxime",
    "FG242": "Phosphine oxide",
    "FG243": "Nitrosamine",
    "FG244": "Hydrazide",
    "FG245": "Thiosemicarbazide",
    "FG246": "Carbonyl thiosemicarbazide",
    "FG247": "Cabonyl thiourea",
    "FG248": "Carbonic acid",
    "FG249": "Dithiocarbamate",
    "FG250": "Hexafluorophosphate",
    "FG251": "Diaminophosphinate",
    "FG252": "Diaminophosphinic acid",
    "FG253": "Iminium",
    "FG254": "Hydrazine carboxylate",
    "FG255": "Hydrazine-1,2-dicarboxylate",
    "FG256": "Hydrazine-1,1,2-tricarboxylate",
    "FG257": "Borane",
    "FG258": "Tetracyanoborate",
    "FG259": "Tetraphenylborate",
    "FG260": "Imidic acid ester",
    "FG261": "NAc",
    "FG262": "NAlloc",
    "FG263": "NMs",
    "FG264": "NNs",
    "FG265": "NTr",
    "FG266": "NTs",
    "FG267": "SuFeX: Sulfonyl Fluoride (RSO2F, R = C)",
    "FG268": "O-Benzyl",
    "FG269": "O-Tr",
    "SG001": "1,2,3,4-Oxatriazole",
    "SG002": "1,2,3,4-Tetrabromobenzene",
    "SG003": "1,2,3,4-Tetrachlorobenzene",
    "SG004": "1,2,3,4-Tetrafluorobenzene",
    "SG005": "1,2,3,4-Tetraiodobenzene",
    "SG006": "1,2,3,5-Tetrabromobenzene",
    "SG007": "1,2,3,5-Tetrachlorobenzene",
    "SG008": "1,2,3,5-Tetrafluorobenzene",
    "SG009": "1,2,3,5-Tetraiodobenzene",
    "SG010": "1,2,3-Oxadiazole",
    "SG011": "1,2,3-Tribromobenzene",
    "SG012": "1,2,3-Trichlorobenzene",
    "SG013": "1,2,3-Trifluorobenzene",
    "SG014": "1,2,3-Triiodobenzene",
    "SG015": "1,2,4,5-Tetrabromobenzene",
    "SG016": "1,2,4,5-Tetrachlorobenzene",
    "SG017": "1,2,4,5-Tetrafluorobenzene",
    "SG018": "1,2,4,5-Tetraiodobenzene",
    "SG019": "1,2,4-Oxadiazole",
    "SG020": "1,2,4-Tribromobenzene",
    "SG021": "1,2,4-Trichlorobenzene",
    "SG022": "1,2,4-Trifluorobenzene",
    "SG023": "1,2,4-Triiodobenzene",
    "SG024": "1,2-Dibromobenzene",
    "SG025": "1,2-Dichlorobenzene",
    "SG026": "1,2-Difluorobenzene",
    "SG027": "1,2-Dihydroazete",
    "SG028": "1,2-Diiodobenzene",
    "SG029": "1,3,4-Oxadiazole",
    "SG030": "1,3,5-Tribromobenzene",
    "SG031": "1,3,5-Trichlorobenzene",
    "SG032": "1,3,5-Trifluorobenzene",
    "SG033": "1,3,5-Triiodobenzene",
    "SG034": "1,3-Dibromobenzene",
    "SG035": "1,3-Dichlorobenzene",
    "SG036": "1,3-Difluorobenzene",
    "SG037": "1,3-Diiodobenzene",
    "SG038": "1,4-Dibromobenzene",
    "SG039": "1,4-Dichlorobenzene",
    "SG040": "1,4-Difluorobenzene",
    "SG041": "1,4-Diiodobenzene",
    "SG042": "1,4-Dithiane",
    "SG043": "2H-Thiete",
    "SG044": "2-Pyrazoline",
    "SG045": "2-Pyrroline",
    "SG046": "3,4-Dihydro-2H-pyran",
    "SG047": "3-Membered heteroaromatic",
    "SG048": "3-Membered heterocycle",
    "SG049": "3-Membered heterocyclic sulfonyl chloride",
    "SG050": "4-Membered heteroaromatic",
    "SG051": "4-Membered heterocycle",
    "SG052": "4-Membered heterocyclic sulfonyl chloride",
    "SG053": "5-Membered heteroaromatic",
    "SG054": "5-Membered heterocycle",
    "SG055": "5-Membered heterocyclic sulfonyl chloride",
    "SG056": "6-Membered heteroaromatic",
    "SG057": "6-Membered heterocycle",
    "SG058": "6-Membered heterocyclic sulfonyl chloride",
    "SG059": "Purine",
    "SG060": "7-Membered heteroaromatic",
    "SG061": "7-Membered heterocycle",
    "SG062": "7-Membered heterocyclic sulfonyl chloride",
    "SG063": "Carbazole",
    "SG064": "Atezidine",
    "SG065": "Azepane",
    "SG066": "Aziridine",
    "SG067": "Benzimidazole",
    "SG068": "Benzofuran",
    "SG069": "Benzothiazole",
    "SG070": "Benzothiophene",
    "SG071": "Benzotriazole",
    "SG072": "Benzoxazole",
    "SG073": "Chiral alcohol",
    "SG074": "Chiral amide",
    "SG075": "Chiral amine",
    "SG076": "Chiral amino alcohol (all)",
    "SG077": "Chiral amino alcohol (both chiral)",
    "SG078": "Chiral amino alcohol (chiral alcohol)",
    "SG079": "Chiral amino alcohol (chiral amino)",
    "SG080": "Chiral carboxylic acid",
    "SG081": "Chiral ester",
    "SG082": "Chiral isocyanate",
    "SG083": "Cinnoline",
    "SG084": "Cyclobutane",
    "SG085": "Cyclobutene",
    "SG086": "Dibenzofuran",
    "SG087": "Dibenzothiophene",
    "SG088": "Dibromobenzene",
    "SG089": "Dibromomethyl/dibromomethoxy benzene",
    "SG090": "Dichlorobenzene",
    "SG091": "Dichloromethyl/dichloromethoxy benzene",
    "SG092": "Difluorobenzene",
    "SG093": "Difluoromethyl/difluoromethoxy benzene",
    "SG094": "Diiodobenzene",
    "SG095": "Diiodomethyl/diiodomethoxy benzene",
    "SG096": "Disubstituted (2,3)- monobromobenzene",
    "SG097": "Disubstituted (2,3)- monochlorobenzene",
    "SG098": "Disubstituted (2,3)- monofluorobenzene",
    "SG099": "Disubstituted (2,3)- monoiodobenzene",
    "SG100": "Disubstituted (2,4)- monobromobenzene",
    "SG101": "Disubstituted (2,4)- monochlorobenzene",
    "SG102": "Disubstituted (2,4)- monofluorobenzene",
    "SG103": "Disubstituted (2,4)- monoiodobenzene",
    "SG104": "Disubstituted (2,5)- monobromobenzene",
    "SG105": "Disubstituted (2,5)- monochlorobenzene",
    "SG106": "Disubstituted (2,5)- monofluorobenzene",
    "SG107": "Disubstituted (2,5)- monoiodobenzene",
    "SG108": "Disubstituted (2,6)- monobromobenzene",
    "SG109": "Disubstituted (2,6)- monochlorobenzene",
    "SG110": "Disubstituted (2,6)- monofluorobenzene",
    "SG111": "Disubstituted (2,6)- monoiodobenzene",
    "SG112": "Disubstituted (3,4)- monobromobenzene",
    "SG113": "Disubstituted (3,4)- monochlorobenzene",
    "SG114": "Disubstituted (3,4)- monofluorobenzene",
    "SG115": "Disubstituted (3,4)- monoiodobenzene",
    "SG116": "Disubstituted (3,5)- monobromobenzene",
    "SG117": "Disubstituted (3,5)- monochlorobenzene",
    "SG118": "Disubstituted (3,5)- monofluorobenzene",
    "SG119": "Disubstituted (3,5)- monoiodobenzene",
    "SG120": "Furan",
    "SG121": "Hexabromobenzene",
    "SG122": "Hexachlorobenzene",
    "SG123": "Hexafluorobenzene",
    "SG124": "Hexaiodobenzene",
    "SG125": "Imidazole",
    "SG126": "Imidazolidine",
    "SG127": "Indazole",
    "SG128": "Indole",
    "SG129": "Indolizine",
    "SG130": "Isoquinoline",
    "SG131": "Isothiazole",
    "SG132": "Isoxazole",
    "SG133": "Monobromobenzene",
    "SG134": "Monochlorobenzene",
    "SG135": "Monofluorobenzene",
    "SG136": "Monoiodobenzenes",
    "SG137": "Monosubstituted (meta) monobromobenzene",
    "SG138": "Monosubstituted (meta) monochlorobenzene",
    "SG139": "Monosubstituted (meta) monofluorobenzene",
    "SG140": "Monosubstituted (meta) monoiodobenzene",
    "SG141": "Monosubstituted (meta) tribromomethylbenzene",
    "SG142": "Monosubstituted (meta) trichloromethylbenzene",
    "SG143": "Monosubstituted (meta) trifluoromethylbenzene",
    "SG144": "Monosubstituted (meta) triiodomethylbenzene",
    "SG145": "Monosubstituted (ortho) monobromobenzene",
    "SG146": "Monosubstituted (ortho) monochlorobenzene",
    "SG147": "Monosubstituted (ortho) monofluorobenzene",
    "SG148": "Monosubstituted (ortho) monoiodobenzene",
    "SG149": "Monosubstituted (ortho) tribromomethylbenzene",
    "SG150": "Monosubstituted (ortho) trichloromethylbenzene",
    "SG151": "Monosubstituted (ortho) trifluoromethylbenzene",
    "SG152": "Monosubstituted (ortho) triiodomethylbenzene",
    "SG153": "Monosubstituted (para) monobromobenzene",
    "SG154": "Monosubstituted (para) monochlorobenzene",
    "SG155": "Monosubstituted (para) monofluorobenzene",
    "SG156": "Monosubstituted (para) monoiodobenzene",
    "SG157": "Monosubstituted (para) tribromomethylbenzene",
    "SG158": "Monosubstituted (para) trichloromethylbenzene",
    "SG159": "Monosubstituted (para) trifluoromethylbenzene",
    "SG160": "Monosubstituted (para) triiodomethylbenzene",
    "SG161": "Morpholine",
    "SG162": "Oxazole",
    "SG163": "Oxetane",
    "SG164": "Oxirane",
    "SG165": "Pentabromobenzene",
    "SG166": "Pentabromosulfanyl benzene",
    "SG167": "Pentachlorobenzene",
    "SG168": "Pentachlorosulfanyl benzene",
    "SG169": "Pentafluorobenzene",
    "SG170": "Pentafluorosulfanyl benzene",
    "SG171": "Pentaiodobenzene",
    "SG172": "Pentaiodosulfanyl benzene",
    "SG173": "Phthalazine",
    "SG174": "Piperazine",
    "SG175": "Piperidine",
    "SG176": "Pteridine",
    "SG177": "Pyrazine",
    "SG178": "Pyrazole",
    "SG179": "Pyrazolidine",
    "SG180": "Pyridazine",
    "SG181": "Pyridine",
    "SG182": "Pyrido[2,3-b]pyrazine",
    "SG183": "Pyrido[3,4-d]pyridazine",
    "SG184": "Pyrimidine",
    "SG185": "Pyrrole",
    "SG186": "Pyrrolidine",
    "SG187": "Pyrrolo[1,2-a]pyrazine",
    "SG188": "Pyrrolo[1,2-b]pyridazine",
    "SG189": "Quinazoline",
    "SG190": "Quinoline",
    "SG191": "Quinoxaline",
    "SG195": "Tetrahydro-2H-pyran",
    "SG196": "Tetrahydrofuran",
    "SG198": "Tetrazole",
    "SG199": "Thiazole",
    "SG200": "Thiepane",
    "SG201": "Thietane",
    "SG202": "Thiirane",
    "SG203": "Thiophene",
    "SG204": "Tribromobenzenes",
    "SG205": "Tribromomethoxy benzene",
    "SG206": "Tribromomethylthio benzene",
    "SG207": "Trichlorobenzene",
    "SG208": "Trichloromethoxy benzene",
    "SG209": "Trichloromethylthio benzene",
    "SG210": "Trifluorobenzene",
    "SG211": "Trifluoromethoxy benzene",
    "SG212": "Trifluoromethylthio benzene",
    "SG213": "Triiodobenzene",
    "SG214": "Triiodomethoxy benzene",
    "SG215": "Triiodomethylthio benzene",
    "SG216": "Trisubstituted (2,3,4)- monobromobenzene",
    "SG217": "Trisubstituted (2,3,4)- monochlorobenzene",
    "SG218": "Trisubstituted (2,3,4)- monofluorobenzene",
    "SG219": "Trisubstituted (2,3,4)- monoiodobenzene",
    "SG220": "Trisubstituted (2,3,5)- monobromobenzene",
    "SG221": "Trisubstituted (2,3,5)- monochlorobenzene",
    "SG222": "Trisubstituted (2,3,5)- monofluorobenzene",
    "SG223": "Trisubstituted (2,3,5)- monoiodobenzene",
    "SG224": "Trisubstituted (2,4,5)- monobromobenzene",
    "SG225": "Trisubstituted (2,4,5)- monochlorobenzene",
    "SG226": "Trisubstituted (2,4,5)- monofluorobenzene",
    "SG227": "Trisubstituted (2,4,5)- monoiodobenzene",
    "SG228": "Trisubstituted (3,4,5)- monobromobenzene",
    "SG229": "Trisubstituted (3,4,5)- monochlorobenzene",
    "SG230": "Trisubstituted (3,4,5)- monofluorobenzene",
    "SG231": "Trisubstituted (3,4,5)- monoiodobenzene",
    "SG232": "Bicyclo[1.1.1]pentane",
    "SG233": "Bicyclo[3.1.1]heptane",
    "SG234": "Bicyclo[3.1.0]hexane",
    "SG235": "Bicyclo[2.2.2]octane",
    "SG236": "Bicyclo[2.2.1]heptane",
    "SG237": "Bicyclo[3.2.2]nonane",
    "SG238": "Bicyclo[2.1.1]hexane",
    "SG239": "Cubane",
    "SG240": "Adamantane",
    "SG241": "Noradamantane",
    "SG242": "Spirocycle",
    "SG243": "Chromane",
    "SG244": "Indane",
    "SG245": "Naphthalene",
    "SG246": "Diaminocyclohexane",
    "SG247": "1,3,2-dioxaborinane",
    "SG248": "Aniline",
    "SG249": "Cyclopentane",
    "SG250": "Cyclohexane",
    "SG251": "Phosphoramidite",
    "SG252": "Nitrobenzene",
    "SG253": "Phenol",
    "SG254": "Benzyl fluoride",
    "SG255": "Cyclic",
    "SG256": "Aromatic",
    "SG257": "PEG-2",
    "SG258": "PEG-4",
    "SG259": "Benzanilide",
    "SG260": "Anthraquinone",
    "SG261": "Tetralin",
    "SG262": "Cinnamic acid",
    "SG263": "Azetidine",
    "SG264": "Acetophenone",
    "SG265": "Anisole",
    "SG266": "Anthracene",
    "SG267": "Benzaldehyde",
    "SG268": "Benzhydryl",
    "SG269": "Benzhydrol",
    "SG270": "Benzisoxazole",
    "SG271": "Benzisothiazole",
    "SG272": "Xanthene",
    "SG273": "Benzoyl halide",
    "SG274": "Benzoic acid",
    "SG275": "1,2-Diazepine",
    "SG276": "1,3-Diazepine",
    "SG277": "1,4-Diazepine",
    "SG278": "Diazepine",
    "SG279": "1,4-Benzodiazepine",
    "SG280": "1,3-Benzodiazepine",
    "SG281": "1,2-Benzodiazepine",
    "SG282": "Benzodiazepine",
    "SG283": "1,2-Dioxane",
    "SG284": "1,3-Dioxane",
    "SG285": "1,4-Dioxane",
    "SG286": "Dioxane",
    "SG287": "1,2-Benzodioxane",
    "SG288": "1,3-Benzodioxane",
    "SG289": "1,4-Benzodioxane",
    "SG290": "Benzodioxane",
    "SG291": "1,4-Benzodioxine",
    "SG292": "1,3-Benzodioxole",
    "SG293": "Isobenzofuran",
    "SG294": "Benzonitrile",
    "SG295": "Benzophenone",
    "SG296": "benzothiadiazine",
    "SG297": "1,2,3-Benzothiadiazole",
    "SG298": "2,1,3-Benzothiadiazole",
    "SG299": "Benzothiadiazole",
    "SG300": "2H-1,2-Oxazine",
    "SG301": "4H-1,2-Oxazine",
    "SG302": "6H-1,2-Oxazine",
    "SG303": "1,2-Oxazine",
    "SG304": "2H-1,3-Oxazine",
    "SG305": "4H-1,3-Oxazine",
    "SG306": "6H-1,3-Oxazine",
    "SG307": "1,3-Oxazine",
    "SG312": "1,2-Oxazinane",
    "SG313": "1,3-Oxazinane",
    "SG314": "1,4-Oxazinane",
    "SG315": "Oxazinane",
    "SG316": "Benzoyl chloride",
    "SG317": "Benzyl chloride",
    "SG318": "Benzoyl fluoride",
    "SG319": "Benzoyl bromide",
    "SG320": "Benzyl bromide",
    "SG321": "Benzyl alcohol",
    "SG322": "Biphenyl",
    "SG323": "2H-Chromene",
    "SG324": "4H-Chromene",
    "SG325": "Chromene",
    "SG326": "Coumarin",
    "SG327": "Crown ether",
    "SG328": "Cycloheptatriene",
    "SG329": "Cyclohexa-1,3-diene",
    "SG330": "Cyclohexa-1,4-diene",
    "SG331": "Cyclohexadiene",
    "SG332": "Cyclohexene",
    "SG333": "Cyclooctane",
    "SG334": "1,2-Cyclooctadiene",
    "SG335": "1,3-Cyclooctadiene",
    "SG336": "1,4-Cyclooctadiene",
    "SG337": "1,5-Cyclooctadiene",
    "SG338": "Cyclooctadiene",
    "SG339": "Cyclopentadiene",
    "SG340": "Cyclopentene",
    "SG341": "Cyclopropane",
    "SG342": "Cyclotetrasiloxane",
    "SG343": "Cyclotrisiloxane",
    "SG344": "Decalin",
    "SG345": "1,2-Diazepane",
    "SG346": "1,3-Diazepane",
    "SG347": "1,4-Diazepane",
    "SG348": "Diazepane",
    "SG349": "Diazirine",
    "SG350": "Diaziridine",
    "SG351": "1,2-Dioxolane",
    "SG352": "1,3-Dioxolane",
    "SG353": "Dioxolane",
    "SG354": "1,3-Dioxole",
    "SG355": "3H-Dioxole",
    "SG356": "Diphenyl ether",
    "SG357": "1,2-Dithietane",
    "SG358": "1,3-Dithietane",
    "SG359": "Dithietane",
    "SG360": "1,2-Oxathietane",
    "SG361": "1,3-Oxathietane",
    "SG362": "Oxathietane",
    "SG363": "Fluorene",
    "SG364": "Furo[2,3-b]pyridine",
    "SG365": "2-Imidazoline",
    "SG366": "3-Imidazoline",
    "SG367": "4-Imidazoline",
    "SG368": "Imidazoline",
    "SG369": "Indene",
    "SG370": "Indoline",
    "SG371": "Benziodoxole",
    "SG372": "Isatin",
    "SG373": "Isatoic anhydride",
    "SG374": "Isoindoline",
    "SG375": "Phthalimide",
    "SG376": "Isophthalic acid",
    "SG377": "Naphtho[1,2-b]thiophene",
    "SG378": "Naphtho[1,2-c]isoxazole",
    "SG379": "4,5-Dihydro-naphtho[1,2-c]isoxazole",
    "SG380": "Naphtho[1,2-d]isoxazole",
    "SG381": "1,5-Naphthyridine",
    "SG382": "1,6-Naphthyridine",
    "SG383": "1,7-Naphthyridine",
    "SG384": "1,8-Naphthyridine",
    "SG385": "2,6-Naphthyridine",
    "SG386": "2,7-Naphthyridine",
    "SG387": "Naphthyridine",
    "SG388": "1,2,5-Oxadiazole",
    "SG389": "Oxadiazole",
    "SG390": "Oxazolidine",
    "SG391": "Isoxazolidine",
    "SG392": "Oxindole",
    "SG393": "Phenacyl bromide",
    "SG394": "Propiophenone",
    "SG395": "Phenanthrene",
    "SG396": "2H-1,2-Thiazine",
    "SG397": "4H-1,2-Thiazine",
    "SG398": "2H-1,3-Thiazine",
    "SG399": "4H-1,3-Thiazine",
    "SG400": "6H-1,3-Thiazine",
    "SG401": "2H-1,4-Thiazine",
    "SG402": "4H-1,4-Thiazine",
    "SG403": "Phenothiazine",
    "SG404": "Thiomorpholine",
    "SG405": "Phenylacetylene",
    "SG406": "Cyclotriphosphazene",
    "SG407": "Phthalic acid",
    "SG408": "Phthalic anhydride",
    "SG409": "Phthalide",
    "SG410": "Pyrylium",
    "SG411": "2H-Pyran",
    "SG412": "4H-pyran",
    "SG413": "Pyran",
    "SG414": "Pyrazolo[3,4-c]pyrazole",
    "SG415": "Pyrazolo[1,5-a]pyrimidine",
    "SG416": "Pyrazolo[1,5-a]pyridine",
    "SG417": "Pyrazolo[3,4-b]pyridine",
    "SG418": "Pyrazolo[3,4-c]pyridine",
    "SG419": "Pyrazolo[4,3-c]pyridine",
    "SG420": "Pyrido[3,2-d]pyrimidine",
    "SG421": "Pyrido[4,3-d]pyrimidine",
    "SG422": "Pyrrolo[3,2-c]pyrazole",
    "SG423": "Pyrrolo[3,4-c]pyrazole",
    "SG424": "Pyrrolo[2,3-b]pyridine",
    "SG425": "Pyrrolo[3,2-b]pyridine",
    "SG426": "Pyrrolo[2,3-c]pyridine",
    "SG427": "Pyrrolo[3,2-c]pyridine",
    "SG428": "Pyrrolo[3,4-c]pyridine",
    "SG429": "Pyrrolo[1,2-a]pyrimidine",
    "SG430": "Pyrrolo[1,2-c]pyrimdine",
    "SG431": "Pyrrolo[2,3-d]pyrimidine",
    "SG432": "Pyrrolo[3,2-d]pyrimidine",
    "SG433": "Styrene",
    "SG434": "Terephthalic acid",
    "SG435": "Tetraphenyl borate",
    "SG436": "1,2,3-Thiadiazole",
    "SG437": "1,2,4-Thiadiazole",
    "SG438": "1,2,5-Thiadiazole",
    "SG439": "1,3,4-Thiadiazole",
    "SG440": "Thiadiazole",
    "SG441": "2-Thiazoline",
    "SG442": "3-Thiazoline",
    "SG443": "4-Thiazoline",
    "SG444": "Thiazoline",
    "SG445": "Thiazolane",
    "SG446": "Thieno[2,3-b]pyridine",
    "SG447": "Thieno[2,3-c]pyridine",
    "SG448": "Thieno[3,2-b]pyridine",
    "SG449": "Thieno[3,2-c]pyridine",
    "SG450": "Thieno[3,4-b]pyridine",
    "SG451": "Thieno[2,3-c]pyrazole",
    "SG452": "Thieno[2,3-d]pyrimidine",
    "SG453": "Thieno[3,2-d]pyrimidine",
    "SG454": "Thieno[2,3-c]pyran",
    "SG455": "Thieno[3,2-b]pyran",
    "SG456": "Thieno[3,2-c]pyran",
    "SG457": "Thieno[3,4-b][1,4]dioxine",
    "SG458": "Thiochroman",
    "SG459": "Tetrahydrothiophene",
    "SG460": "Thioxanthene",
    "SG461": "1,2,3-Triazine",
    "SG462": "1,2,4-Triazine",
    "SG463": "1,3,5-Triazine",
    "SG464": "Triazine",
    "SG465": "1,2,3-Triazole",
    "SG466": "1,2,4-Triazole",
    "SG467": "[1,2,3]Triazolo[1,5-a]pyridine",
    "SG468": "[1,2,3]Triazolo[4,5-b]pyridine",
    "SG469": "[1,2,4]Triazolo[4,3-a]pyridine",
    "SG470": "[1,2,4]Triazolo[4,3-a]pyrazine",
    "SG471": "[1,2,4]Triazolo[4,3-b]pyridazine",
    "SG472": "[1,2,4]Triazolo[1,5-a]pyrimidine",
    "SG473": "[1,2,4]Triazolo[4,3-a]pyrimidine",
    "SG474": "Triphenylborane",
    "SG475": "Bicyclo[3.2.1]octane",
    "SG476": "Phenylboronic acid",
    "SG477": "Phenylboronic acid ester",
    "SG478": "Phenylboronic acid pinacol ester",
    "SG479": "Benzimidazolidine",
    "SG480": "Benzo[g]indazole",
    "SG481": "Benzoate",
    "SG482": "1,3-Benzodithiole",
    "SG483": "1,3-Benzothiazine",
    "SG484": "1,4-Benzothiazine",
    "SG485": "1,2-Benzoxazine",
    "SG486": "1,3-Benzoxazine",
    "SG487": "1,4-Benzoxazine",
    "SG488": "3,1-Benzoxazine",
    "SG489": "Biphenylene",
    "SG490": "Pyrido[2,3-b]indole",
    "SG491": "Pyrido[3,2-b]indole",
    "SG492": "Pyrido[3,4-b]indole",
    "SG493": "Pyrido[4,3-b]indole",
    "SG494": "Cyclohepta[c]pyrazole",
    "SG495": "Tropylium",
    "SG496": "Cyclohepta[b]quinoline",
    "SG497": "Imidazo[1,2-a][1,8]naphthyridine",
    "SG498": "Trifluoroacetophenone",
    "SG499": "Cyclopropene",
    "SG500": "Dioxaborirane",
    "SG501": "Silacyclobutane",
    "SG502": "Cyclopropylidene",
    "SG503": "1,3,2-Dioxaborolane",
    "SG504": "Cyclobutanone",
    "SG505": "2-Azetidinone",
    "SG506": "3-Azetidinone",
    "SG507": "1,2,3-Dithiazolium",
    "SG508": "2,5-Dihydro-1H-pyrrole",
    "SG509": "3,4-Dihydro-2H-pyrrole",
    "SG510": "2,3-Dihydropyran",
    "SG511": "2,5-Dihydropyran",
    "SG512": "1-Pyrrolinium",
    "SG513": "Methylenecyclobutane",
    "SG514": "Oxazoline",
    "SG515": "4,5-Dihydro-1,2-oxazole",
    "SG516": "1-Sila-3-cyclopentene",
    "SG517": "Silacyclopenatne",
    "SG518": "1-Aza-2,5-disilacyclopentane",
    "SG519": "1,2,5-Oxadisilolane",
    "SG520": "1,2-Thiasilolane",
    "SG521": "Azasilacyclopentane",
    "SG522": "3-Methylideneoxetane",
    "SG523": "3-Oxetanone",
    "SG524": "Pyrene",
    "SG525": "Chromone",
    "SG526": "Triphenylphosphine",
    "SG527": "Triphenylphosphonium",
    "SG528": "Triphenylphosphorane",
    "SG529": "Cycloheptane",
    "SG530": "Cyclobutenedione",
    "SG531": "Cycloheptatrienone",
    "SG532": "Indolium",
    "SG533": "Estrin",
    "SG534": "Hydrindane",
    "SG535": "Pregnane",
    "SG536": "1,1'-Binaphthalene",
    "SG537": "Spiro[2.5]octane",
    "SG538": "Spiro[3.5]nonane",
    "SG539": "Spiro[2.3]hexane",
    "SG540": "Spiro[3.3]heptane",
    "SG541": "Spirobiindane",
    "SG542": "Diphenylacetylene",
    "SG543": "Pentacene",
    "SG544": "Cyclododecane",
    "SG545": "Acenaphthylene",
    "SG546": "Acenaphthene",
    "Conecpt_FG268": "Sulfonyl Chloride (RSO2Cl, R = C)",
    "concept_CG036": "4-Halobenzonitrile",
}