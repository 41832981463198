import { html } from "htm/preact";
import { useEffect } from "preact/hooks";

export function ModernSlaveryStatementPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return html`
    
    <div class="body-overlay"></div>
        
        <!-- Document Wrapper
        ============================================= -->
        <div id="wrapper" class="clearfix">
    
        
    
            <!-- Content
            ============================================= -->
            <section id="content cms-content">
    
                <div class="content-wrap nopadding">
    
                    <div id="section-products" class="page-section notoppadding nobottompadding">
    
                        <div class="section nomargin" style="background-color: white;">
                            <div class="container clearfix">
                                <div class="divcenter">
                                    
                                    <h1 class="nobottommargin t300 ls1">Fluorochem Modern Slavery Statement 2021</h1>
    
                                    <h2>Background</h2>
    
                                    <p>This statement has been prepared for the purposes of Section 54 of the Modern Slavery Act 2015 “Transparency in Supply Chains”.</p>
                                    <p>It was approved and signed by the Fluorochem Board on 14th June 2021. The statement will be updated annually.</p>
                                    <p>Modern Slavery is a crime where people are exploited and treated as commodities for commercial gain. It includes slavery, servitude, forced labour and human trafficking. According to some sources there are over 20 million people worldwide subjected to modern slavery. </p>
                                    <p>Fluorochem is committed to playing its part to help effect change.</p>
    
                                    <h2>Our Business</h2>
                                    <p>Fluorochem Limited is a leading supplier of intermediates for research and development to customers worldwide. We have over 40 employees in the UK. We interact with suppliers and customers in many different countries worldwide. Our supply chain in particular is diverse but mainly focussed on USA, Europe (significantly UK, Germany and Switzerland), Japan and China.</p>
                                    
                                    <h2>Our policies and commitments</h2>
                                    <p>We are committed to ensuring our business operates in an ethical manner and to operate in a responsible corporate manner in all our business relationships. Our responsible business policies include:</p>
                                    
                                    <ul>
                                        <li>Anti-Bribery Policy</li>
                                        <li>Whistleblowing Policy</li>
                                        <li>Equal Opportunities Policy</li>
                                        <li>Health &amp; Safety Policy</li>
                                        <li>Supplier Code of Conduct</li>
                                    </ul>
                                    
                                    <h2>Due Diligence and risk assessment</h2>
                                    <p>We have reviewed our business and our supply chain and, to the best of our knowledge, there is no evidence of modern slavery</p>
                                    <p>The following steps have been taken to assess and manage any risk our supply chain may use modern slavery</p>
                                    <ul>
                                        <li>Our suppliers complete a Supplier Approval Questionnaire and sign up to our Supplier Code of Conduct. As part of that process we are alert to an y indicators of modern slavery</li>
                                        <li>Where deemed necessary we can request responses to specific questions regarding modern slavery and steps they have taken to ensure it is not used by them or in their own supply chain</li>
                                        <li>We reserve the right to audit their facilities to ensure they comply with these obligations and that there is no use of modern slavery</li>
                                        <li>We expect our suppliers to impose equivalent obligations in their own supply chain</li>
                                    </ul>
    
                                    <h2>Training</h2>
                                    <p>As part of their induction training, staff are given an understanding of the risks of modern slavery and our policies to mitigate it. This training is included in their training record.</p>
    
                                    <h2>Ongoing effectiveness</h2>
                                    <p>We will continue to review our policies, suppliers and training to ensure these support our full commitment to eradicating this practice.</p>
    
                                    <p><em>Approved by Board of Directors on 14th June 2021 </em></p>
    
                                </div>
                            </div>
                        </div>
    
                    </div>
    
                </div>
    
            </section><!-- #content end -->
    
    
        </div><!-- #wrapper end -->
    
        <!-- Go To Top
        ============================================= -->
        <div id="gotoTop" class="icon-angle-up"></div>

        
   `;
}