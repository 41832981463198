import { html } from "htm/preact";
import { useState, useEffect } from "preact/hooks";
import { ctx } from "../index";

declare var require: any;

export default function Main() {
    const { state, dispatch } = ctx();
    const [validated, setValidated] = useState(false);

    const onProductSearchClick = (ev: Event) => {
        const isValid = state.search.q.length > 1;
        setValidated(true);
        if (!isValid) {
            ev.preventDefault();
            ev.stopPropagation();
            ev.stopImmediatePropagation();
            return false;
        }
    }

    const onSearchSubmit = (ev: Event) => {

        ev.preventDefault();
        window.location.href = '/catalog?q=' + state.search.q;
    }

    useEffect(() => {
        dispatch({ type: 'setSearchQuery', query: '' });
    }, []);

    return html`<main id="maincontent" class="page-main">
            <div id="contentarea"></div>
            <div class="page messages">
                <div data-placeholder="messages"></div>
                <div data-bind="scope: 'messages'">
                    <!-- ko if: cookieMessages && cookieMessages.length > 0 -->
                    <div role="alert" data-bind="foreach: { data: cookieMessages, as: 'message' }" class="messages">
                        <div data-bind="attr: {
            class: 'message-' + message.type + ' ' + message.type + ' message',
            'data-ui-id': 'message-' + message.type
        }">
                            <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                        </div>
                    </div>
                    <!-- /ko -->

                    <!-- ko if: messages().messages && messages().messages.length > 0 -->
                    <div role="alert" data-bind="foreach: { data: messages().messages, as: 'message' }"
                        class="messages">
                        <div data-bind="attr: {
            class: 'message-' + message.type + ' ' + message.type + ' message',
            'data-ui-id': 'message-' + message.type
        }">
                            <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                        </div>
                    </div>
                    <!-- /ko -->
                </div>
            </div>
            <article class="columns">
                <section class="column main">
                    <div class="homepage-container">
                        <div class="homepage-logo-container">
                            <img src="${require('/assets/images/d.png')}" alt="Doug Discovery Logo" class="homepage-logo logo1" />
                            <img src="${require('/assets/images/fluologo.png')}" alt="Fluorochem Logo" class="homepage-logo logo2" />
                        </div>
                        <div class="homepage-search">
                            <form class="product-search" id="product_search_form" onSubmit=${onSearchSubmit}>

                                <label class="label sr-only" for="product_search_input" data-role="minisearch-label">
                                    <span>Product Search...</span>
                                </label>

                                <div class="product-search-input-wrapper">
                                   
                                    <input id="product_search_input" onInput=${(e: InputEvent) => dispatch({
        type: 'setSearchQuery', query: (e.target as HTMLInputElement).value
    })} value=${state.search.q} type="text" name="q" placeholder="Search by Name, CAS, SMILES, IUPAC etc."
                                        class="input-text" maxlength="128" minlength="3" role="combobox"
                                        aria-haspopup="false" aria-autocomplete="both" autocomplete="off" />
                                    <div id="product_search_autocomplete" class="search-autocomplete"></div>
                                    ${validated ? html`<div class="warning">Please input at least 2 characters</div>` : html``}
                                    <button type="submit" title="Search" class="action search">
                                        <span class="text">Search</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="homepage-search-buttons">
                        
                            <a onclick=${onProductSearchClick} href="/catalog?q=${state.search.q}" class="home-search-button-primary">
                                Product Search
                            </a>
                            <a href="/advanced?q=${state.search.q}" class="home-search-button">
                            Concept Search </a>
                            <a href="/sketcher" class="home-search-button">
                                Sketcher Search </a>                  
                        </div>
                    </div>
                </section>
            </article>
        </main>`;

}