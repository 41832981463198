import {html} from 'htm/preact';
import {ctx} from '../';
import {route, Link} from 'preact-router';

export default function AccountMenu() {

    const { dispatch } = ctx();
    const logout = () => {
        dispatch({ type: 'logout' });
        route('/');
    };

    return html`<div class="sidebar sidebar-main">
    <div class="block block-collapsible-nav">
        <div class="title block-collapsible-nav-title"
            data-mage-init='{"toggleAdvanced": {"toggleContainers": "#block-collapsible-nav", "selectorsToggleClass": "active"}}'>
            <strong>
                My Account </strong>
        </div>
        <div class="content block-collapsible-nav-content" id="block-collapsible-nav">
            <ul class="nav items">
                <${Link} class="nav item" activeClassName="current" href="/account/orders">
                    <a>My Orders</a>
                <//>
                <${Link} class="nav item" activeClassName="current" href="/account/draft-orders">
                    <a>My Draft Orders</a>
                <//>
                <${Link} class="nav item" activeClassName="current" href="/account/quotes">
                    <a>My Quotes</a>
                <//>
                <${Link} class="nav item" activeClassName="current" href="/account/addresses">
                    <a>Address Book</a>
                <//>
                <${Link} class="nav item" activeClassName="current" href="/account/me">
                    <a>Profile</a>
                <//>
                <${Link} class="nav item" onClick=${logout}>
                    <a>Log out</a>
                </li>
            </ul>
        </div>
    </div>
</div>`;
}