import { html } from "htm/preact";


export function Pagination({ page, pages, changePage }: { page: number, pages: number[], changePage: (page: number) => void }) {
    if (!pages.length) return html``;
    return html`<article class="pages">
    <strong class="label pages-label" id="paging-label">Page</strong>
    <ul class="items pages-items" aria-labelledby="paging-label">

        <!-- real one -->

        <li class="item pages-item-previous">
            <a style="cursor: pointer;" onClick=${() => changePage(page - 1)} class="action previous"
                title="Previous">
                <span class="label">Page</span>
                <span>Previous</span>
            </a>
        </li>

        ${pages.map(it => it == page ? html`<li style="cursor: pointer;" class="item current">
            <strong class="page">
                <span class="label">You're currently reading page</span>
                <span>${it}</span>
            </strong>
        </li>`: html`<li style="cursor: pointer;" class="item" onClick=${() => changePage(it)}>
            <a class="page">
                <span class="label">Page</span>
                <span>${it}</span>
            </a>
        </li>` )}

        <li class="item pages-item-next">
            <a style="cursor: pointer;" onClick=${() => changePage(page + 1)} class="action next"
                title="Next">
                <span class="label">Page</span>
                <span>Next</span>
            </a>
        </li>
    </ul>
</article>`;
}