import { html } from "htm/preact";
import { ctx } from "..";
import { OrdersOrQuotes, OrderOrQuoteDetails } from "..";
import { Router, route } from "preact-router";

import { AddNewAddress } from "..";

import MyAccount from "./MyAccount";
import AccountInfo from "./AccountInfo";
import AccountMenu from "./AccountMenu";
import Addresses from "..";

function Orders() {
    return OrdersOrQuotes('orders');
}

function DraftOrders() {
    return OrdersOrQuotes('orders', true);
}

function Quotes() {
    return OrdersOrQuotes('quotes');
}

function OrderDetails(params: { id: string }) {
    return OrderOrQuoteDetails('order', params);
}

function QuoteDetails(params: { id: string }) {
    return OrderOrQuoteDetails('quote', params);
}

export default function Account() {

    const { state } = ctx();

    if (!state.user) {
        return route('/login');
    }

    return html`
        <main id="maincontent" class="page-main">
            <div id="contentarea"></div>
            <div class="page messages">
                <div data-placeholder="messages"></div>
                <div data-bind="scope: 'messages'">
                    <!-- ko if: cookieMessages && cookieMessages.length > 0 -->
                    <div role="alert" data-bind="foreach: { data: cookieMessages, as: 'message' }" class="messages">
                        <div data-bind="attr: {
            class: 'message-' + message.type + ' ' + message.type + ' message',
            'data-ui-id': 'message-' + message.type
        }">
                            <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                        </div>
                    </div>
                    <!-- /ko -->

                    <!-- ko if: messages().messages && messages().messages.length > 0 -->
                    <div role="alert" data-bind="foreach: { data: messages().messages, as: 'message' }"
                        class="messages">
                        <div data-bind="attr: {
            class: 'message-' + message.type + ' ' + message.type + ' message',
            'data-ui-id': 'message-' + message.type
        }">
                            <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                        </div>
                    </div>
                    <!-- /ko -->
                </div>
            </div>
            <div class="columns">
                
            <${Router}>
                <${MyAccount} path="/account/me" />
                <${Orders} path="/account/orders" />
                <${DraftOrders} path="/account/draft-orders" />
                <${Quotes} path="/account/quotes" />
                <${OrderDetails} path="/account/order/:id" />
                <${QuoteDetails} path="/account/quote/:id" />
                <${Addresses} path="/account/addresses" />
                <${AddNewAddress} path="/account/address-new" />
                <${AccountInfo} path="/account/edit" />
            </${Router}>
            <${AccountMenu}/>

            </div>
        </main>`;
}