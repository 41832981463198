import { html } from "htm/preact";
import { useState } from "preact/hooks";

import {conceptBlocks} from '../data';

interface ConceptBlock {
    name: string;
    code: string;
    count: number;
}

export default function ConceptSearch() {

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [selelectedConcepts, setSelectedConcepts] = useState([] as ConceptBlock[]);

    const totalPages = () => {
        return Math.ceil(conceptBlocks.length / pageSize);
    };
    const onPageSizeSelect = (e: InputEvent) => {
        setPageSize(parseInt((e.target as HTMLSelectElement).value));
        setPage(1);
    }

    const getPages = (page: number, totalPages: number) => {
        const pages = [page - 2, page - 1, page, page + 1, page + 2, page + 3, page + 4].filter(p => p > 0 && p <= totalPages);
        if (pages.length > 5) pages.length = 5;
        return pages;
    };

    const getConceptBlockPage = () => {
        return conceptBlocks.slice((page - 1) * pageSize, page * pageSize);
    };


    const onPreviousClick = () => {
        if (page > 1) setPage(page - 1);
    };

    const onNextClick = () => {
        if (page < totalPages()) setPage(page + 1);
    };

    const isConceptBlockSelected = (conceptBlock: ConceptBlock) => {
        return selelectedConcepts.find(c => c.code === conceptBlock.code) !== undefined;
    }

    const toggleConceptBlock = (conceptBlock: ConceptBlock) => {
        if (isConceptBlockSelected(conceptBlock)) {
            setSelectedConcepts(selelectedConcepts.filter(c => c.code !== conceptBlock.code));
        } else {
            setSelectedConcepts([...selelectedConcepts, conceptBlock]);
        }
    }

    return html`<main id="maincontent" class="page-main"><div id="contentarea"></div>
    <div class="page-title-wrapper">
        <h1 class="page-title">
            <span class="base" data-ui-id="page-title-wrapper">Concept Search</span>    </h1>
        </div>
    <div class="page messages"><div data-placeholder="messages"></div>
    <div data-bind="scope: 'messages'">
        <!-- ko if: cookieMessages && cookieMessages.length > 0 --><!-- /ko -->
    
        <!-- ko if: messages().messages && messages().messages.length > 0 --><!-- /ko -->
    </div>
    
    </div><div class="columns"><div class="column main">
        <input name="form_key" type="hidden" value="i8hLt8ukPFs9dSGI"/>
        <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;">
        <!-- ko template: getTemplate() -->
    
    
    <!-- /ko -->
        
    </div>
    
    
    
    
    <div id="conceptSearch" class="conceptSearch">
        <div class="conceptSearch-wrapper">
            <div id="conceptSearch-table_wrapper" class="dataTables_wrapper no-footer">
                <table id="conceptSearch-table" class="conceptSearch-table dataTable no-footer" style="width: 100%" role="grid">
                    <thead>
                        <tr role="row">
                            <th class="sorting_disabled" rowspan="1" colspan="1" aria-label="Select"><span>Select</span></th>
                            <th class="sorting_desc" tabindex="0" aria-controls="conceptSearch-table" rowspan="1" colspan="1" aria-label="Concept Group: activate to sort column ascending" aria-sort="descending"><span>Concept Group</span></th>
                            <th class="sorting" tabindex="0" aria-controls="conceptSearch-table" rowspan="1" colspan="1" aria-label="Hits: activate to sort column ascending"><span>Hits</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        ${getConceptBlockPage().map(row => html`<tr role="row" class="odd">
                            <td><label class="custom-checkbox">
                                <input onClick=${() => toggleConceptBlock(row)} checked=${isConceptBlockSelected(row)} type="checkbox" class="conceptSearch-row-check" name="codes[]"/>
                                <div class="checkbox"></div></label>
                            </td>
                            <td class="sorting_1">${row.name}</td><td class="">${row.count}</td>
                        </tr>`)}
                </tbody>
            </table>
            <div class="conceptSearch-paging-container">
                <div class="dataTables_length" id="conceptSearch-table_length">
                    <label>Show 
                        <select value=${pageSize} onChange=${onPageSizeSelect} name="conceptSearch-table_length" aria-controls="conceptSearch-table" class="">
                            <option value="10" >10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="1000">All</option>
                        </select> entries</label>
                    </div>
                    <div class="dataTables_paginate paging_simple_numbers_no_ellipses" id="conceptSearch-table_paginate">
                        <a onClick=${onPreviousClick} class="paginate_button previous disabled" aria-controls="conceptSearch-table" data-dt-idx="0" tabindex="-1" id="conceptSearch-table_previous">
                            <span class="text">Previous</span>
                        </a>
                        <span>
                            ${getPages(page, totalPages()).map(p => html`<a onClick=${() => setPage(p)} class="paginate_button ${page == p ? 'current' : ''}" aria-controls="conceptSearch-table" data-dt-idx="1" tabindex="0">${p}</a>`)}
                        </span>
                        <a onClick=${onNextClick} class="paginate_button next" aria-controls="conceptSearch-table" data-dt-idx="6" tabindex="0" id="conceptSearch-table_next">
                            <span class="text">Next</span>
                        </a>
                    </div>
                </div>
            </div>
            <div id="conceptSearch-selected" class="conceptSearch-selected">
                <ul>
                    ${selelectedConcepts.map(it => html`<li data-concept-code="${it.code}"><button class="conceptSearch-selected-remove">
                        <span class="text">Deselect</span></button><span>${it.name}</span>
                    </li>`)}
                </ul>
            </div>
        </div>
        <div class="conceptSearch-buttons">
            <button id="conceptSearch-search" class="conceptSearch-search action primary" disabled=${selelectedConcepts.length == 0} style="">Show ${selelectedConcepts.reduce((p, n) => p + n.count, 0)} Results</button>
        </div>
    </div>
    </div></div></main>`;
}