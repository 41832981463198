import { html } from "htm/preact";
import { ctx, useInput } from ".";
import { useEffect, useRef, useState } from "preact/hooks";
import { conceptsMap, filtersData } from "./data";


export function ConceptGroupsFiler() {


    const { state, dispatch } = ctx();

    const [textFilter, setTextFilter] = useInput('');
    const [selectedGroup, setSelectedGroupRaw] = useState({} as { [key: string]: string });

    const [page, setPage] = useState(1);


    const setSelectedGroup = (value: string) => {
        const newValue = {
            ...selectedGroup, [value]: (selectedGroup[value] == 'include') ? 'exclude'
                : (selectedGroup[value] == 'exclude' ? '' : 'include')
        };
        setSelectedGroupRaw(newValue);
        dispatch({ type: 'setConceptGroups', value: Object.keys(newValue).filter(it => newValue[it] == 'include') });
        dispatch({ type: 'setExcludeConceptGroups', value: Object.keys(newValue).filter(it => newValue[it] == 'exclude') });

    }

    // available for search
    const available = filtersData.concepts
        .filter(([name]) =>
            conceptsMap[name]?.toLowerCase().includes(textFilter.toLowerCase())
            && !selectedGroup[name]
        );

    const selected = filtersData.concepts.filter(([name]) => selectedGroup[name]);

    const CONCEPTS_PAGE_SIZE = 6;

    return html`<details class="faq__detail" open>
    <summary class="faq__summary"><span class="faq__question" style="display: flex;justify-content: space-between;">
        Concept Groups
    
    <div style="display: flex;gap:5px">
    <label class="custom-checkbox" style="display: flex;gap:5px">
        <div class="checkbox concept include"></div>
        <p>include</p>
    </label>
    <label class="custom-checkbox" style="display: flex;gap:5px">
        <div class="checkbox concept exclude" style="background-color:red"></div>
        <p>exclude</p>
    </label>
    </div>

    </span>
    </summary>
    <div class="faq__text">
        <input class="concepts-input" onkeyup=${(e: any) => { setPage(1); setTextFilter(e) }} type="text" value=${textFilter} />
        ${selected.concat(available.slice((page - 1) * CONCEPTS_PAGE_SIZE, page * CONCEPTS_PAGE_SIZE))
            .map(([name, count]) => {
                return html`<div  onclick=${() => setSelectedGroup(name)} class="concept_group_item">
                <label class="custom-checkbox">
                    <div class="checkbox concept ${selectedGroup[name]}">
                       <i class="fas fa-${selectedGroup[name] == 'include' ? 'check' : (selectedGroup[name] == 'exclude' ? 'times' : '')}"></i>
                    </div>
                    <p>${conceptsMap[name]}</p> 
                </label>
            </div>`;
            })}
            <div class="concept_pagination">
                <span>page ${page} of ${Math.ceil(available.length / CONCEPTS_PAGE_SIZE)}</span>
                <button onclick=${() => setPage(Math.max(1, page - 1))}></button>
                <button onclick=${() => setPage(Math.min(Math.ceil(available.length / CONCEPTS_PAGE_SIZE), page + 1))}></button>
            </div>
    </div>
</details>`;
}



const ranges = [
    ["LogP", "log_p"],
    ["Fsp3", "fsp3"],
    ["Molecular Weight", "molecular_weight"],
    //["Rotatable Bonds", "rotatable_bonds_count"],
    ["HBA", "h_bond_acceptors"],
    ["HBD", "h_bond_donors"],
    ["Asymetric Atoms", "asymetric_atoms"],
    ["Purity", "purity"],
    /*["Amid Bonds", "num_amide_bonds"],
    ["Hetero Atoms", "num_hetero_atoms"],
    ["Heavy Atoms", "num_heavy_atoms"],
    ["Atoms", "total_number_of_atoms"],
    ["Rings", "num_rings"],
    ["Aromatic Rings", "num_aromatic_rings"],
    ["Saturated Rings", "num_saturated_rings"],
    ["Aliphatic Rings", "num_aliphatic_rings"],*/
];

export function RangesFilters() {

    const { state, dispatch } = ctx();

    const onToggleClick = (field: string) => {
        if (state.search.ranges[field]) {
            dispatch({ type: 'setRange', field, value: null as any });
        }
    };

    return ranges.map(([name, field]) => html`<details class="faq__detail"  open=${state.search.ranges[field]}>
    <summary  class="faq__summary" onclick=${() => onToggleClick(field)}><span class="faq__question">${name}</span></summary>
    <p class="faq__text">
        <${RangeFiler} title=${name} value=${field} />
    </p>
    </details>`);

}



function RangeFiler({ title, value }: { title: string; value: string; }) {
    if (!filtersData.ranges[value]) {
        console.warn('missing range', value);
        return html``;
    }

    return html`<div class="filter-options-item allow">
    <div data-role="content" class="filter-options-content" role="tabpanel" aria-hidden="true">
        <${Range} field=${value} from=${filtersData.ranges[value][0]} to=${filtersData.ranges[value][1]}><//>
    </div>
</div>`;
}

function OldRangeFiler({ title }: { title: string }) {

    const [collapsed, setCollapsed] = useState(true);

    return html`<div class="filter-options-item allow">
    <div onClick=${() => setCollapsed(!collapsed)} data-role="title" class="filter-options-title" role="tab" aria-selected="false"
        aria-expanded="false" tabindex="0">${title}
    </div>
    <div data-role="content" class="filter-options-content" role="tabpanel" aria-hidden="true"
        style="${collapsed ? 'display:none' : ''}">
        <${Range} from=${-9} to=${9}><//>


    </div>
</div>`;
}

export function Range({ from, to, field }: { field: string, from: number, to: number }) {

    const steps = to - from;
    const round = steps > 1 ? Math.round : (it: number) => it;

    const { state, dispatch } = ctx();

    const container = useRef(null);


    const width = 0;




    const current = state.search.ranges[field];

    const [dragTarget, setDragTarget] = useState(null as any);
    const [offset, setOffset] = useState(0);
    let [left, setLeft] = useState(current ? (current[0] - from) * (width / steps) : 0);
    let [right, setRight] = useState(current ? (current[1] - from) * (width / steps) : width);
    let [stepSize, setStepSize] = useState(width / steps);

    let [isDragging, setIsDragging] = useState(false);

    const onMouseDown = (ev: MouseEvent) => {
        const target = ev.target as HTMLElement;
        setDragTarget(ev.target);
        setOffset(ev.clientX - parseInt(target.style.left || '0px'));
        console.log('down', ev);
        setIsDragging(true);
        isDragging = true;
    };

    const onMouseUp = (ev: MouseEvent) => {
        if (!container.current || !isDragging) {
            return;
        }
        setIsDragging(false);
        setDragTarget(null);
        setOffset(0);
        onMouseMove(ev);

        let leftValue = getLeftValue();
        let rightValue = getRightValue();


        const current = state.search.ranges[field];
        console.log(current, leftValue, rightValue);
        if (current && current[0] == leftValue && current[1] == rightValue) {
            return;
        }
        console.log('set range', from, [leftValue, rightValue], to, width);
        if (leftValue >= round(from) && rightValue <= round(to) && !(rightValue == width && leftValue == width)) {
            dispatch({ type: 'setRange', field, value: [leftValue, rightValue] });
        }
    }

    const onMouseMove = (ev: MouseEvent) => {
        if (!container.current || !isDragging) {
            return;
        }
        const max = (container.current as any)?.getBoundingClientRect().width - 16;
        if (!max) {
            return;
        }

        stepSize = max / steps;
        setStepSize(stepSize);

        const pos = round(Math.min(Math.max(0, ev.clientX - offset), max) / stepSize) * stepSize;

        const [newLeft, newRight] = getNewRange(left, right, pos);

        if (newLeft != left || newRight != right) {
            setLeft(newLeft);
            setRight(newRight);
            left = newLeft;
            right = newRight;
        }

    }

    function getNewRange(left: number, right: number, pos: number): [number, number] {
        if (dragTarget?.classList.contains('thumb-left')) {
            if (pos <= right) {
                return [pos, right];
            } else if (right == left) {
                return [left, pos];
            }
        } else if (dragTarget?.classList.contains('thumb-right')) {
            if (pos >= left) {
                return [left, pos];
            } else if (right == left) {
                return [pos, right];
            }
        }
        return [left, right];
    }

    useEffect(() => {
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

    }, [dragTarget]);

    useEffect(() => {
        if (container.current) {
            const width = (container.current as any)?.getBoundingClientRect().width - 16;

            setLeft(current ? (current[0] - from) * (width / steps) : 0);
            setRight(current ? (current[1] - from) * (width / steps) : width);
            setStepSize(width / steps);
        }
    }, [container.current]);

    const updateValue = (value: number) => {
        console.log('value', value, 'form: ', from, 'to:', to, 'stepsize', stepSize);
        return (Math.min(Math.max(value, from), to) - from) * stepSize;
    };

    const getLeftValue = () => round((left / stepSize) + from);

    const getRightValue = () => round((right / stepSize) + from);

    return html`<div><div ref=${container} class="range">
    <div onMouseDown=${onMouseDown} style="left:${left}px" class="thumb thumb-left"></div>
    <div onMouseDown=${onMouseDown} style="left:${right}px" class="thumb thumb-right"></div>
</div>
<!--<div style="text-align:center">${getLeftValue()} - ${getRightValue().toFixed()}</div>-->
<div style="display:flex;justify-content:space-between">
<input onInput=${(ev: InputEvent) => setLeft(updateValue(parseFloat((ev.target as HTMLInputElement).value)))}
value=${getLeftValue()} type="number" class="number-input-field"/>
<input onInput=${(ev: InputEvent) => setRight(updateValue(parseFloat((ev.target as HTMLInputElement).value)))}
value=${getRightValue()} type="number" class="number-input-field"/>

</div>
</div>
`;
}


export function Slider({ from, to, value }: { value: number, from: number, to: number }) {

    const steps = to - from;
    const round = steps > 1 ? Math.round : (it: number) => it;

    const { state, dispatch } = ctx();
    const container = useRef(null);

    const width = 0;


    const current = value;
    const [dragTarget, setDragTarget] = useState(null as any);
    const [offset, setOffset] = useState(0);
    let [right, setRight] = useState(current ? (value - from) * (width / steps) : width);
    let [stepSize, setStepSize] = useState(width / steps);

    let [isDragging, setIsDragging] = useState(false);

    const onMouseDown = (ev: MouseEvent) => {
        const target = ev.target as HTMLElement;
        setDragTarget(ev.target);
        setOffset(ev.clientX - parseInt(target.style.left || '0px'));
        console.log('down', ev);
        setIsDragging(true);
        isDragging = true;
    };

    const onMouseUp = (ev: MouseEvent) => {
        if (!container.current || !isDragging) {
            return;
        }
        setIsDragging(false);
        setDragTarget(null);
        setOffset(0);
        onMouseMove(ev);

        let rightValue = getRightValue();


        if (current && current == rightValue) {
            return;
        }
        if (rightValue >= from && rightValue <= to && !(rightValue == width)) {
            dispatch({ type: 'setMoleculeDistance', distance: rightValue });
        }
    }

    const onMouseMove = (ev: MouseEvent) => {
        if (!container.current || !isDragging) {
            return;
        }
        const max = (container.current as any)?.getBoundingClientRect().width - 16;
        if (!max) {
            return;
        }

        stepSize = max / steps;
        setStepSize(stepSize);

        const pos = round(Math.min(Math.max(0, ev.clientX - offset), max) / stepSize) * stepSize;

        const newRight = getNewRange(right, pos);

        if (newRight != right) {
            setRight(newRight);
            right = newRight;
        }

    }

    function getNewRange(right: number, pos: number): number {
        if (dragTarget?.classList.contains('thumb-right')) {
            return pos;
        }
        return right;
    }

    useEffect(() => {
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

    }, [dragTarget]);


    const getRightValue = () => round((right / stepSize) + from);

    let sliderValue = (getRightValue() * 100).toFixed();
    if (sliderValue == '100') {
        sliderValue = '99';
    }

    useEffect(() => {
        if (container.current) {
            const width = (container.current as any)?.getBoundingClientRect().width - 16;
            setRight(current ? (value - from) * (width / steps) : width);
            setStepSize(width / steps);
        }

    }, [container.current]);

    return html`<div><div ref=${container} class="range">
    <div onMouseDown=${onMouseDown} style="left:${right}px" class="thumb thumb-right"></div>
</div>
<div style="text-align:center">> ${sliderValue}%</div>

</div>
`;
}
