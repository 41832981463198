import { html } from "htm/preact";
import { useState } from "preact/hooks";
import { route } from "preact-router";
import { ctx, useInput } from "..";
import { isValidEmail } from "..";
import * as api from '../api';

declare var require: any;

export default function Login() {

    const { dispatch } = ctx();

    const [email, setEmail] = useInput('');
    const [password, setPassword] = useInput('');
    const [cus, setCus] = useInput('');
    const [code, setCode] = useInput('');

    const [loading, setLoading] = useState(false);

    const [step, setStep] = useState('Login' as 'Login' | 'EmailPasswordReset' | 'SelectCustomer');
    const [customers, setCustomers] = useState([] as any[]);

    const onSubmit = (e: SubmitEvent) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();


        if (!isValidEmail(email)) {
            return alert('Invalid email');
        }

        if (!password || password.length < 6) {
            return alert('Please enter a password of at least 6 characters');
        }

        if (step == 'EmailPasswordReset' && !code) {
            return alert('Please enter the code');
        }

        setLoading(true);
        api.login(email, password, cus, code)
            .then((res) => {
                if (res == 'EmailPasswordReset') {
                    setStep('EmailPasswordReset');
                } else if (res.Logged) {
                    dispatch({ type: 'logged', ...res.Logged });
                    route('/');
                } else if (res.SelectCustomer) {
                    setStep('SelectCustomer');
                    setCustomers(res.SelectCustomer);
                }
            })
            .catch((err) => {
                alert(err.message);
            })
            .finally(() => {
                setLoading(false);
            });




    };


    return html`<main id="maincontent" class="page-main">
    <div id="contentarea"></div>
    <div class="page messages">
        <div data-placeholder="messages"></div>
        <div data-bind="scope: 'messages'">
            <!-- ko if: cookieMessages && cookieMessages.length > 0 -->
            <div role="alert" data-bind="foreach: { data: cookieMessages, as: 'message' }" class="messages">
                <div data-bind="attr: {
                class: 'message-' + message.type + ' ' + message.type + ' message',
                'data-ui-id': 'message-' + message.type
            }">
                    <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                </div>
            </div>
            <!-- /ko -->

            <!-- ko if: messages().messages && messages().messages.length > 0 -->
            <div role="alert" data-bind="foreach: { data: messages().messages, as: 'message' }" class="messages">
                <div data-bind="attr: {
                class: 'message-' + message.type + ' ' + message.type + ' message',
                'data-ui-id': 'message-' + message.type
            }">
                    <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                </div>
            </div>
            <!-- /ko -->
        </div>
    </div>
    <div class="columns">
        <div class="column main"><input name="form_key" type="hidden" value="i8hLt8ukPFs9dSGI" />
            <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;">
            </div>
            <div class="login-container">              
                <div class="login-container-right">
                    <div class="block block-customer-login">
                        <div class="block-title">
                        <img class="logo" src="${require('/assets/images/d.png')}"
        title="" alt="Doug Discovery" />                           
                        </div>
                        <div class="block-title">
                            <h2 id="block-customer-login-heading">
                                Registered Doug Discovery Users </h2>
                        </div>
                        <div class="block-content" aria-labelledby="block-customer-login-heading">
                            <form onSubmit=${onSubmit} class="form form-login">
                                ${step == 'Login' ? html`<fieldset class="fieldset login" data-hasrequired="* Required Fields">
                                    <div class="field note">If you have an account, sign in with your email address.
                                    </div>
                                    <div class="field email required">
                                        <label class="label" for="email"><span>Email</span></label>
                                        <div class="control">
                                            <input onInput=${setEmail} name="login[username]" value=${email} autocomplete="off" id="email"  required
                                                type="email" class="input-text" title="Email"/>
                                        </div>
                                    </div>
                                    <div class="field password required">
                                        <label for="pass" class="label"><span>Password</span></label>
                                        <div class="control">
                                            <input onInput=${setPassword} value=${password} name="login[password]" type="password" autocomplete="off"   required
                                                class="input-text" id="pass" title="Password"/>
                                        </div>
                                    </div>
                                    <div class="actions-toolbar">
                                        <div class="primary">
                                            <button type="submit" class="action login primary ${loading ? 'disabled' : ''}" name="send" id="send2">
                                                <span>Sign In</span></button>
                                        </div>
                                        <div class="secondary"><a class="action remind" href="/forgot-password"><span>Forgot Your Password?</span></a></div>
                                    </div>
                                </fieldset>`: (step == 'SelectCustomer' ? html`<fieldset class="fieldset login" data-hasrequired="* Required Fields">
                                <div class="field note">Your account is linked with multiple customers.<br/>Please select the customer you would like to login with.
                                </div>
                                <div class="field email">
                                    <label class="label" for="cus"><span>CUS</span></label>
                                    <div class="control">
                                        <select onInput=${setCus} name="login[cus]" value=${cus}>
                                            ${customers.map(it => html`<option value=${it.cus}>${it.name} ${it.cus}</option>`)}
                                        </select>
                                    </div>
                                </div>
                                <div class="actions-toolbar">
                                    <div class="primary">
                                        <button type="submit" class="action login primary ${loading ? 'disabled' : ''}" name="send" id="send2">
                                            <span>Sign In</span></button>
                                    </div>
                                    <div class="secondary">
                                        <a class="action remind" onclick=${() => { setStep('Login'); setCode(undefined); setCus(undefined); }}>
                                            <span>Cancel</span>
                                        </a>
                                    </div>
                                </div>
                            </fieldset>` : html`<fieldset class="fieldset login" data-hasrequired="* Required Fields">
                            <div class="field note">A code has been sent to your email.<br/>Please use that code to login
                            </div>
                            <div class="field email">
                                <label class="label" for="code"><span>Code</span></label>
                                <div class="control">
                                    <div class="control required">
                                            <input onInput=${setCode} name="login[username]" value=${code} autocomplete="off" id="code" required
                                                type="text" class="input-text" title="Code"/>
                                    </div>
                                </div>
                            </div>
                            <div class="actions-toolbar">
                                <div class="primary">
                                    <button type="submit" class="action login primary ${loading ? 'disabled' : ''}" name="send" id="send2">
                                        <span>Sign In</span></button>
                                </div>
                                <div class="secondary">
                                    <a class="action remind" onclick=${() => { setStep('Login'); setCode(undefined); setCus(undefined); }}>
                                        <span>Cancel</span>
                                    </a>
                                </div>
                            </div>
                        </fieldset>`)}
                            </form>
                        </div>
                    </div>

                    <div class="block aw-ca-creat-wrapper">
                        <div class="aw-block-new-company">
                            <div class="block-title">
                                <h2 id="block-new-customer-heading">Register with Doug Discovery</h2>
                            </div>
                            <div class="block-content" aria-labelledby="block-new-customer-heading">
                                <p>
                                    Please register here to manage your account and orders. </p>
                                <div class="actions-toolbar">
                                    <div class="primary">
                                        <a href="/register" class="action create primary">
                                            <span>Sign Up</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>`;
}